import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List  from './WorkEntry_List';
 
import { Event_onNew } from './Events';
import WorkEntry_Dialog from './WorkEntry_Dialog';
import EmployeeLayout from '../../Layouts/EmployeeLayout';

export default function WorkEntry(props){
 
    /**
     * Dialog me project_title nahi aa raha hai
     * Filter proper kam nahi kar rhe
     * Status dena hai
     * Task nature limit enforce karna hai
     */
      

    return <>
                <EmployeeLayout title="Work Entry">
                    <WorkEntry_Dialog />
                    <button  onClick={()=>{window.dispatchEvent(new CustomEvent(Event_onNew,{}));}} className="btn btn-lg btn-primary mb-2">New</button>
                    <WorkEntry_List />
                </EmployeeLayout>
            </>
}