 
import React, { useState } from 'react';
import NormalDialog from '../../../Lib/NormalDialog';
 
import { ApiBaseUrl } from '../../../Config';
import { useSelector } from 'react-redux';
 
import moment from 'moment';

const MonthReportDialog = (props) => {
 

    const [month, setMonth] = useState("01");
    const [year, setYear] = useState(moment().format("YYYY"));
    const state = useSelector(state => state);
    var formattedNumber = (myNumber) => ("0" + myNumber).slice(-2);
    let monthList = {"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"};
    let monthArray = [];    
    for (let mn = 1; mn <= 12; mn++) {
        let key = formattedNumber(mn); 
        let label = monthList[formattedNumber(mn)];
        monthArray.push({
            key:key, label:label
        })
    }
    let yearArray = []
    for (let yr = 2019; yr <= 2035; yr++) {
        yearArray.push({
            key:yr, label:yr
        });
    }

    const displayReport = () => {
        let url = ApiBaseUrl+"/admin/project-task/monthly-report?month="+month+"&year="+year+"&token="+state.UserState.token;
        window.open(url, null, "height=800,width=1000,status=yes,toolbar=no,menubar=no,location=no");  

    }

    return (<>
        <NormalDialog maxWidth="sm" title="Monthly Report" open={props.open} onClose={props.onClose}>
            <div className="form-group">
                <label>Select Month</label>
                <select id="month" value={month} className="form-control"
                onChange={(e)=>setMonth(e.target.value)}>
                    {monthArray.map((r)=>
                        <option key={r.key} value={r.key}>{r.label}</option>
                    )}
                </select>
            </div>
            <div className="form-group">
                <label>Select Year</label>
                <select id="year" value={year} className="form-control"
                onChange={(e)=>setYear(e.target.value)}>
                    {yearArray.map((r)=>
                        <option key={r.key} value={r.key}>{r.label}</option>
                    )}
                </select>
            </div>            
            <button onClick={()=>displayReport()} className="btn btn-primary">Print and Display Report</button>
        </NormalDialog> 
        </>);
}

export default MonthReportDialog;