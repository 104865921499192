import './App.css';
import './Style.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./Reducers/index";
import OperatorLogin from './Screens/Login/OperatorLogin';
import config from '../package.json';
import { BrowserRouter, Route } from 'react-router-dom';
import Project from './Screens/Admin/Project/Project';
import Employee from './Screens/Admin/Employee/Employee';

import AdminDashboard from './Screens/Admin/Dashboard/Main';

import User from './Screens/Admin/User/User';
import TaskNature from './Screens/Admin/TaskNature/TaskNature';
import WorkEntry from './Screens/Admin/WorkEntry/WorkEntry';
import Keyword from './Screens/Admin/QBank/Keyword/Keyword';

import Files from './Screens/Admin/QBank/Files/QBankFiles';
import Page from './Screens/Admin/Page/Page';


import ManagerDashboard from './Screens/Manager/Dashboard/Dashboard';
import ManagerWorkEntry from './Screens/Manager/WorkEntry/WorkEntry';
import ManagerAssignedWork from './Screens/Manager/WorkEntry/AssignedWork';

import StatusReportProjectWiseWorkEntry from './Screens/Admin/WorkEntry/StatusReport';

import ManagerQBank from './Screens/Manager/QBank/Files/QBankFiles';

import HolidayMain from './Screens/Admin/Leave/Holiday/Main';
import RequestMain from './Screens/Admin/Leave/Request/Main';
import AccountMain from './Screens/Admin/Leave/Account/Main';
import LeaveBulkMain from './Screens/Admin/Leave/BulkAction/Main';

import AssociateWorkEntry from './Screens/Associate/WorkEntry/WorkEntry';
import AssociateLeave from './Screens/Associate/Leave/Request/Main';
import AssociatePage from './Screens/Associate/Page/Page';
import AssociateQBank from './Screens/Associate/QBank/Files/QBankFiles';
import EmployeeReport from './Screens/Admin/WorkEntry/EmployeeReport';
import ChangePassword from './Screens/Associate/Me/ChangePassword';
import Report_LastDateExpired from './Screens/Admin/Project/Report_LastDateExpired';
import Report_LowBalance from './Screens/Admin/Project/Report_LowBalance';
import Report_Test from './Screens/Admin/Project/Report_Test';
function App() {

  /*<Route path="/login" exact component={Login} />
         <Route path="/admin" exact component={Project} />
         <Route path="/admin/task-nature" exact component={TaskNature} />      
         <Route path="/admin/users" exact component={User} />
         <Route path="/admin/employee" exact component={Employee} />
         <Route path="/admin/projects" exact component={Project} />
 <Route path="/admin/old-work" exact component={OldWork} />*/
  window.onload = function () {
    var body = document.body,
      html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    var styles = `
    .ReactModal__Overlay { 
        height: ${height}px;
    }
    `;
    var styleSheet = document.createElement("style")
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet);
  };
  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={config.homepage}>

          <Route path="/" exact component={OperatorLogin} />
          <Route path="/admin" exact component={AdminDashboard} />
          <Route path="/admin/project" exact component={Project} />
          <Route path="/admin/project/lastdate-report" exact component={Report_LastDateExpired} />
          <Route path="/admin/project/lowbalance-report" exact component={Report_LowBalance} />
          <Route path="/admin/project/test-report" exact component={Report_Test} />
          <Route path="/admin/employee" exact component={Employee} />
          <Route path="/admin/user" exact component={User} />
          <Route path="/admin/task-nature" exact component={TaskNature} />
          <Route path="/admin/work-entry" exact component={WorkEntry} />
          <Route path="/admin/work-entry/status-report" exact component={StatusReportProjectWiseWorkEntry} />
          <Route path="/admin/work-entry/employee-report" exact component={EmployeeReport} />
          <Route path="/admin/question-bank/states" exact component={() => <Keyword type="State" />} />
          <Route path="/admin/question-bank/types" exact component={() => <Keyword type="Type" />} />
          <Route path="/admin/question-bank/tags" exact component={() => <Keyword type="Tag" />} />
          <Route path="/admin/question-bank/files" exact component={Files} />

          <Route path="/admin/pages" exact component={Page} />

          <Route path="/admin/leave/holiday" exact component={HolidayMain} />
          <Route path="/admin/leave/request" exact component={RequestMain} />
          <Route path="/admin/leave/account" exact component={AccountMain} />
          <Route path="/admin/leave/bulk" exact component={LeaveBulkMain} />
          <Route path="/manager" exact component={ManagerDashboard} />
          <Route path="/manager/work-entry" exact component={ManagerWorkEntry} />
          <Route path="/manager/assigned-work" exact component={ManagerAssignedWork} />
          
          <Route path="/manager/question-bank" exact component={ManagerQBank} />

          <Route path="/associate/pages" exact component={AssociatePage} />
          <Route path="/associate/work-entry" exact component={AssociateWorkEntry} />
          <Route path="/associate/leave" exact component={AssociateLeave} />
          <Route path="/associate/question-bank" exact component={AssociateQBank} />
          <Route path="/associate/change-password" exact component={ChangePassword} />
        </BrowserRouter>
      </PersistGate>
    </Provider>

  );
}


export default App;
