import React from 'react';
 
import moment from 'moment';
 
 

const PaymentRecords = (props) => {
 
    return <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <td>For Month</td>
                    <td>Payment Date</td>                        
                    <td>Via</td>
                    <td>Description</td>
                    <td>Amount</td>                        
                </tr>
            </thead>
            <tbody>
                {props.records.map((r) => (
                    <tr key={r.pmt_id}>
                        <td>{moment(r.pmt_month).format("MMM YYYY")}</td>
                        <td>{moment(r.pmt_month).format("DD MMM YYYY")}</td>                        
                        <td>{r.pmt_mode}</td>
                        <td>{r.pmt_description}</td>
                        <td>{r.pmt_amount}</td>                        
                    </tr>
                ))} 
            </tbody>
        </table>
    </div>
}   

export default PaymentRecords;
