export const StatusList = [
    {value:"Pending", label:"Pending"},
    {value:"Transferred", label:"Transferred"},
    {value:"Accept", label:"Accept"},
    {value:"Reject", label:"Reject"},
    {value:"Complete", label:"Complete"},
    {value:"Approved", label:"Approved"},
    {value:"Sent to AWD", label:"Sent to AWD"},
]

export const StatusListWithAll = [
    {value:"", label:"All/Any"},
    {value:"Pending", label:"Pending"},
    {value:"Transferred", label:"Transferred"},
    {value:"Accepted", label:"Accepted"},
    {value:"Rejected", label:"Rejected"},
    {value:"Completed", label:"Completed"},
    {value:"Approved", label:"Approved"},
    {value:"Sent to AWD", label:"Sent to AWD"},
]



export const getStatusColor = status => {
    switch (status) {
        case "Pending":
            return "warning";
            break;
        case "Transferred":
            return "warning";
            break;
        case "Accepted":
            return "info";
            break;
        case "Rejected":
            return "danger";
            break;  
        case "Completed":
            return "primary";
            break;
        case "Approved":
            return "success";
            break;  
        case "Sent to AWD":
            return "dark";
            break;
        default:
            break;
    }
}