import React from 'react';
 
import moment from 'moment';
 
import { useState, useEffect } from 'react';
import {   apiGet, apiPost } from '../../../Lib/Helpers';
//import ProjectIncentive_Dialog from '../../..Screens/Project/ProjectIncentive_Dialog';
import ProjectIncentiveDialog from '../Project/ProjectIncentive_Dialog';
 
import { useDispatch } from 'react-redux';
import * as B from 'react-bootstrap';

import Confirmation from '../../../Lib/Confirmation';

const IncentiveRecords = (props) => {
 
    const initialModalState = {
        inc_id:0,
        inc_date:moment().format("DD MMM YYYY"),
        inc_amount:0,
        ts_id:0,
        pr_id:props.pr_id,
    }
 
    const initialErrorState = {
        inc_id:"", inc_date:"", inc_amount:"", ts_id:"", pr_id:"",
    }

    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialModalState})
    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [tsList, setTsList] = useState([]);
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const dispatch = useDispatch();
  // const state = useSelector(state => state);
    useEffect(() => {
         
        apiGet("/admin/project-task/listall",{emp_id:props.emp_id},(r)=>{
            setTsList(r.data.records);
        })
    },[])

    const Delete = () =>{
        apiPost("/admin/project-incentive/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""}); 
            
            dispatch({type:"PAYMENT/UPDATELIST"});
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project-incentive/get",{
            id:id
        },(r)=>{
            setModal( {...r.data, inc_date:moment(r.data.inc_date).format("DD MMM YYYY") });
            setError({...initialErrorState});
            setDialogState({open:true,title:"Edit"}); 
        })
    };

    const Save = (e) =>{
        e.preventDefault();
       // let modal2 = {...modal};
       // modal2.inc_date = moment(modal.inc_date._d).format("DD MMM YYYY");
        apiPost("/admin/project-incentive/save",{
            ProjectIncentive: modal,
        },(r)=>{
            setDialogState({open:false, title:""}); 
            dispatch({type:"PAYMENT/UPDATELIST"});
        }, setError)
    };


    return <>
    
<ProjectIncentiveDialog tsList={tsList}
setError={setError} setModal={setModal} error={error} modal={modal}
onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title} />

<Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
    <div className="table-responsive">
        <table className="table">
            <thead>
                    <tr>
                        <td>Actions</td>
                        <td>Print Order</td>
                
                        <td>Project</td>
                        <td>Task</td>                        
                        <td>Rate</td>
                        <td>Quantity</td>
                        <td>Amount</td>
                        <td>Incentive Rate</td>
                        <td>Incentive</td>
                        <td>Incentive Date</td>
                        <td>Status</td>
                    </tr>
            </thead>
            <tbody>
                {['Hard','Soft'].map(type2 => {
                    return <>
                        <tr className="table-primary"><th colSpan="11">{type2}</th></tr>

                        {props.records[type2].map((r) => (
                            <tr key={r.inc_id}>
                                <td>
                                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                        <B.Dropdown.Item  
                                            onClick={(e)=>Get(r.inc_id)} href="#">Edit</B.Dropdown.Item>                                 
                                        <B.Dropdown.Item 
                                        onClick={(e)=>{ 
                                            e.preventDefault();
                                            setConfirmDialogState({open:true, id:r.inc_id})                                    
                                            }
                                        } 
                                        className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                
                                    </B.DropdownButton>
                                    
                                </td>
                                <td>{moment(r.projectPrintOrder.pr_date).format("DD MMM YYYY")}<br />
                                {r.projectPrintOrder.pr_description}
                                </td> 
                                <td>{r.projectTask.cost.project.proj_title}</td>
                                <td>{r.projectTask.task.task_title}</td>
                                
                                <td>{r.projectTask.ts_rate}</td>
                                <td>{r.projectTask.ts_qty}</td>
                                <td>{r.projectTask.ts_amount}</td>
                                <td>{ parseFloat(100 * r.inc_amount / r.projectTask.ts_amount).toFixed(2)}%</td>
                                <td>{r.inc_amount}</td>
                                <td>{moment(r.inc_date).format("DD MMM YYYY")}</td>
                                <td><span style={{"color":props.color}}>{props.status}</span></td>
                            </tr>
                        ))} 

                    </>
                })}
                
            </tbody>
        </table>
    </div></>
}

export default IncentiveRecords;