import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List  from './WorkEntry_List';
 
import { Event_onAfterFileLinking, Event_onNew, Event_onNewFile } from './Events';
import WorkEntry_Dialog from './WorkEntry_Dialog';
import Files_Dialog from './Files_Dialog';
import Files_List from './Files_List';
import NormalDialog from '../../../Lib/NormalDialog';
 
export default function Files(props){
     
     

    return <> 
        <Files_Dialog  w_id={props.w_id} />
        
        

         

        <Files_List readonly={props.readonly} w_id={props.w_id} />        
 
        

        </>
}