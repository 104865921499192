import React, { useEffect, useState } from 'react';

import * as B from 'react-bootstrap';

import moment from 'moment';

import DatePicker from 'react-datepicker';
 
import SaveDialog from '../../../Lib/SaveDialog';

import * as FC from '../../../Lib/FormControl';


import { apiGet } from '../../../Lib/Helpers';
  


const ProjectTask_Dialog = (props) => {
    let modal = props.modal;
    let error = props.error;
    let setModal = props.setModal;
    let taskList = props.taskList;
    let managerList = props.managerList;
    let associateList = props.associateList;
    
     
 
    return (    
     

<SaveDialog onClose={(e)=>props.onClose(e)} onSubmit={(e) => props.onSubmit(e)} open={props.open} title={props.title}>


<input type="hidden" id="ts_id" value={modal.ts_id} />

<div className="form-group">
    <label>Task Nature</label>
    <select value={modal.task_id} className="form-control" onChange={(e)=>setModal({...modal, task_id:e.target.value})}>
        <option key={0} value={""}>-- Select Task Nature --</option>
    {taskList.map((r) => (
            <option key={r.task_id} value={r.task_id}>{r.task_title}</option>
        ))}
    </select>
    <div className="text-danger">{error.task_id}</div>
</div>

<div className="form-group">
    <label>Task Description</label>
    <textarea className="form-control" value={modal.ts_description} onChange={(e)=>setModal({...modal, ts_description:e.target.value})} />    
    <div className="text-danger">{error.ts_description}</div>
</div>

<FC.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
    <B.Row>
        <B.Col md={6}>
            <FC.DateInput name="ts_estimated_date" label="Estimated Date" />
        </B.Col>
        <B.Col md={6}>
            <FC.DateInput name="ts_completion_date" label="Completion Date" />
        </B.Col>
    </B.Row> 
</FC.FormContextProvider>

 {/*
<B.Row>
    <B.Col md={6}>
        <div className="form-group">
            <label>Estimated Date</label>     
            <DatePicker dateFormat="dd MMM yyyy" selected={new Date(modal.ts_estimated_date)} className="form-control" onChange={(date) => setModal({...modal, ts_estimated_date:moment(date).format("DD MMM YYYY")})} />                       
            <div className="text-danger">{error.ts_estimated_date}</div>
        </div>
    </B.Col>
    <B.Col md={6}>
        <div className="form-group">
            <label>Completion Date</label>     
            <DatePicker dateFormat="dd MMM yyyy" selected={new Date(modal.ts_completion_date)} className="form-control" onChange={(date) => setModal({...modal, ts_completion_date:moment(date).format("DD MMM YYYY")})} />                       
            <div className="text-danger">{error.ts_completion_date}</div>
        </div>
    </B.Col>    
</B.Row>
 */}
<B.Row> 
    <B.Col md={4}>
        <div className="form-group">
            <label>Rate</label>
            <input type="number" className="form-control" value={modal.ts_rate} onChange={(e)=>setModal({...modal, ts_rate:e.target.value, ts_amount: e.target.value * modal.ts_qty})} />    
            <div className="text-danger">{error.ts_rate}</div>
        </div>
    </B.Col>
    <B.Col md={4}>
        <div className="form-group">
            <label>Quantity</label>
            <input type="number" className="form-control" value={modal.ts_qty} onChange={(e)=>setModal({...modal, ts_qty:e.target.value, ts_amount: e.target.value * modal.ts_rate})} />    
            <div className="text-danger">{error.ts_qty}</div>
        </div>
    </B.Col>
    <B.Col md={4}>
        <label>Total Amount:</label>
        <div>Rs {modal.ts_amount}</div><div className="text-danger">{error.ts_amount}</div>
    </B.Col>
</B.Row>
 
 
<B.Row>
    <B.Col md={4}>
        <div className="form-group">
            <label>Alotted By</label>
            <select className="form-control" value={modal.ts_alotted_by} onChange={(e)=>setModal({...modal, ts_alotted_by:e.target.value})}>    
                <option key={0} value={""}>-- Select Employee --</option>
                {managerList.map((r) => (                
                    <option key={r.emp_id} value={r.emp_id}>{r.emp_fullname}</option>
                ))}
            </select>
            <div className="text-danger">{error.ts_alotted_by}</div>
        </div>
    </B.Col>
    <B.Col md={4}>
        <div className="form-group">
            <label>Alotted To</label>
            <select className="form-control" value={modal.ts_alotted_to} onChange={(e)=>setModal({...modal, ts_alotted_to:e.target.value})}>    
                    <option key={0} value={""}>-- Select Employee --</option>
                    {associateList.map((r) => (
                        <option key={r.emp_id} value={r.emp_id}>{r.emp_fullname}</option>
                    ))}
            </select>
            <div className="text-danger">{error.ts_alotted_to}</div>
        </div>
    </B.Col>
    <B.Col md={4}>
        <div className="form-group">
            <label>Status</label>     
            <select value={modal.ts_status} onChange={(e)=>setModal({...modal, ts_status:e.target.value})} className="form-control">
                <option key={1} value={"Completed"}>Completed</option>
                <option key={2} value={"Pending"}>Pending</option>
            </select>
            <div className="text-danger">{error.ts_status}</div>
        </div>
    </B.Col>
</B.Row>
  

</SaveDialog>
    );
}

export default ProjectTask_Dialog;