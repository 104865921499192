import React, { useEffect, useState } from 'react';
 
import { Helmet } from 'react-helmet';
import * as B from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {apiPost} from '../../Lib/Helpers';
import { MdDescription, MdLabel, MdList, MdPeople, MdPerson, MdLocationCity, MdFolderOpen, MdPages, MdPageview, MdFirstPage, MdHome, MdSecurity } from "react-icons/md";
import * as IM from "react-icons/im";
import { FaChartBar, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
 
import { ImOffice } from 'react-icons/im';
import * as Config from '../../Config';
const Main_Links = [
  {
    icon:<MdHome  size="1.5rem"/>,
    label:"Dashboard",
    link:"/manager",
    id:1,
  }, 
  {
    icon:<MdList  size="1.5rem"/>,
    label:"Work Entries",
    link:"/manager/work-entry",
    id:2,
  },

  {
    icon:<MdList  size="1.5rem"/>,
    label:"Operator Work Entries",
    link:"/manager/assigned-work",
    id:2,
  },

];

  const Qb_Links_Manager = [ 
    {
      icon:<MdList size="1.5rem"/>,
      label:"Question Bank",
      link:"/manager/question-bank",
      id:3,
    },
  ]
 
  const Qb_Links_Associate = [ 
    {
      icon:<MdList size="1.5rem"/>,
      label:"Question Bank",
      link:"/associate/question-bank",
      id:3,
    },
  ]

const Emp_Links = [ 
  {
    icon:<MdList size="1.5rem"/>,
    label:"Assigned Work",
    link:"/associate/work-entry",
    id:4,
  },
  {
    icon:<MdList size="1.5rem"/>,
    label:"Leave Requests",
    link:"/associate/leave",
    id:5,
  },
  {
    icon:<MdList  size="1.5rem"/>,
    label:"Office Rules",
    link:"/associate/pages",
    id:2,
  },
]

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
export default function EmployeeLayout(props){
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const location = useLocation();
 
  
  
  useEffect(()=>{

    

    if('Notification' in window){
        Notification.requestPermission((r)=>{
            if(r == "granted"){
                //new Notification("Success");                     
                if('serviceWorker' in navigator){ 
                   

                    let reg;
                    navigator.serviceWorker.ready.then((swreg)=>{
                        reg = swreg;
                        return swreg.pushManager.getSubscription();
                    }).then((sub)=>{ 
                    
                        if(sub === null){
                            //create new subscription
                            let publicKey = Config.PushNotification.publicKey ;
                            let key = urlBase64ToUint8Array(publicKey);
                            return reg.pushManager.subscribe({
                                userVisibleOnly:true,
                                applicationServerKey:key
                            });
                        } else {
                            //get subscription
                            return sub;
                        }
                    }).then((newSubscription)=>{
                      if(state.UserState.user !== null){
                        let data = {
                          newSubscription : JSON.stringify(newSubscription),
                          emp_id : state.UserState.user.user_id
                        };
                        apiPost("/notification/subscribe",data,(r)=>{
                           
                        })  
                      }
                    })
                }
            } else {
                console.log("Permission Denied");
            }
        })
    }
 /*
    const pushSubscriptionChange = (oldSub,newSub) => {
      console.log(oldSub);
      console.log(newSub);
      let data = {
        newSubscription : JSON.stringify(newSub),
        oldSubscription : JSON.stringify(oldSub),
        emp_id : state.UserState.user.user_id
      };
      apiPost("/notification/update-subscription",data,(r)=>{
         
      }) 
    }

    document.addEventListener('pushsubscriptionchange', pushSubscriptionChange);
    return (()=>{
      document.removeEventListener('pushsubscriptionchange',pushSubscriptionChange)
    });
 */

  },[])

  if(!state.UserState.isLoggedIn){
    return <Redirect  to={ { pathname:'/', state:{from:location.pathname} } } />;
  }
  if(state.UserState.type === "User"){
    return <Redirect to='/admin' />;
  }
  const Logout = () => {
    dispatch({
      type: "USER/LOGOUT", 
    });
    
  };

  if(!state.UserState.user.roles.includes("Project Manager")){
    switch (location.pathname) {
      case "/manager/work-entry":
        return <Redirect to='/associate/work-entry' />;
        break;
    
      default:
        break;
    }
  }




  
    return <>
    
    <Helmet>
        <title>{props.title}</title>
    </Helmet>

    <B.Navbar variant="light" bg="primary" className="shadow text-white" expand="lg">
  <B.Container fluid>
    <B.Navbar.Brand href="/">
     <ImOffice style={{marginTop: -10}} /> Office Work
    </B.Navbar.Brand>
    <B.Navbar.Toggle aria-controls="basic-navbar-nav" />
    <B.Navbar.Collapse id="basic-navbar-nav">
      <B.Nav className="me-auto">
          

        
 
      </B.Nav>
    </B.Navbar.Collapse>

    <div className="float-right">


<div className="form-row">
  <div className="col">
  <FaUserCircle size="3rem" />
  </div>
  <div className="col-auto">

  Welcome <strong>{state.UserState.user.user_name}</strong><br />
  <a href="#" className="text-light" onClick={() => Logout()}><FaSignOutAlt /> Sign Out</a>
  </div>
</div>


  
</div>

  </B.Container>
</B.Navbar>
<div className="content-area">
  <div className="sidemenu">  
    <div className="sidemenu-heading">Main Menu</div>
    {state.UserState.user.roles.includes("Project Manager") ? 
    <>
   
    <ul>
        {Main_Links.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}        
    </ul>
    </>
    : ''}

    <ul>
        {Emp_Links.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}  
            
    </ul>
    
    {state.UserState.user.roles.includes("Project Manager") ? 
    <ul>
        {Qb_Links_Manager.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}        
    </ul>
    : 
    <ul>
        {Qb_Links_Associate.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}        
    </ul>
    }
    <ul>
     <li><Link className={"/associate/change-password" == location.pathname ? 'active' : ''} to={"/associate/change-password"}>
       <MdSecurity size="1.5rem" /> Change Password</Link></li>  
    </ul>
  </div>
  <div className="main">
      <header className="page-header clearfix">
          
          <h1 className="h4 mt-3">{props.title}</h1>
              
      </header>
      <div style={{minHeight:1000}}>
      {props.children}     
      </div>
  </div>
</div>
 
    </>
}