import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import EmployeeLayout from '../../Layouts/EmployeeLayout'
import MyProject from './MyProject';
import { Redirect } from 'react-router';

export default function Dashboard(props){
    const state = useSelector(state=>state);
    if(!state.UserState.isLoggedIn){
        return <Redirect to='/' />;
    }
    
    let ProjectWidget = <></>
    if(state.UserState.user.roles.includes("Project Manager")){
        ProjectWidget = <div className="card">
        <div className="card-header">
            My Projects
        </div>                     
        <MyProject />                     
    </div>
    }

    return <EmployeeLayout title="Welcome to Employee Dashboard">
        
        <div className="row">
            <div className="col-md-6">
                {ProjectWidget}
            </div>
        </div>
 
    </EmployeeLayout>
}


/*




        */