import React from 'react';
import * as B from 'react-bootstrap';
import { useState, useEffect } from 'react';
import {  apiGet, apiPost } from '../../../Lib/Helpers';
//import { connect } from 'react-redux';
import Confirmation from '../../../Lib/Confirmation';
import SaveDialog from '../../../Lib/SaveDialog';
import moment from 'moment';
//import ProjectIncentive from '../Project/ProjectIncentive';
import NormalDialog from '../../../Lib/NormalDialog';
import DatePicker from 'react-datepicker';
import Pagination from 'react-js-pagination';
import ProjectIncentive from '../Project/ProjectIncentive';
const PrintOrder = (props) =>{
    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [filters, setFilter] = useState({
        proj_id:props.proj_id,
        page:0,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const initialModalState = {
        pr_id:0,
        pr_description:"",
        pr_date:moment(),
        proj_id:props.proj_id,
    }
    const [updateIncentives, setUpdateIncentives] = useState(false);
    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialModalState,
        pr_id:"",proj_id:"", pr_date:""
    })
    useEffect(() => {
        Loadlist();
    },[filters]);
    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
        setError({...initialModalState,
            pr_id:"",proj_id:"", pr_date:""
        });
        setUpdateIncentives(true);
    };

    const Delete = () =>{
        apiPost("/admin/print-order/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/print-order/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/print-order/get",{
            id:id
        },(r)=>{
            setModal({...r.data, pr_date: moment(r.data.pr_date).format("DD MMM YYYY") });
            setError({...initialModalState,
                pr_id:"",proj_id:"",pr_date:""
            });
            setUpdateIncentives(false);
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
        
        let modal2 = {...modal};
        modal2.pr_date = moment(modal.pr_date).format("DD MMM YYYY");
        console.log(modal2);
        apiPost("/admin/print-order/save",{
            ProjectPrintOrder: modal2, updateIncentives: updateIncentives
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };

    const [incDialogState, setIncDialogState] = useState({open:false,pr_id:0,title:""});

 

        return (<React.Fragment>
             <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
            <SaveDialog onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                <div className="form-group">
                    <label>Description</label>
                    <textarea className="form-control" onChange={(e)=>setModal({...modal, pr_description:e.target.value})} value={modal.pr_description} />
                    <div className="text-danger">{error.pr_description}</div>
                </div>
                <div className="form-group">
                    <label>Print Order Date</label>
                    <DatePicker dateFormat="dd MMM yyyy" selected={new Date(modal.pr_date)} className="form-control" 
                    onChange={(date) => setModal({...modal, pr_date:moment(date).format("DD MMM YYYY")})} />
                    <div className="text-danger">{error.pr_date}</div>
                </div>


   
            {modal.pr_id > 0 ? <label><input type="checkbox"
            checked={updateIncentives}
            onChange={(e)=>setUpdateIncentives(e.target.checked)} value={1} /> Update Incentives</label>
            : ""}


                    <input type="hidden" id="emp_id" value={modal.proj_id} />
                    <input type="hidden" id="tp_id" value={modal.pr_id} />
            </SaveDialog>

            <NormalDialog title={incDialogState.title} onClose={(e)=>setIncDialogState({...incDialogState, open:false})} open={incDialogState.open}>
                {incDialogState.open ? <ProjectIncentive proj_id={props.proj_id} pr_id={incDialogState.pr_id} /> : "" }  
            </NormalDialog>




            <button className="btn btn-primary btn-lg mb-2"  onClick={()=>New()}>New</button>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr style={{width:120}}>
                            <th>Actions</th>
                            <th>Date</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.task_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e)=>Get(r.pr_id)} href="#">Edit</B.Dropdown.Item>
                                    <B.Dropdown.Item  
                                        onClick={() => setIncDialogState({
                                            open:true,
                                            pr_id:r.pr_id,
                                            title: "Incentive Data for Print Order #"+moment(r.pr_date).format("DD MMM YYYY")+"/"+r.project.proj_title 
                                        }) }                                        
                                        href="#">Incentives</B.Dropdown.Item>                                                         
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.pr_id})                                    
                                        }
                                    } className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                    
                                </B.DropdownButton>
                            </td>
                            <td>
                            {moment(r.pr_date).format("DD MMM YYYY")}
                            </td>
                            <td>
                            {r.pr_description}    
                            </td>
                        </tr>                         
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3">
                            <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> 

        </React.Fragment>)
}

export default PrintOrder;