import React from 'react';

import { useState, useEffect } from 'react';
import { apiGet } from '../../../../Lib/Helpers';
import * as B from 'react-bootstrap';
import Pagination from "react-js-pagination";
import {Events} from './Constants';
export default function EmployeeList(){
    const [filters, setFilter] = useState({
        search : "",
        role_id: "",
        page:1,
        perpage:10,
    })

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    useEffect(() => {
        Loadlist();
    },[filters]);

    useEffect(() => {
        LoadRoleList();
    },[]);    

    const Loadlist = () =>{
        apiGet("/admin/employee/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const [roleList, setRoleList] = useState([]);

    const LoadRoleList = () => {
        apiGet("/admin/employee/roles-listall",{},(r)=>{            
            let roleList = r.data.records.map((r)=>{                 
                return {
                    id:r.role_id,
                    name:r.role_title
                }                       
            })
            setRoleList(roleList); 
        });
    }

    return <>
    
    <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="7">
                                <div className="form-row float-right">
                                    <div className="col">
                                        <input placeholder="Search..." type="search" onChange={ e => setFilter({ ...filters, search:e.target.value,}) }  value={filters.search} className="form-control" />                                     
                                    </div>
                                    <div className="col-auto">
                                        <select value={filters.role_id} onChange={(e)=>setFilter({...filters, role_id:e.target.value})} className="form-control">
                                            <option key={0} value=" ">--All/Any Role--</option>
                                            {roleList.map((r)=> {
                                                return <option value={r.id} key={r.id}>{r.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style={{width:120}}>Actions</th>
                            <th>#ID</th>
                            <th>Employee Name</th>                        
                            <th>Email</th>
                            <th>Roles</th>                        
                            <th>Present Type</th>
                            <th>Present Target</th>        
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.emp_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e) => window.dispatchEvent(new CustomEvent(Events.onTransactionButtonClick,{detail:r}))  } href="#">Transaction</B.Dropdown.Item>
                                    <B.Dropdown.Item  
                                        onClick={(e) => window.dispatchEvent(new CustomEvent(Events.onAddTranscationButtonClick,{detail:r})) } href="#">Add Transaction</B.Dropdown.Item>                                                                     
                                </B.DropdownButton>
                            </td>
                            <td>{r.emp_uid}</td>
                            <td>{r.emp_fullname}</td>
                            <td>{r.emp_email}</td>
                            
                            <td>
                                {r.employeeRoles.map((r) => (
                                    <h5 key={r.role_id}><span className="badge badge-secondary">{r.role_title}</span></h5>
                                ))}
                            </td>
                            <td>
                                <h5><span className="badge badge-secondary">
                                {r.currentEmployeeType === null ? "NONE" : r.currentEmployeeType.tp_type}
                                </span></h5>
                            </td>
                            <td>         
                                <h5><span className="badge badge-secondary">
                                {r.currentEmployeeTarget === null ? "NONE" : r.currentEmployeeTarget.tg_amount}
                                </span></h5>
                            </td>
                        </tr>
                    ))}        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="7">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>                    
            </div>
    
    
    </>
}