import React from 'react';
import EmployeeLayout from '../../Layouts/EmployeeLayout'
import * as B from 'react-bootstrap';
import NormalDialog from '../../../Lib/NormalDialog';
import   { useState, useEffect } from 'react';
 
 
 
import { apiGet } from '../../../../src/Lib/Helpers';
import Pagination from "react-js-pagination";
export default function MyProject(props){
    const [filters, setFilter] = useState({ 
        page:1,
        perpage:10,
        
    });

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const Loadlist = () => {          
        apiGet("/manager/project/assigned-projects-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    useEffect(()=>{ 
        Loadlist(); 
    },[]);
    
    useEffect(()=>{
        Loadlist();
    },[filters]);

    return <>
       <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Project Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.records.map(r=>
                                <tr key={r.proj_id}>
                                    <td>{r.proj_id}</td>
                                    <td>{r.proj_title}</td>
                                </tr>    
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={filters.page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={listData.total}
                                        pageRangeDisplayed={5}
                                        onChange={page => {
                                            setFilter({...filters, page})
                                        }}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>

    </>
}

 