import React from 'react';
 import * as B from 'react-bootstrap';
import { useState, useEffect } from 'react';
import {  apiGet, apiPost } from '../../../Lib/Helpers';
//import {  useDispatch, useSelector } from 'react-redux';
import Confirmation from '../../../Lib/Confirmation';
import moment from 'moment';
import SaveDialog from '../../../Lib/SaveDialog';
import { yearArray, monthArray, paymentModeArray } from '../../../Config';
import Pagination from 'react-js-pagination';
import * as FormControl from '../../../Lib/FormControl';
const Payment_AllData = (props) => {

    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});

    const [filters, setFilter] = useState({
        emp_id : props.emp_id,
        page:0,
        perpage:10,
    })

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        pmt_id:0,
        pmt_date: moment().format("DD MMM YYYY"),
        pmt_amount: 0,
        pmt_mode:"Net Banking",
        pmt_description:"",
        pmt_month:moment().format("MM"),
        pmt_year:moment().format("YYYY"),
        emp_id:props.emp_id
    }

    const initialErrorState = {
        pmt_id:"",
        pmt_date: "",
        pmt_amount: "",
        pmt_mode:"",
        pmt_description:"",
        pmt_month:"",
        pmt_year:"",
        emp_id:""
    }

    //const state = useSelector(state => state);
    //const dispatch = useDispatch();
    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialErrorState})
    useEffect(() => {
        Loadlist();
    },[filters,props.refresh]);

    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
    };

    const Delete = () =>{
        apiPost("/admin/payment/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
                props.onChange();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/payment/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/payment/get",{
            id:id
        },(r)=>{
            setModal({...r.data, pmt_date: r.data.pmt_date});
            setError({...initialErrorState});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
       // let modal2 = {...modal};
     //   modal2.pmt_date = moment(modal2.pmt_date._d).format("DD MMM YYYY");
        apiPost("/admin/payment/save",{
            "Payment":modal
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
            props.onChange();
        },setError)
    };

    
 
/*
    const getMonth = (id) => {
        for (let index = 0; index < 12; index++) {            
            if(monthArray[index].id == id ){
                return monthArray[index].title;
            }            
        }
        return "-";
    }
*/
    return(
        <React.Fragment>

            <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />

            <SaveDialog onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
                    <B.Row>
                        <B.Col md={6}>
                            <FormControl.Select name="pmt_month" label="Month" 
                            options={monthArray.map( (r) => (
                                {value:r.id, label:r.title}                                
                            ))}
                            />
                        </B.Col>
                        <B.Col md={6}>
                            <FormControl.Select name="pmt_year" label="Year" 
                            options={yearArray.map( (r) => (
                                {value:r, label:r}                                
                            ))}
                            />
                        </B.Col>
                    </B.Row>

                    <FormControl.Select name="pmt_mode" label="Mode" 
                    options={paymentModeArray.map( (r) => (
                        {value:r.id, label:r.title}                                
                    ))}
                    />

                    <FormControl.Textarea name="pmt_description" label="Description" />
                    <FormControl.TextInput name="pmt_amount" label="Amount" />
                    <FormControl.DateInput name="pmt_date" label="Date" />
                    <input type="hidden" value={modal.pmt_id} id="pmt_id" />
                    <input type="hidden" value={modal.emp_id} id="emp_id" />
                    
                </FormControl.FormContextProvider>
                    {/*<B.Row>
                        <B.Col md={6}>
                            <div className="form-group">
                                <label>Month</label>
                                <select className="form-control" id="tp_type" value={modal.pmt_month} 
                                onChange={(e)=>setModal({...modal, pmt_month:e.target.value})}>
                                {monthArray.map( (r) => (
                                    <option key={r.id} value={r.id}>{r.title}</option>                                    
                                ))}
                                </select>
                                <div className="text-danger">{error.pmt_month}</div>
                            </div>
                        </B.Col>
                        <B.Col md={6}>
                            <div className="form-group">
                                <label>Year</label>
                                <select id="pmt_year" className="form-control" value={modal.pmt_year}
                                    onChange={(e)=>setModal({...modal, pmt_year:e.target.value})}>
                                    {yearArray.map( (r) => (
                                        <option key={r} value={r}>{r}</option>
                                    ))}                            
                                </select>
                                <div className="text-danger">{error.pmt_year}</div>
                            </div>
                        </B.Col>
                    </B.Row>
                         
                    <div className="form-group">
                        <label>Mode</label>
                        <select id="pmt_mode" className="form-control" value={modal.pmt_mode}
                            onChange={(e)=>setModal({...modal, pmt_mode:e.target.value})}>
                            {paymentModeArray.map( (r) => (
                                <option key={r.id} value={r.id}>{r.title}</option>
                            ))}                            
                        </select>
                        <div className="text-danger">{error.pmt_mode}</div>
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <textarea 
                        className="form-control"
                        id="pmt_description" value={modal.pmt_description}
                            onChange={(e)=>setModal({...modal, pmt_description:e.target.value})} />                             
                        <div className="text-danger">{error.pmt_description}</div>
                    </div>
                     
                    <div className="form-group">
                        <label>Amount</label>
                        <input 
                        className="form-control"
                        type="number" id="pmt_amount" value={modal.pmt_amount}
                            onChange={(e)=>setModal({...modal, pmt_amount:e.target.value})} />
                        <div className="text-danger">{error.pmt_amount}</div>
                    </div>
                     
                    <div className="form-group">
                        <label>Date</label>
                        <input 
                        className="form-control"
                        type="text" id="pmt_date" value={modal.pmt_date}
                            onChange={(date)=>setModal({...modal, pmt_date:moment(date).format("DD MMM YYYY")})} />
                        <div className="text-danger">{error.pmt_date}</div>
                    </div>            
                            */}
                    
            </SaveDialog>

       
                    <button className="btn btn-primary btn-lg mb-2" onClick={()=>New()} >Add Payment</button>
                

            

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <td>Actions</td>
                            <td>Date</td>
                            <td>For Month</td>
                            <td>Via</td>
                            <td>Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.pmt_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e)=>Get(r.pmt_id)} href="#">Edit</B.Dropdown.Item>                                    
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.pmt_id})                                    
                                        }
                                    } 
                                    className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                    
                                </B.DropdownButton>
                            </td>
                            <td>{moment(r.pmt_date).format("DD MMM YYYY")}</td>
                            <td>{moment(r.pmt_month).format("MMM YYYY")}  </td>
                            <td>{r.pmt_mode}</td>
                            <td>{r.pmt_amount}</td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot> 
                </table>
            </div>  
        </React.Fragment>
    );
};

export default Payment_AllData;
