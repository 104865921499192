import React from 'react';


import { useState, useEffect } from 'react';


import {  apiGet, apiPost } from '../../../Lib/Helpers';



import SaveDialog from '../../../Lib/SaveDialog';

 
import * as B from 'react-bootstrap';
 
import moment from "moment";
 
 
import DatePicker from 'react-datepicker';
import Confirmation from '../../../Lib/Confirmation';
import Pagination from "react-js-pagination";
const EmployeeTargetHistory = (props) => {

    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});

    const [filters, setFilter] = useState({
        emp_id:props.emp_id,
        page:0,
        perpage:10,
    })

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        emp_id: props.emp_id,
        tg_startdate:moment().format("DD MMM YYYY"),
        tg_amount: "",
        tg_id:""
    }



    const [modal, setModal] = useState({...initialModalState})

    const [error, setError] = useState({...initialModalState,tg_startdate:""})

    useEffect(() => {
        Loadlist();
    },[filters]);

    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
    };

    const Delete = () =>{
        apiPost("/admin/employee/target-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/employee/target-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/employee/target-get",{
            id:id
        },(r)=>{
            setModal(r.data);
            setModal({ ...r.data, 
                tg_startdate:moment(r.data.tg_startdate).format("DD MMM YYYY"),                
            });
            setError({...initialModalState,tg_startdate:""});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
      //  let modal2 = {...modal};
        //modal2.tg_startdate = moment(modal.tg_startdate._d).format("DD MMM YYYY");
        apiPost("/admin/employee/target-save",{
            EmployeeTargetHistory: modal,
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };
 



    return (
        <React.Fragment>
            <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />

            <SaveDialog onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                <div className="form-group">
                    <label>Target Amount</label>
                    <input type="number" value={modal.tg_amount} onChange={(e)=>setModal({...modal, tg_amount:e.target.value})} className="form-control" />                        
                    <div className="text-danger">{error.tg_amount}</div>
                </div>

                <div className="form-group">
                    <label>Target Date</label>
                    <DatePicker dateFormat="dd MMM yyyy" className="form-control" 
                    selected={new Date(modal.tg_startdate)} 
                    onChange={(date) => setModal({...modal, tg_startdate:moment(date).format("DD MMM YYYY")})} />                                           
                    <div className="text-danger">{error.tp_startdate}</div>
                </div> 
                    <input type="hidden" id="emp_id" value={modal.emp_id} />
                    <input type="hidden" id="tg_id" value={modal.tg_id} />
            </SaveDialog>

            <button className="btn btn-primary btn-lg mb-2" onClick={()=>New()} >New</button>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width:120}}>Actions</th>
                            <th>Target Amount</th>
                            <th>From Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.tg_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e)=>Get(r.tg_id)} href="#">Edit</B.Dropdown.Item>                                
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.tg_id})                                    
                                        }
                                    } 
                                    className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                 
                                </B.DropdownButton> 
                            </td>
                            <td>{r.tg_amount}</td>
                            <td>{moment(r.tg_startdate).format("DD MMM YYYY")}</td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>


             
 
        </React.Fragment>
    )
}

export default EmployeeTargetHistory;
