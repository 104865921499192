import React from 'react';

 
import { useState, useEffect } from 'react';


import {  apiGet, apiPost } from '../../../Lib/Helpers';

import * as B from 'react-bootstrap';

import SaveDialog from '../../../Lib/SaveDialog';

import * as FormControl from '../../../Lib/FormControl';

 
import moment from "moment";
import DatePicker from 'react-datepicker';
import Confirmation from '../../../Lib/Confirmation';
import Pagination from "react-js-pagination";

const EmployeeTypeHistory = (props) => {

    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});

    const [filters, setFilter] = useState({
        emp_id:props.emp_id,
        page:0,
        perpage:10,
    })

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        emp_id: props.emp_id,
        tp_startdate:moment().format("DD MMM YYYY"),
        tp_type: "Insider",
        tp_id:""
    }



    const [modal, setModal] = useState({...initialModalState})

    const [error, setError] = useState({...initialModalState,tp_startdate:"",tp_type:""})

    useEffect(() => {
        Loadlist();
    },[filters]);

    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
    };

    const Delete = () =>{
        apiPost("/admin/employee/type-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/employee/type-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/employee/type-get",{
            id:id
        },(r)=>{
            setModal(r.data);
            setModal({ ...r.data, 
                tp_startdate:moment(r.data.tp_startdate).format("DD MMM YYYY"),                
            });
            setError({...initialModalState, tp_startdate:"", tp_type:""});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
       // let modal2 = {...modal};
       // modal2.tp_startdate = moment(modal.tp_startdate._d).format("DD MMM YYYY");
        apiPost("/admin/employee/type-save",{
            EmployeeTypeHistory: modal,
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };
 


    return (
        <React.Fragment>
             <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />

            <SaveDialog onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
                    <FormControl.Select name="tp_type" label={"Employee Current Type"} options={[
                        {value:"Insider",label:"Insider"},
                        {value:"Outsider",label:"Outsider"},                        
                    ]}/>
                    <FormControl.DateInput name="tp_startdate" label="Start Date" />

                </FormControl.FormContextProvider>

                {/*<div className="form-group">
                    <label>Employee Current Type</label>
                    <select className="form-control" value={modal.tp_type} onChange={(e)=>setModal({...modal, tp_type:e.target.value})}>
                        <option key={1} value={"Insider"}>Insider</option>
                        <option key={2} value={"Outsider"}>Outsider</option>
                    </select>
                    <div className="text-danger">{error.tp_type}</div>
                </div>
                <div className="form-group">
                    <label>State date</label>
                    <DatePicker dateFormat="dd MMM yyyy" className="form-control" 
                    selected={new Date(modal.tp_startdate)} 
                    onChange={(date) => setModal({...modal, tp_startdate:moment(date).format("DD MMM YYYY")})} />   
                    <div className="text-danger">{error.tp_startdate}</div>
                </div>                  */}
                <input type="hidden" id="emp_id" value={modal.emp_id} />
                <input type="hidden" id="tp_id" value={modal.tp_id} />
            </SaveDialog>
            

            <button className="btn btn-primary  btn-lg mb-2" onClick={()=>New()} >New</button>

            <div className="table-responsive">
                <table className="table">
                    <thead>

                    <tr>
                        <th style={{width:120}}>Actions</th>
                        <th>Type</th>
                        <th>From Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.tp_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e)=>Get(r.tp_id)} href="#">Edit</B.Dropdown.Item>                                
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.tp_id})                                    
                                        }
                                    } 
                                    className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                 
                                </B.DropdownButton> 
                            </td>
                            <td>{r.tp_type}</td>
                            <td>{moment(r.tp_startdate).format("DD MMM YYYY")}</td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> 
        </React.Fragment>
    )
}

export default EmployeeTypeHistory;
