import React, { useState } from 'react';

export default function Finder({
    placeholder="Find...",
    remoteUrl,
    setValue,    
    open=false,
    valueField='value',
    labelField='label',
    initialInputText='',
    onSelectOption=()=>{},
    ...props
}){
    const [suggestions, setSuggestions] = useState([]);
   
    const [inputText, setInputText] = useState(initialInputText);
 

    const onInput = (e) => {  
      
        let input = e.target.value;
        if(inputText===input){
            return;
        }
      

        setInputText(input);      
        if(input.trim() === ""){
            setSuggestions([]);
            setValue("");
            return false;
        }
        if (remoteUrl.match(/\?./)) {
            remoteUrl = remoteUrl+"&term="
        } else {
            remoteUrl = remoteUrl+"?term="
        }       
        fetch(remoteUrl+input).then((rs)=>{            
            return rs.json()
        }).then((rs)=>{
            setSuggestions(rs);
        })
    }

    const handleSelect = (e, r) => {
        e.preventDefault();
        setValue(r);
        setInputText(r[labelField]);
        setSuggestions([]);
       
        if(typeof onSelectOption === "function"){
            onSelectOption(r);
        }
    }
     
    return <>
   
    <input type="search" className="form-control" value={inputText} onChange={onInput} placeholder={placeholder} {...props} />
    <div className="suggestions finder">
        {suggestions.map(function(r) { 
            return <a data-id={r[valueField]} href="/" className="p-1 d-block" onClick={(e)=>handleSelect(e,r)}>{r[labelField]}</a> 
        })}
    </div>
{/*
    <div className="card" style={{display:open ? 'block' : 'none'}}>
        <div className="card-header">
            
        </div>
        <div className="card-body">
            
        </div> 
</div> */}
    </>
}