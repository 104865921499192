import * as B from 'react-bootstrap';
import React from 'react';
import { useState, useEffect } from 'react';
import { apiGet } from '../../../Lib/Helpers';
import AdminLayout from '../../Layouts/AdminLayout';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { getStatusColor, StatusList, StatusListWithAll } from './Satus';

import * as FC from '../../../Lib/FormControl';  
export default function Report_Test(){
    const [filters, setFilter] = useState({
        status:"",
        from:moment().format("01 MMM YYYY"),
        to:moment().format("DD MMM YYYY"),
        threshold:10,
        search : "",
        page:1,
        perpage:2000, 
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    
    const Loadlist = () => { 
        apiGet("/admin/project/report-test",filters,(r)=>{      
            //console.log(r.data.records);      
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    const [utilization, setUtilization] = useState({
        total:0,
        used:0,
        remaining:0,
        total_percentage:100,
        used_percentage:0,
        remaining_percentage:0
    })

    useEffect(()=>{
        Loadlist();
    },[filters]);

    let perpageList = [
        {value:10, label:10},
        {value:20, label:20},
        {value:50, label:50},
        {value:100, label:100},
        {value:200, label:200},
        {value:500, label:500},
        {value:1000, label:1000},
        {value:2000, label:2000},
        {value:5000, label:5000},        
    ]
    let n = ( filters.page * filters.perpage ) - filters.perpage;

    return <> 
    <AdminLayout title={moment(filters.from).format("DD MMM YYYY")+" - "+moment(filters.to).format("DD MMM YYYY")+" - Page: "+filters.page}>            
        <div style={{minHeight:400}} className="table-responsive">
            <table className="table">
                <thead> 
                     <tr>
                    
        <th colSpan={11}>
            <div class="d-print-none">
                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}>
                    <div className="form-row">  
                        {/* <div className="col-md-2">
                            <FC.DateInput name='from' label={'From'} />                            
                        </div>
                        <div className="col-md-2">
                            <FC.DateInput name='to' label={'To'} />
                        </div> */}
                        {/* <div className="col-md-2">
                        <label>&nbsp;</label><br />
                                    <input type="search" name="search" value={filters.search} placeholder="Search..."
                                    onInput={(e)=>setFilter({...filters,search:e.target.value})} className="form-control" />
                                </div> */}

                        <div className="col-md-2">
                            <FC.Select name="perpage" label={"Perpage"} options={perpageList} />
                        </div>
                        <div className="col-md-2">
                            <label>&nbsp;</label><br />
                            <button onClick={()=> window.print()} type="button" className="btn btn-primary">Print</button>
                        </div>
                    </div> 
                </FC.FormContextProvider>
            </div> 
        </th>
                </tr>
                    <tr>
                        <th>S.No</th>
                        <th>Project Title</th>
                        <th>Project Cost</th>
                        <th>Balance Amount</th>
                        <th>Completion Date</th>
                        <th>Task Date</th>
                        {/* <th>Status</th> */}
                    </tr> 
                </thead>
                <tbody>
                
                {listData.records.map(r => {
        n++;
        return (
            <tr key={r.proj_id}>
                <td>{n}</td>
                <td>{r.proj_title}</td>
                <td>{Math.round(r.proj_cost)}</td>
                <td>{Math.round(r.balance)}</td>
                <td>{moment(r.proj_completion_date).format("DD MMM YYYY")}</td>
                <td>{moment(r.proj_lasttaskdate).format("DD MMM YYYY")}</td>
            </tr>
        );
})}
                </tbody>
                <tfoot className="d-print-none">
                    <tr>
                        <td colSpan="7">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={filters.perpage}
                                totalItemsCount={listData.total}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>    
    </AdminLayout>
    </>
}