import React from 'react';
import AdminLayout from '../../../Layouts/AdminLayout';


import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../../Config';
 
import Pagination from "react-js-pagination";
 
import { Multiselect } from 'multiselect-react-dropdown';
import {  Event_onAfterSave, Event_onEdit } from './Event';


export default function List(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
     
    const [filters, setFilter] = useState({
        search : "",
        state : "",
        type : "",
        emp_id: "",
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    
    const Delete = () => {
        apiPost("/admin/qb-bank/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    }
 

    const Loadlist = () => {
          
        apiGet("/admin/qb-bank/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    useEffect(()=>{
        window.addEventListener(Event_onAfterSave,Loadlist);        
        Loadlist();
        return () => {
            window.removeEventListener(Event_onAfterSave,Loadlist);            
        }        
    },[]);
    
    useEffect(()=>{
        Loadlist();
    },[filters]);

    const [stateList, setStateList] = useState([]);
    const loadStateList = () => {
        apiGet("/admin/qb-keyword/listall",{type:"State"},(rs)=>{
            let list = rs.data.records.map((r)=>{
                return {"label":r.kw_title, "value":r.kw_id}
            });
            list.push({"label":"--Select State--", "value":""});
            setStateList(list);
        });
    }

    const [typeList, setTypeList] = useState([]);
    const loadTypeList = () => {
        apiGet("/admin/qb-keyword/listall",{type:"Type"},(rs)=>{
            let list = rs.data.records.map((r)=>{
                return {"label":r.kw_title, "value":r.kw_id}
            });
            list.push({"label":"--Select Type--", "value":""});
            setTypeList(list);
        });
    }

    useEffect(()=>{
        loadStateList();
        loadTypeList();
    },[]);

    return <> 
    <Confirmation open={confirmDialogState.open} text={confirmDialogState.title} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
         
    <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="7">
                        <div className="float-right form-row">
                            <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                            <div className="col">
                                <FC.Select name="type" label={"Type"} options={typeList} />
                            </div> 
                            <div className="col">
                                <FC.Select name="state" label={"State"} options={stateList} />
                            </div>
                            <div className="col">
                                <FC.TextInput name="search" label={"Search"} />
                            </div>
                            </FC.FormContextProvider>
                        </div>
                    </th>                
                </tr>
                <tr>
                    <th style={{width:120}}>Actions</th>
                    <th>File</th> 
                    <th style={{width:200}}>Type</th>
                    <th style={{width:200}}>State</th>
                    <th style={{width:200}}>Uploaded On</th> 
                    <th style={{width:200}}>Modified On</th> 
                    <th style={{width:200}}>Uploaded By</th> 
                </tr>
            </thead>
            <tbody>
            {listData.records.map(r => (
                <tr>
                <td> 
                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                        
                        <B.Dropdown.Item  
                            onClick={(e)=>{
                                window.dispatchEvent(new CustomEvent(Event_onEdit, { detail: r.file_id }));
                            }} href="#">Edit</B.Dropdown.Item>

                        <B.Dropdown.Item target="_blank" 
                            href={ApiBaseUrl+"/uploads/"+r.file_path}>Download</B.Dropdown.Item>
                          
                        <B.Dropdown.Item 
                        onClick={(e)=>{ 
                            e.preventDefault();
                            setConfirmDialogState({open:true, id:r.file_id, title:"Are you sure want to DELETE?"})                                    
                            }
                        } className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>


                                                    
                    </B.DropdownButton>
                </td>

                <td>{r.file_title}</td>               
                <td>
                    {r.type!==null ? r.type.kw_title : ""}                    
                </td>
                <td>
                    {r.state!==null ? r.state.kw_title : ""}
                </td>
                <td>{moment(r.file_uploaded_on).format("DD MMM YYYY HH:m:s")}</td>               
                <td>{moment(r.file_modified_on).format("DD MMM YYYY HH:m:s")}</td>               
                <td>{r.employee.emp_fullname}</td>         
            </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="7">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
        </>
}