export const StatusList = [
    {value:"Pending", label:"Pending"},
    {value:"Transferred", label:"Transferred"},
    {value:"Accepted", label:"Accepted"},
    {value:"Rejected", label:"Rejected"},
    {value:"Completed", label:"Completed"},
    {value:"Approved", label:"Approved"},
    {value:"Sent to AWD", label:"Sent to AWD"},
]

export const StatusListWithAll = [
    {value:"", label:"All/Any"},
    {value:"Pending", label:"Pending"},
    {value:"Transferred", label:"Transferred"},
    {value:"Accepted", label:"Accepted"},
    {value:"Rejected", label:"Rejected"},
    {value:"Completed", label:"Completed"},
    {value:"Approved", label:"Approved"},
    {value:"Sent to AWD", label:"Sent to AWD"},
]

export const getStatusColor = status => {
    switch (status) {
        case "Pending":
            return "warning";
         
        case "Transferred":
            return "warning";
        
        case "Accepted":
            return "info";
       
        case "Rejected":
            return "danger";
 
        case "Completed":
            return "primary";
       
        case "Approved":
            return "success";
 
        case "Sent to AWD":
            return "dark";
    
        default:
            return "dark";
    }
}