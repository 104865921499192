import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout'; 
import { useState, useEffect } from 'react';
import {  apiGet, apiPost } from '../../../Lib/Helpers';
import { connect } from 'react-redux';
import Confirmation from '../../../Lib/Confirmation';
import * as B from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import SaveDialog from '../../../Lib/SaveDialog';
import * as FormControl from '../../../Lib/FormControl';

const TaskNature = (props) => {

    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});

    const [filters, setFilter] = useState({
        search : "",
        token:props.UserState.token,
        page:1,
        perpage:10,
    })

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        task_title:"",
        task_id: "",
        task_limit:0,
    }

    const [modal, setModal] = useState({...initialModalState})

    const [error, setError] = useState({...initialModalState, task_limit:''})

    useEffect(() => {
        Loadlist();
    },[filters]);

    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
    };

    const Delete = () =>{
        apiPost("/admin/task/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/task/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/task/get",{
            id:id
        },(r)=>{
            setModal(r.data);
            setError({...initialModalState,task_limit:''});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
        apiPost("/admin/task/save",{
            "Task":modal
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };
 

    return(
        <React.Fragment>
            <AdminLayout title="Manage Task Type List">
            <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />

<SaveDialog onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
    <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
        <FormControl.TextInput name="task_title" label={"Task Title"} />
        <FormControl.TextInput name="task_limit" label={"Limit"} />
    </FormControl.FormContextProvider>
    

    <input type="hidden" value={modal.task_id} id="task_id" />
       
</SaveDialog>
 
<button className="btn btn-primary btn-lg mb-2" onClick={()=>New()} >New</button>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="3">
                                <div className="form-row float-right">
                                    <div className="col">
                                        <input type="search" className="form-control" placeholder="Search..."			                
                                        onChange={(e)=>setFilter({
                                            ...filters,
                                            search : e.target.value,
                                            page : 0,
                                            })
                                        }   /> 
                                    </div>
                                </div>
                                
                            </th>
                        </tr>
                        <tr>
                        <th style={{width:120}}>Actions</th>
                        <th>Task Nature Title</th>
                        <th style={{width:150}}>Limit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.records.map(r => (
                            <tr key={r.task_id}>
                                <td>
                                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                        <B.Dropdown.Item  
                                            onClick={(e)=>Get(r.task_id)} href="#">Edit</B.Dropdown.Item>
                                        
                                        <B.Dropdown.Item 
                                        onClick={(e)=>{ 
                                            e.preventDefault();
                                            setConfirmDialogState({open:true, id:r.task_id})                                    
                                            }
                                        } 
                                        className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                 
                                    </B.DropdownButton> 
                                </td>
                                <td>
                                    {r.task_title}
                                </td>
                                <td>
                                    {r.task_limit}
                                </td>
                            </tr>
                        ))}        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={filters.page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={listData.total}
                                        pageRangeDisplayed={5}
                                        onChange={page => {
                                            setFilter({...filters, page})
                                        }}
                                    />
                            </td>
                        </tr>
                    </tfoot>
                </table>
 </div>
            </AdminLayout>
        </React.Fragment>
    );
};




const mapStateToProps = state =>{ return state };
const mapDispatchToProps = dispatch => { return { do: (action) => { dispatch(action) } } };
export default connect(mapStateToProps, mapDispatchToProps)(TaskNature);
