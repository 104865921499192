import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout';
import { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { apiGet, apiPost } from '../../../Lib/Helpers';
import { connect } from 'react-redux';
import Confirmation from '../../../Lib/Confirmation';
import * as B from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import SaveDialog from '../../../Lib/SaveDialog';
import Pagination from 'react-js-pagination';
const User = (props) => {

    let state = useSelector(state => state);
    let dispatch = useDispatch();
    const [dialogState, setDialogState] = useState({ open: false, title: "" });
    const [confirmDialogState, setConfirmDialogState] = useState({ open: false });
    const [filters, setFilter] = useState({
        search: "",
        token: props.UserState.token,
        page: 1,
        perpage: 10,
    })

    const [listData, setListData] = useState({
        records: [],
        total: 0,
    });

    const initialModalState = {
        user_id: "",
        user_name: "",
        user_password: "",
        auth_workentry: "",
        auth_balance: "1",
        token: props.UserState.token,
    }
   
    const [modal, setModal] = useState({ ...initialModalState })

    const [error, setError] = useState({ ...initialModalState })

    useEffect(() => {
        Loadlist();
    }, [filters]);

    const New = () => {
        setDialogState({ open: true, title: "New" });
        setModal({ ...initialModalState });
        setError({ ...initialModalState });
    };

    const Delete = () => {
        apiPost("/admin/user/delete", {
            id: confirmDialogState.id
        }, (r) => {
            setConfirmDialogState({ open: false, title: "" });
            Loadlist();
        })
    };

    const Loadlist = () => {
        apiGet("/admin/user/loadlist", filters, (r) => {
            setListData({
                records: r.data.records,
                total: r.data.total,
            });
        })
    };

    const Get = (id) => {
        apiGet("/admin/user/get", {
            id: id
        }, (r) => {
            setModal(r.data);
            setError({ ...initialModalState });
            setDialogState({ open: true, title: "Edit" });
        })
    };

    const Save = (e) => {
        e.preventDefault();
        apiPost("/admin/user/save", {
            "User": modal
        }, (r) => {
            setDialogState({ open: false, title: "" });
            Loadlist();
        }, setError)
    };

    return (
        <React.Fragment>
            <AdminLayout title="Manage Users">
                <Confirmation open={confirmDialogState.open} onYes={() => Delete(confirmDialogState.id)} onNo={(e) => {
                    setConfirmDialogState({ open: false });
                }} />
                <SaveDialog onClose={(e) => setDialogState({ open: false })} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                    <div className="form-group">
                        <label>User Name</label>
                        <input type="text" onChange={(e) => setModal({
                            user_name: e.target.value,
                            user_password: modal.user_password,
                            user_id: modal.user_id
                        })} value={modal.user_name} className="form-control" />
                        <div className="text-danger">{error.user_name}</div>
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input type="text" onChange={(e) => setModal({
                            user_name: modal.user_name,
                            user_password: e.target.value,
                            user_id: modal.user_id
                        })} value={modal.user_password} className="form-control" />
                        <div className="text-danger">{error.user_password}</div>
                        <input type="hidden" value={modal.user_id} id="user_id" />
                    </div>
                   
                </SaveDialog>

                <button className="btn btn-primary mb-2 btn-lg" onClick={() => New()} >New</button>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th colSpan="3">
                                    <div className="form-inline float-right">
                                        <input type="search" placeholder="Search..." className="form-control"
                                            onChange={(e) => setFilter({
                                                ...filters,
                                                search: e.target.value,
                                                page: 0,
                                            })
                                            } />
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th style={{ width: 120 }}>Actions</th>
                                <th>User Name</th>
                                <th style={{ width: 200 }}>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.records.map(r => (
                                <tr key={r.user_id}>
                                    <td>
                                        <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                            <B.Dropdown.Item
                                                   onClick={(e) => Get(r.user_id)} href="#">Edit</B.Dropdown.Item>
 
                                                   <B.Dropdown.Item
                                                    onClick={(e) => {
                                                    e.preventDefault();
                                                    setConfirmDialogState({ open: true, id: r.user_id })
                                                }
                                                }
                                                className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>
                                        </B.DropdownButton>
                                    </td>
                                    <td>
                                        {r.user_name}
                                    </td>
                                    <td>
                                        {r.user_id == 1 ? "Super Admin" : "Admin"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={filters.page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={listData.total}
                                        pageRangeDisplayed={5}
                                        onChange={page => {
                                            setFilter({ ...filters, page })
                                        }}
                                    />

                                {/* <td>{getLabel("Auth Workentry",modal.auth_workentry)}</td> */}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </AdminLayout>
        </React.Fragment>
    );
};
const mapStateToProps = state => { return state };
const mapDispatchToProps = dispatch => { return { do: (action) => { dispatch(action) } } };
export default connect(mapStateToProps, mapDispatchToProps)(User);
