
import React from 'react';
import * as B from 'react-bootstrap';
import NormalDialog from './NormalDialog';


const SaveDialog = ({ buttonLabel = "Save", disableSaveButton = false, width = 800, ...props }) => {
  
  // Inline event handler for the form submission
  let footer = (
    <div style={{ textAlign: 'right' }}>
      {/* Use an inline arrow function to prevent the default action */}
      <B.Button disabled={disableSaveButton} variant="primary" type="button" onClick={(e) => {
        e.preventDefault(); // Prevent default button click behavior
        if (props.onSubmit) {
          props.onSubmit(e); // Call the onSubmit prop, if provided
        }
        return false; // Return false to prevent any further default action
      }}>
        {buttonLabel}
      </B.Button>
    </div>
  );

  return (
    <NormalDialog
      footer={footer}
      width={width}
      open={props.open}
      onClose={props.onClose}
      title={props.title}
    >
      {/* Use an inline arrow function and return false to prevent default form submission */}
      <form onSubmit={(e) => {
        e.preventDefault();
        return false;
      }}>
        {props.children}
        <button className="d-none" type="submit">Submit</button>
      </form>
    </NormalDialog>
  );
};

  
  
/*

const SaveDialog = ({buttonLabel = "Save", ...props}) => {
    return (
        <div className="modal-bg" style={{display:props.open ? 'block' : 'none'}}>
        <B.Modal.Dialog
            style={{ display: props.open ? 'block' : 'none', width:900 }}
            size="xl">
            <form method="post" onSubmit={props.onSubmit}>
                <B.Modal.Header closeButton onHide={props.onClose}>
                    <B.Modal.Title>{props.title}</B.Modal.Title>
                </B.Modal.Header>

                <B.Modal.Body> 
                    {props.children} 
                </B.Modal.Body>

                <B.Modal.Footer>
                    <B.Button variant="primary" size="lg" onClick={props.onSubmit}>{buttonLabel}</B.Button>
                </B.Modal.Footer>
            </form>
        </B.Modal.Dialog> 
        </div>
    )
}*/

export default SaveDialog;
