import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List from './WorkEntry_List';
import { Event_onAfterSave, Event_onEdit, Event_onEditFile, Event_onNewFile, Event_onAfterSaveFile } from './Events';
import AdminLayout from '../../Layouts/AdminLayout';
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../Lib/Helpers';
import * as FormControl from '../../../Lib/FormControl';
import moment from 'moment';
import SaveDialog from '../../../Lib/SaveDialog';
import { ApiBaseUrl } from '../../../Config';
import { getStatusColor, StatusList, StatusListWithAll } from '../../../Constants/WorkEntry_StatusList';
import axios from 'axios';
import { AjaxValidate } from '../../../Lib/Helpers';

export default function Files_Dialog(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState({});
    const initialModalState = {
        f_id:0,
        f_title:"",
        f_path:0,
        file:"",
        emp_id:"",        
    }

    const initialErrorState = {
        f_id:"",
        f_title:"",
        f_path:"",
        file:"",
        emp_id:"",
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
    const [empList,setEmpList] = useState([]);

    
    const Save = (e) =>{
        e.preventDefault(); 
        let modal2 = {...modal};
        
        let formData = new FormData();
        for (const [key, value] of Object.entries(modal2)) {            
            formData.append("WorkFile["+key+"]",value);
        } 
        formData.append('token',state.UserState.token); 
        formData.append('w_id',props.w_id); 
        dispatch({type: "GS/LOADING_START"});
        axios.post(ApiBaseUrl+"/admin/work-entry/file-save",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).then((r)=>r.data).then((r)=>{
            if(AjaxValidate(r,setError)){
                setOpen(false);     
                setSelectedFile({});

                window.dispatchEvent(new CustomEvent(Event_onAfterSaveFile, modal2));                
            }
            dispatch({type: "GS/LOADING_STOP"});
        }).catch((err)=>{
            dispatch({type: "GS/LOADING_STOP"});
        }); 
    };

    const Get = (e) => {         
        let id = e.detail;
        setSelectedFile({});
        apiGet("/admin/work-entry/file-get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                setModal(r.data);                  
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
        setSelectedFile({});
    }

    const LoadEmployeeList = () => {
        apiGet("/admin/employee/listall",{ role:"" },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.emp_id, label: r.emp_fullname };
            });
            data.push({
                value:"",
                label:"--Select Employee--"
            })
            setEmpList(data);
        });
         
    }
    
    useEffect(()=>{
        LoadEmployeeList();
        window.addEventListener(Event_onNewFile,New);
        window.addEventListener(Event_onEditFile,Get);
        return () =>{
            window.removeEventListener(Event_onNewFile,New);
            window.removeEventListener(Event_onEditFile,Get);
        } 
    },[])



    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
            <FormControl.TextInput name="f_title" label="File Title"/>
            <FormControl.Select name="emp_id" label="Employee" options={empList} />

            <input type="file" id="file" style={{display:'none'}} onChange={(e)=>{
            setModal({...modal, file:e.target.files[0]});
            setSelectedFile(e.target.files[0]);
            }} />
    
            <label htmlFor="file">
                <span className="btn btn-info" type="button">Upload File</span>        
            </label>

            <a target="_blank" href={ApiBaseUrl+"/uploads/"+modal.f_path}
            style={  modal.f_path == '' ?  {display:'none'}: {}  } className="btn btn-info  ml-1">Download Attached File</a>
 
            <div style={{color:'gray'}}>{selectedFile.name}</div>
            <div style={{color:'red'}}>{error.file}</div>

        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}