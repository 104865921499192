import React from 'react';
import * as B from 'react-bootstrap';



const Confirmation = ({text="Are you sure?",...props}) => {
    return (
        <B.Modal.Dialog
       
            style={{display:props.open ? 'block' : 'none'}}
            size="md"
            >
            <B.Modal.Header closeButton onHide={props.onNo}>
                <B.Modal.Title>Please Confirm</B.Modal.Title>
            </B.Modal.Header>

            <B.Modal.Body dangerouslySetInnerHTML={{ __html: text }}>
            
        </B.Modal.Body>

            <B.Modal.Footer>
                <button onClick={props.onYes} className="btn btn-primary">Yes</button>
                <button onClick={props.onNo} className="btn btn-light">No</button>
            </B.Modal.Footer>
        </B.Modal.Dialog>
    );
}

export default Confirmation;