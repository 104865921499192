import React from 'react';
 


import { useState, useEffect } from 'react';
import {     apiGet, apiPost } from '../../../Lib/Helpers';
import {   useDispatch, useSelector } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../Config';
 
import Pagination from "react-js-pagination";
 
 
import { Event_onAfterSave, Event_onEdit, Event_onEditSelf,  Event_onAfterStatusChange } from './Events';
import { getStatusColor,  StatusListWithAll } from '../../../Constants/WorkEntry_StatusList';
import Files from './Files';
import NormalDialog from '../../../Lib/NormalDialog';
 
 
export default function WorkEntry_List(props){
    const state = useSelector(state => state);
   
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [fileDialog, setFileDialog] = useState({open:false, id:0, title:"Files"});
     

    const [filters, setFilter] = useState({
        search : "",
        proj_id:"",
        dateFilter:"",
        from:moment("01 Jan 2019").format("DD MMM YYYY"),
        to:moment().add(3,"M").format("DD MMM YYYY"),
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

 
    const Delete = () => {
        apiPost("/associate/work-entry/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    }
     
   
    const Loadlist = () => {
        if(typeof filters.proj_id === "undefined")
        { setFilter({...filters, proj_id : ""}); }
      
        

        apiGet("/associate/work-entry/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

     
 
    useEffect(()=>{
        window.addEventListener(Event_onAfterSave,Loadlist);        
        window.addEventListener(Event_onAfterStatusChange,Loadlist); 
        Loadlist();
        return () => {
            window.removeEventListener(Event_onAfterSave,Loadlist);
            window.removeEventListener(Event_onAfterStatusChange,Loadlist);
        }        
    },[]);
    
    
    useEffect(()=>{
        Loadlist();        
    },[filters]);
    let dateFilterList = [
        {value:"", label:"--None--"},
        {value:"w_completion_date", label:"Completion Date"},
        {value:"w_estimated_date", label:"Estimated Date"},
        {value:"w_lastupdated_on", label:"Last Updated Date"},
    ]

    const getEditBtn = (r) =>{
        if(r.w_status !== "Approved" || (r.w_status !== "Approved" && parseInt(r.alottedBy.emp_id) === parseInt(state.UserState.user.user_id))){
            return (
                <>
                    <B.Dropdown.Item
                    onClick={(e)=>{
                        e.preventDefault();
                        window.dispatchEvent(new CustomEvent(Event_onEditSelf, { detail: r.w_id }));
                    }} href="#">Edit</B.Dropdown.Item>
                </>
            )
        }
    }

    return <> 
        <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
        <NormalDialog onClose={(e)=>{ setFileDialog({...fileDialog, open:false}); }} title={fileDialog.title} open={fileDialog.open}>
            {fileDialog.open ? <Files w_id={fileDialog.id} /> : "" } 
        </NormalDialog>
        
            

        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th colSpan="10">
                            <div className="float-right form-row">
                                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}>

                                <div className="col">
                                    <FC.AutoFillRemote name="proj_id" 
                                    jsonSource={ApiBaseUrl+"/associate/suggest/project?token="+state.UserState.token} name="proj_id" label="Project" />
                                </div> 
                                <div className="col">
                                    <FC.Select name="status" label={"Status"} options={StatusListWithAll} />
                                </div>
                                <div className="col">
                                    <FC.Select name="dateFilter" label={"Date Filter"} options={dateFilterList} />
                                </div>                                
                                <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                                    <FC.DateInput name="from" label={"From"} />
                                </div>
                                <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                                    <FC.DateInput name="to" label={"To"} />
                                </div>
                                <div className="col">
                                    <FC.TextInput name="search" label={"Search"} />
                                </div> 
                                </FC.FormContextProvider>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th className="action-col">Actions</th>
                        <th>Project Title</th>
                        <th>Cost Type</th>
                        <th>Rate</th> 
                        <th>Quantity</th> 
                        <th>Amount</th>                            
                        <th>Description</th>
                        <th>Alotted By</th>
                        <th>Alotted To</th>
                        <th>Creation Date</th>
                        <th>Completion Date</th>
                        <th>Last Updated On</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {listData.records.map(r => (
                    <tr key={r.w_id}>
                        <td> 
                            <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                <B.Dropdown.Item  
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        window.dispatchEvent(new CustomEvent(Event_onEdit, { detail: r.w_id }));
                                    }} href="#">Open</B.Dropdown.Item>
                               
                                <B.Dropdown.Item  
                                    onClick={(e)=>{   
                                        e.preventDefault();                                     
                                        setFileDialog({id:r.w_id, title:"Files for "+r.w_description, open:true})
                                    }} href="#">
                                        Files
                                </B.Dropdown.Item> 

                                {r.w_status !== "Sent to AWD" &&     
                                <>   
                                {getEditBtn(r)}
                                <B.Dropdown.Item 
                                onClick={(e)=>{ 
                                    e.preventDefault(); 
                                                                       
                                    }} ></B.Dropdown.Item>                                    
                                </>}
                                                             
                            </B.DropdownButton>
                        </td>

                        <td>{r.project.proj_title}</td>
                        <td>{r.cost.cost_title}</td>
                        <td>{r.w_rate}</td>
                        <td>{r.w_qty}</td>
                        <td>{r.w_amount}</td>
                        <td>{r.w_description}</td>
                        <td>{r.alottedBy.emp_fullname}</td>
                        <td>{r.alottedTo.emp_fullname}</td>
                       
                        <td>{moment(r.w_creation_date).format("DD MMM YYYY")}</td>
                        <td>{moment(r.w_completion_date).format("DD MMM YYYY")}</td>                       
                        <td>{moment(r.w_lastupdated_on).format("DD MMM YYYY")}</td>
                        <td>
                            <strong className={"text-"+getStatusColor(r.w_status)}>
                            {r.w_status}
                            </strong>
                        </td>
                    </tr>   
                ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="10">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={10}
                                totalItemsCount={parseInt(listData.total)}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        
    
                      
        
    </>
}