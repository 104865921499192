import React, { useEffect, useState } from 'react';
 
import { Events } from './Constants'; 
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../../Lib/Helpers';
 
import * as Lv from '../../../../Constants/Leave';
 
import SaveDialog from '../../../../Lib/SaveDialog';
import * as FormControl from '../../../../Lib/FormControl';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import NormalDialog from '../../../../Lib/NormalDialog';
import moment from 'moment';
export default function Edit(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState({});
    const initialModalState = {
        rq_id:0,
        rq_dates:[],
 
        rq_subject:"",
        rq_message:"",
       // rq_status:"",
       // emp_id:0,
        rq_created_on:"",
        rq_updated_on:"",
    }

    const initialErrorState = {
        rq_id:"",
        rq_dates:"",
        
        rq_subject:"",
        rq_message:"",
       // rq_status:"",
       // emp_id:"",
        rq_created_on:"",
        rq_updated_on:"",        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const [accountBalance, setAccountBalance] = useState({"Casual":0,"Special":0,"Paid":0}); 
     
    const Save = (e) =>{
        e.preventDefault(); 
        apiPost("/associate/leave/post-request",{LeaveRequest:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Events.onAfterSave, {detail:modal}));
        },setError)  
    };

    const Get = (e) => {         
        let id = e.detail;
        setSelectedFile({});
        
        apiGet("/associate/leave/view-request",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                apiGet("/associate/leave/account-get-balance",{emp_id:state.UserState.user_id},(r)=> setAccountBalance(r.data));
                //console.log(JSON.parse(r.data.rq_dates)); 
                setModal({...r.data, rq_dates:JSON.parse(r.data.rq_dates)})                    
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    
    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
        apiGet("/associate/leave/account-get-balance",{emp_id:state.UserState.user_id},(r)=> setAccountBalance(r.data));
    }
 
    useEffect(()=>{ 
        window.addEventListener(Events.onNew,New);
        window.addEventListener(Events.onEdit,Get);
      //  LoadEmpList();
        LoadHolidayList();
        return () =>{
            window.removeEventListener(Events.onNew,New);
            window.removeEventListener(Events.onEdit,Get);
        } 
    },[])
/*
    const [empList, setEmpList] = useState([]);
    const LoadEmpList = () => {
        apiGet("/admin/employee/listall",{},(r)=>{            
            let list = r.data.records.map((r)=>{
                return {value:r.emp_id, label:r.emp_fullname};
            });
            list.unshift({value:"",label:"--Select Employee--"});
            setEmpList(list);
        });
    }
*/
    const [holidayList, setHolidayList] = useState([]); 
    const LoadHolidayList = (year) => {      
        apiGet("/associate/leave/holiday-listall",{},(r)=>{            
            let holidays = r.data.records.map((r2)=>{
                return r2.dt_date;
            }); 
            setHolidayList(holidays);            
        })        
    } 
 
    let TypeList = [...Lv.TypeList];
    TypeList.unshift({value:"",label:"--Select Leave Type--"});
 
    if(modal.rq_id > 0){
        return <>
            <NormalDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
                <ReadOnly modal={modal} />
            </NormalDialog>
        </>
    }

    return (<>   
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>

            
            <FormControl.TextInput name="rq_subject" label={"Subject"}/>
            <FormControl.Textarea name="rq_message" label={"Message"}/>

            
            <div className="row">
                <div className="col-md-6">
  
                    <FormControl.MultiDateInput plugins={[<DatePanel />]} mapDays={(dt)=>{
                        
                            let month = String(dt.date.month.number).padStart(2, '0');
                            let day = String(dt.date.day).padStart(2, '0');
                            let currentDate = dt.date.year+"-"+month+"-"+day;
                            
                            if(dt.date.weekDay.name == "Sunday"){
                                return {disabled:true}
                            }
                            if(holidayList.includes(currentDate.toString())){
                                return {disabled:true}
                            } 
                        
                    }} type="calendar" name="rq_dates" label={"Date"}/>
                     
                </div>
                      
                <div className="col-md-3">
                    
                    <strong className={"text-"+Lv.getStatusColor(modal.rq_status)}>{modal.rq_status}</strong>
                    {/*<FormControl.Select options={StatusList} name="rq_status" label={"Status"} />*/}        
                    {/*<FormControl.Select options={empList} name="emp_id" label={"Employee"} />*/}
                </div> 
                <div className="col-md-3">
                    <h5>Leave Account Balance</h5>
                    <hr />
                    <p><label>Casual:</label> {accountBalance.Casual}<br />
                    <label>Paid:</label> {accountBalance.Paid}<br />
                    <label>Special:</label> {accountBalance.Special}</p>

                    {/*ActionButtons()*/}

                </div>          
            </div>
 
        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>);
}

function ReadOnly({modal, ...props}){
    const getLabel = (label, value) => {
        return <>
            <div className="form-group">
            <label>{label}</label><br />
            {value}
            </div>
        </>
    }
    return <>
        <table className="table table-bordered">
            <tbody>
                <tr>
                    <td colSpan={2}>
                        {getLabel("Subject", modal.rq_subject)}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {getLabel("Message", modal.rq_message)}
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Requested Dates</label>
                        <div>
                        {modal.rq_dates.map(r => <>{moment(r).format("DD MMM YYYY")}<br /></>)}
                        </div>
                    </td>
                    <td>
                        <label>Status</label><br />
                        <strong className={"text-"+Lv.getStatusColor(modal.rq_status)}>{modal.rq_status}</strong>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {getLabel("Posted On", moment(modal.rq_created_on).format('DD MMM YYYY') )}
                    </td>
                </tr>
            </tbody>
        </table>

    
    </>
}