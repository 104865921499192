import { store } from "../Reducers/index";
import { ApiBaseUrl } from "../Config";

import  ajax   from 'can-ajax';

 

export const SetValidationErrors = (r,setErrorStateFunction) => {
    if(r.validation_status === false){
        let m = {};
        for (const er in r.validation_errors) {
            m[er] = r.validation_errors[er].join(" ");
        }
        setErrorStateFunction(m);
    }
};

 

export const apiGet = (url, data={},successFunction=null,setError=null) => {
    data.token = store.getState().UserState.token;
    
    ajax({
        url:ApiBaseUrl + url,
        type:"GET",
        data:data,
         
      /*  headers:{
            'Authorization':'Bearer '+store.getState().UserState.token
        }, */
        crossDomain:true, 
        beforeSend: () =>{
            store.dispatch({type: "GS/LOADING_START"});
        },
        success: () =>{
            store.dispatch({type: "GS/LOADING_STOP"});
        },
        error: () =>{
            store.dispatch({type: "GS/LOADING_STOP"});
        },
    }).then((r)=>{         
        if(AjaxValidate(r,setError)){
            if(typeof successFunction === "function"){
                successFunction(r);
            }
        }
    })

};

export const apiPost = (url,data={},successFunction=null,setError=null) => {
    data.token = store.getState().UserState.token;
 
    ajax({
        url:ApiBaseUrl + url,
        type:"POST",
        data:data,
       /* headers:{
            'Authorization':'Bearer '+store.getState().UserState.token
        }, */
        crossDomain:true,             
        beforeSend: () =>{
            store.dispatch({type: "GS/LOADING_START"});
        },
        success: () =>{
            store.dispatch({type: "GS/LOADING_STOP"});
        },
        error: () =>{
            store.dispatch({type: "GS/LOADING_STOP"});
        },   
    }).then((r)=>{                 
        if(AjaxValidate(r,setError)){
            if(typeof successFunction === "function"){
                successFunction(r);
            }
        }
    })
  
};

export const AjaxValidate = (response,setErrorStateFunction) => {
 
    if (response.authentication === true) {
        if (response.authorization === true) {
            if (response.error === false) {
                if (response.validation_status === true) {
                    if(response.msg.toString() !==""){
                        alert(response.msg);
                    }
                    return true;
                } else {
                    
                    if(typeof setErrorStateFunction === "function"){
                      
                        SetValidationErrors(response,setErrorStateFunction);
                    }
                    return false;
                }
            } else {
                alert(response.error);
                return false;
            }
        } else {
            alert("You are unauthorized to perform this action");
            return false;
        }
    } else if (response.authentication === false) {
        store.dispatch({
            type:"USER/LOGOUT",            
        })         
        return false;
    } 
};