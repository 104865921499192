import React, { useEffect, useState } from 'react';
 
import {   Event_onAfterSave, Event_onEdit, Event_onNew } from './Event'; 
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../../Lib/Helpers';
import * as FormControl from '../../../../Lib/FormControl';
import axios from 'axios';
import { AjaxValidate } from '../../../../Lib/Helpers';
import { ApiBaseUrl } from '../../../../Config';

import SaveDialog from '../../../../Lib/SaveDialog';
 
export default function Edit(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState({});
    const initialModalState = {
        file_id:0,
        file_title:"",
        types:"",
        states:"",
        keywords:"",        
      //  emp_id:"",
    }

    const initialErrorState = {
        file_id:"",
        file_title:"",
        types:"",
        states:"",
        keywords:"",
     //   emp_id:""
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const Save = (e) =>{
        e.preventDefault(); 
        let modal2 = {...modal};
        
        let formData = new FormData();
        for (const [key, value] of Object.entries(modal2)) {            
            formData.append("QbFile["+key+"]",value);
        } 
        formData.append('token',state.UserState.token);         
        dispatch({type: "GS/LOADING_START"});
        axios.post(ApiBaseUrl+"/manager/qb-bank/save",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).then((r)=>r.data).then((r)=>{
            if(AjaxValidate(r,setError)){
                setOpen(false);     
                setSelectedFile({});

                window.dispatchEvent(new CustomEvent(Event_onAfterSave, modal2));                
            }
            dispatch({type: "GS/LOADING_STOP"});
        }).catch((err)=>{
            dispatch({type: "GS/LOADING_STOP"});
        });

/*
        e.preventDefault(); 
        apiPost("/admin/qb-bank/save",{QbKeyword:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Event_onAfterSave, modal));
        },setError)  */
    };

    const Get = (e) => {         
        let id = e.detail;
        setSelectedFile({});
        apiGet("/manager/qb-bank/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                setModal(r.data); 
                console.log(modal);
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
 
    }

     
    
    useEffect(()=>{ 
        window.addEventListener(Event_onNew,New);
        window.addEventListener(Event_onEdit,Get);
        return () =>{
            window.removeEventListener(Event_onNew,New);
            window.removeEventListener(Event_onEdit,Get);
        } 
    },[])

    const [stateList, setStateList] = useState([]);
    const loadStateList = () => {
        apiGet("/manager/qb-keyword/listall",{type:"State"},(rs)=>{
            let list = rs.data.records.map((r)=>{
                return {"label":r.kw_title, "value":r.kw_id}
            });
            list.push({"label":"--Select State--","value":""});
            setStateList(list);
        });
    }
    const [typeList, setTypeList] = useState([]);
    const loadTypeList = () => {
        apiGet("/manager/qb-keyword/listall",{type:"Type"},(rs)=>{
            let list = rs.data.records.map((r)=>{
                return {"label":r.kw_title, "value":r.kw_id}
            });
            list.push({"label":"--Select Type--","value":""});
            setTypeList(list);
        });
    }
    const [empList, setEmpList] = useState([]);
    const loadEmpList = () => {
        apiGet("/manager/employee/listall",{},(rs)=>{
            let list = rs.data.records.map((r)=>{
                return {"label":r.emp_fullname, "value":r.emp_id}
            });
            list.push({"label":"--Select Employee--","value":""});
            setEmpList(list);
        });
    }
    useEffect(()=>{
        loadStateList();
        loadTypeList();
        loadEmpList();
    },[]);
 
    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
            <FormControl.TextInput name="file_title" label="File Title" />
            <div className="row">
                <div className="col-md-4">
                    <FormControl.Select name="types" label={"Type"} options={typeList}/>
                </div>
                <div className="col-md-4">
                    <FormControl.Select name="states" label={"States"} options={stateList} />        
                </div>
                {/* <div className="col-md-4">
                    <FormControl.Select name="emp_id" label={"Uploaded By Employee"} options={empList} />        
</div> */}
            </div>
            <FormControl.TextInput name="keywords" label="Keywords" />
            
            <input type="hidden" name="file_id" value={modal.file_id} />



            <input type="file" id="file" style={{display:'none'}} onChange={(e)=>{
            setModal({...modal, file:e.target.files[0]});
            setSelectedFile(e.target.files[0]);
            }} />
    
            <label htmlFor="file">
                <span className="btn btn-info" type="button">Upload File</span>        
            </label>

            <a target="_blank" href={ApiBaseUrl+"/uploads/"+modal.file_path}
            style={  modal.file_path == '' || modal.file_path == undefined ?  {display:'none'}: {}  } className="btn btn-info  ml-1">Download Attached File</a>
 
            <div style={{color:'gray'}}>{selectedFile.name}</div>
            <div style={{color:'red'}}>{error.file}</div>

        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}