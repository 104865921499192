import React from 'react';
import AdminLayout from '../../../Layouts/AdminLayout';


import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../../Config';
 
import Pagination from "react-js-pagination";
 
 import { Events } from './Constants';

import * as Lv from '../../../../Constants/Leave';

export default function List(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
   // const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    
    const [filters, setFilter] = useState({
        search : "",
      //  type : "",
      //  emp_id : "",
        status : "",
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    /*
    const Delete = () => {
        apiPost("/admin/leave/request-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    }*/
 

    const Loadlist = () => {
          
        apiGet("/associate/leave/request-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    let StatusList = [...Lv.StatusList];
    StatusList.unshift({value:"",label:"--Select Status--"});
    let TypeList = [...Lv.TypeList];
    TypeList.unshift({value:"",label:"--Select Status--"});

    useEffect(()=>{
        window.addEventListener(Events.onAfterSave,Loadlist);        
        Loadlist();
        //LoadEmpList();        
        return () => {
            window.removeEventListener(Events.onAfterSave,Loadlist);            
        }        
    },[]);
    
    useEffect(()=>{
        Loadlist();
    },[filters]);
/*
    const [empList, setEmpList] = useState([]);
    const LoadEmpList = () => {
        apiGet("/admin/employee/listall",{},(r)=>{            
            let list = r.data.records.map((r)=>{
                return {value:r.emp_id, label:r.emp_fullname};
            });
            list.unshift({value:"",label:"-- Select Employee --"});
            setEmpList(list);
        });
    }   
*/    
    
     
    return <> 
   {/* <Confirmation open={confirmDialogState.open} text={confirmDialogState.title} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
        */}

    <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="4">
                        <div className="float-right form-row">
                            <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                            <div className="col">
                                <FC.Select name="status" label={"Status"} options={StatusList} />
                            </div>
                            {/*<div className="col">
                                <FC.Select name="type" label={"Type"} options={TypeList} />
                            </div> 
                            
                            <div className="col">
                                <FC.Select name="emp_id" label={"Employee"} options={empList} />
                            </div>                            
                            */}
                            <div className="col">                                
                                <FC.TextInput name="search" label={"Search"} />
                            </div> 
                            </FC.FormContextProvider>
                        </div>
                    </th>                
                </tr>
                <tr>
                    <th style={{width:120}}>Actions</th>
                    {/*<th style={{width:200}}>Leave Type</th>*/}
                    <th>Subject</th>
                    {/*<th style={{width:350}}>Employee</th>*/}
                    <th style={{width:200}}>Posted On</th>
                    <th style={{width:120}}>Status</th>                     
                </tr>
            </thead>
            <tbody>
            {listData.records.map(r => (
            <tr>
                <td> 
                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">                        
                        <B.Dropdown.Item  
                            onClick={(e)=>{
                                window.dispatchEvent(new CustomEvent(Events.onEdit, { detail: r.rq_id }));
                            }} href="#">View</B.Dropdown.Item>                         
                        {/*<B.Dropdown.Item 
                        onClick={(e)=>{ 
                            e.preventDefault();
                            setConfirmDialogState({open:true, id:r.rq_id, title:"Are you sure want to DELETE?"})                                    
                            }
                        } className="text-danger" href="#">Delete</B.Dropdown.Item>*/}
                    </B.DropdownButton>
                </td>
                {/*<td>{r.rq_leave_type}</td>*/}
                <td>{r.rq_subject}</td>                
                {/*<td>{r.employee.emp_fullname}</td>*/}
                <td>{moment(r.rq_created_on).format("DD MMM YYYY HH:mm:ss")}</td>          
                <td><strong className={"text-"+Lv.getStatusColor(r.rq_status)}>{r.rq_status}</strong></td> 
            </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="4">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
        </>
}