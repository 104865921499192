import React from 'react'; 
import moment from 'moment';
import { useState, useEffect } from 'react';
import { apiGet, apiPost } from '../../../Lib/Helpers';
import ProjectTaskDialog from '../Project/ProjectTask_Dialog';
import { useDispatch, useSelector } from 'react-redux';
import * as B from 'react-bootstrap';
import Confirmation from '../../../Lib/Confirmation';
const TaskRecords = (props) => {    
 
    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const state = useSelector(state => state);
    //const [ employeeList, setEmployeeList ] = useState([]);
    const initialModalState = {
        ts_id:0,
        ts_description:"",
        ts_rate:"",
        ts_qty:"",
        ts_amount:"",
        ts_estimated_date:moment().format("DD MMM YYYY"),
        ts_completion_date:moment().format("DD MMM YYYY"),
        ts_alotted_by:"",
        ts_alotted_to:"",
        ts_status:"",
        cost_id:0,
        task_id:"",
        user_id:state.UserState.user.user_id,
    }

    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialModalState,
        cost_id:"",user_id:"",
        ts_estimated_date:"", ts_completion_date:""
    })
    const dispatch = useDispatch();
    const [taskList, setTaskList] = useState([]);
    const [associateList, setAssociateList] = useState([]);
    const [managerList, setManagerList] = useState([]);
 
    const Delete = () =>{
        apiPost("/admin/project-task/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            dispatch({type:"PAYMENT/UPDATELIST"})
        })
    };
 
    const Get = (id) =>{
        apiGet("/admin/project-task/get",{
            id:id
        },(r)=>{
            updateAssociateList(r.data.ts_alotted_to);
            updateManagerList(r.data.ts_alotted_by);

            setModal({...r.data,
                ts_estimated_date: moment(r.data.ts_estimated_date).format("DD MMM YYYY"),
                ts_completion_date: moment(r.data.ts_completion_date).format("DD MMM YYYY"),         
            });
            setError({...initialModalState,
                cost_id:"", user_id:"",
                ts_estimated_date:"", ts_completion_date:""
            });
            setDialogState({open:true,title:"Edit"});
        })
    };
 
    const Save = (e) =>{
        e.preventDefault();
        
       // let modal2 = {...modal};
        //modal2.ts_estimated_date = moment(modal.ts_estimated_date._d).format("DD MMM YYYY");
        //modal2.ts_completion_date = moment(modal.ts_completion_date._d).format("DD MMM YYYY");
 
        apiPost("/admin/project-task/save",{
            ProjectTask: modal,
        },(r)=>{
            setDialogState({open:false, title:""});
            dispatch({type:"PAYMENT/UPDATELIST"})
        }, setError)
    };

    useEffect(()=>{
        apiGet("/admin/task/listall",{},(r)=>{
            setTaskList(r.data.records);
        });
        /*apiGet("/admin/employee/listall",{},(r)=>{
            setEmployeeList(r.data.records);
        });*/
        updateAssociateList();
        updateManagerList();
    },[]);

    const updateAssociateList = (include=0) => {
        apiGet("/admin/employee/loadlist",{search:'', include: include, role_id:1, perpage:1000000000},(r)=>{
            setAssociateList(r.data.records);
        });
    }

    const updateManagerList = (include=0) => {
        apiGet("/admin/employee/loadlist",{search:'', include: include, role_id:2, perpage:1000000000},(r)=>{
            setManagerList(r.data.records);
        }); 
    }

    return <>
    <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
    <ProjectTaskDialog 
        taskList = {taskList} managerList = {managerList} associateList = {associateList}
        setError={setError} setModal={setModal} error={error} modal={modal}
        onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title} /> 
        <div className="table-responsive">
        <table className="table">
            <thead>
                    <tr>
                        <td>Actions</td>
                        <td>Project</td>
                        <td>Task</td>                        
                        <td>Rate</td>
                        <td>Quantity</td>
                        <td>Amount</td>
                        <td>Completed On</td>
                        <td>Status</td>
                    </tr>
            </thead>
            <tbody>
            {['Hard','Soft'].map(type2 => {
                return <>
                    <tr className="table-primary"><th colSpan="11">{type2}</th></tr>

                    {props.records[type2].map((r) => (
                        <tr key={r.ts_id}> 
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e)=>Get(r.ts_id)} href="#">Edit</B.Dropdown.Item>                                 
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.ts_id})                                    
                                        }
                                    } 
                                    className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                
                                </B.DropdownButton>
                            </td>
                            <td>{r.cost.project.proj_title}</td>
                            <td>{r.task.task_title}</td>                        
                            <td>{r.ts_rate}</td>
                            <td>{r.ts_qty}</td>
                            <td>{r.ts_amount}</td>
                            <td>{moment(r.ts_completion_date).format("DD MMM YYYY")}</td>
                            <td><span style={{"color":props.color}}>{props.status}</span></td>
                        </tr>
                    ))}

                </>
            })}

                 
            </tbody>
        </table>
        </div>
    </>
}

export default TaskRecords;
