import React  from 'react';
 
import moment from 'moment';
//import { paymentModeArray } from  '../../../Config';
//import { apiPost } from '../../../Lib/Helpers';
import PaymentQuick from './Payment_Quick';
 
const Payment_Summery = (props) => {
 
    let month = moment(props.month+"-01").format("MMM YYYY");
    let month_date = moment(props.month+"-01").format("YYYY-MM-DD");
   

 
    if(props.data !== false){
        return (
            <React.Fragment>
                <div className="card">
                <table className="table">                
                    <thead>
                        <tr>
                            <td></td>
                            <td>For {month}</td>
                            <td>Till {month}</td>
                        </tr>
                    </thead>                         
                    <tbody>                        
                        <tr>
                            <td>Old Incentives</td>                            
                                             
                            <td>{props.data.month_summery.oldwork.incentive}</td>
                            <td>{props.data.summery.oldwork.incentive}</td>
                        </tr>
                        <tr>
                            <td>Old Project</td>     
                            <td>{props.data.month_summery.oldwork.project_work}</td>
                            <td>{props.data.summery.oldwork.project_work}</td>
                        </tr>

                        
                        
                        <tr>                
                            <td>Total Amount</td>        
                            <td>{props.data.month_summery.total_amount}</td>                
                            {/*<td>{props.data.month_summery.total_amount + props.data.month_summery.oldwork.project_work}</td>*/} 
                            <td>-NA-</td>           
                        </tr>
                        <tr>
                            <td>Target</td>                                                        
                            <td>{props.data.month_summery.target}</td>
                            <td>-NA-</td>    
                        </tr>
                        <tr>              
                            <td>Achievement</td>                       
                            <td>{props.data.month_summery.workdone}</td>                            
                            <td>{props.data.summery.workdone}</td>       
                        </tr>


                        <tr>
                            <td>Incentive Earned</td>                             
                            <td>{props.data.month_summery.incentive}</td>
                            {/*<td>{props.data.month_summery.incentive + props.data.month_summery.oldwork.incentive}</td> */}
                            <td>{props.data.summery.incentive}</td>
                            {/*<td>{props.data.summery.incentive + props.data.summery.oldwork.incentive}</td>*/}
                        </tr>                        
                        <tr>
                            <td>Amount Paid</td>
                            <td>{props.data.month_summery.payment}</td>
                            <td>{props.data.summery.payment}</td>
                        </tr>                        
                    </tbody>
                </table>
                <div style={{textAlign:"center", paddingTop:10, paddingBottom:10, backgroundColor: "rgba(0,0,0,0.1)" }}>
                    <div style={{fontSize:"1.2rem"}}>Needed To Pay Till {month} : Rs {props.data.summery.balance}</div>
                    <em>This may contain previous pending payments</em>
                </div>
                <PaymentQuick data={props.data}  
                month={month_date}               
                onSubmit={() => {
                    props.setRefresh()
                }} emp_id={props.emp_id} />
                </div>
            </React.Fragment>
        )
    } else {
        return <React.Fragment />
    }
    
}

export default Payment_Summery;