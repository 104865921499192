import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List from './WorkEntry_List';
import { Event_onAfterSave, Event_onEdit, Event_onNew } from './Events';
import AdminLayout from '../../Layouts/AdminLayout';
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../Lib/Helpers';
import * as FormControl from '../../../Lib/FormControl';
import moment from 'moment';
import SaveDialog from '../../../Lib/SaveDialog';
import { ApiBaseUrl } from '../../../Config';

import Select from 'react-select';

import { getStatusColor, StatusList, StatusListWithAll } from '../../../Constants/WorkEntry_StatusList';
export default function WorkEntry_Dialog(props){
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const initialModalState = {
        w_id:0,
        w_description:"",
        w_rate:0,
        w_qty:0,
        w_amount:0,
        w_estimated_date:moment().format("DD MMM YYYY"),
        w_completion_date:moment().format("DD MMM YYYY"),
        w_alotted_by:"",
        w_alotted_to:"",
        w_status:"Pending",
        task_id:"",
        cost_id:"",
        proj_id:"", 

        project:{
            proj_title: ""
        }
    }

    const initialErrorState = {
        w_id:"",
        w_description:"",
        w_rate:"",
        w_qty:"",
        w_amount:"",
        w_estimated_date:"",
        w_completion_date:"",
        w_alotted_by:"",
        w_alotted_to:"",
        w_status:"",
        task_id:"",
        cost_id:"",
        proj_id:"", 
        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [openReadOnly, setOpenReadOnly] = useState(false);
   
    const Save = () => {
        apiPost("/admin/work-entry/save", { WorkEntry: modal }, (response) => {
          setOpen(false);
        }, setError);
      };


      const reloadTableBody = () => {
        // Assuming you have a reference to the tbody
        const tbody = document.getElementById('yourTable').getElementsByTagName('tbody')[0];
      
        // Clear existing tbody content
        tbody.innerHTML = '';
      
        // Fetch and append new data to the tbody
        fetchDataAndPopulateTable();
      }
      
      const fetchDataAndPopulateTable = () => {
        // Fetch data and populate the table body here
        // You need to implement the logic to fetch data and create table rows based on the data
        // Example:
        // apiGet("/admin/work-entry/getAll", {}, (data) => {
        //   data.forEach((entry) => {
        //     const row = document.createElement('tr');
        //     // Create and append cells based on your data structure
        //     // Example: row.innerHTML = `<td>${entry.property1}</td><td>${entry.property2}</td>`;
        //     tbody.appendChild(row);
        //   });
        // });
      }


    const Get = (e) => {         
        let id = e.detail;
        apiGet("/admin/work-entry/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setError(initialErrorState);    

                if(r.data.w_status === "Sent to AWD"){
                    //setOpenReadOnly(true);
                    //setOpen(false);      
                    setModal(r.data);                 
                    setTitle("View");
                    setOpen(true);
                } else {
                    LoadCostList(r.data.proj_id,()=>{
                        setTitle("Edit");
                        setModal(r.data);                  
                        setOpen(true);
                        //setOpenReadOnly(false);
                    });
                }
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => {
         
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
    }

    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");

    useEffect(()=>{
        window.addEventListener(Event_onNew,New);
        window.addEventListener(Event_onEdit,Get);
        return () =>{
            window.removeEventListener(Event_onNew,New);
            window.removeEventListener(Event_onEdit,Get);
        } 
    },[])

    const [taskNatureList,setTaskNatureList] = useState([]);
    const [managerList,setManagerList] = useState([]);
    const [associateList,setAssociateList] = useState([]);
 
    useEffect(()=>{ 
        LoadTaskNatureList();   
        LoadEmployeeList();
    },[]);

    const LoadTaskNatureList = () => {
        apiGet("/admin/task/listall",{},(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.task_id, label: r.task_title };
            });
            data.push({value:"",label:"-- Select Task Nature --"});
            setTaskNatureList(data);
        });
    }

    const LoadEmployeeList = () => {
        apiGet("/admin/employee/listall",{ role:2 },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.emp_id, label: r.emp_uid + " - " + r.emp_fullname };
            });
            data.push({value:"",label:"-- Select Employee --"});
            setManagerList(data);
        });
        apiGet("/admin/employee/listall",{ role:1 },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.emp_id, label:r.emp_uid + " - " + r.emp_fullname + " - " + r.alotted_work_count };
            });
            data.push({value:"",label:"-- Select Employee --"});
            setAssociateList(data);
        });
    }

    const [costList, setCostList] = useState([]);
    const LoadCostList = (id,childfunction=null) => {
        apiGet("/admin/project-cost/listall",{ proj_id: id },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.cost_id, label: r.cost_title };
            });            
            data.push({value:"",label:"--Select Cost Type--"});   
            setCostList(data);
            if(childfunction!==null){
                childfunction();
            }
        });
    }
    if(modal.w_status === "Sent to AWD"){
        return <WorkEntry_Dialog_ReadOnly
            setOpen={setOpen} Save={Save} title={title} open={open} modal={modal} setModal={setModal} error={error}
        />
    }

    StatusList.splice(6,1); //Removing Send to AWD Status
    

    return <>
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>

            <B.Row>
                <B.Col md={6}>
                    <FormControl.AutoFillRemote 
                    onSelectOption={(r)=>{                       
                       LoadCostList(r.value,()=>{
                           setModal({...modal, cost_id : "", proj_id:r.value})
                       });
                    }}
                    jsonSource={ApiBaseUrl+"/admin/suggest/project?token="+state.UserState.token}
                    initialInputText={modal.project.proj_title}
                    name="proj_id" label="Project"/>
                </B.Col>
                <B.Col md={6}>
                    <FormControl.Select name="cost_id" label="Cost" options={costList} />
                </B.Col> 
            </B.Row>
            <FormControl.TextInput name="w_description" label="Work Description"/>
            <B.Row>
                <B.Col md={4}>
                    <FormControl.TextInput name="w_rate" label="Rate"/>
                </B.Col>
                <B.Col md={4}>
                    <FormControl.TextInput name="w_qty" label="Quantity"/>
                </B.Col>
                <B.Col md={4}>
                    AMOUNT: {(modal.w_rate * modal.w_qty)}
                </B.Col>
            </B.Row>
            <FormControl.Select name="task_id" label="Task Nature" options={taskNatureList} />
            <B.Row>
                <B.Col md={6}>
                    <FormControl.DateInput name="w_estimated_date" label="Estimated Date"/>
                </B.Col>
                <B.Col md={6}>
                    <FormControl.DateInput name="w_completion_date" label="Completion Date"/>
                </B.Col> 
            </B.Row>
            <B.Row>
                <B.Col md={4}>
                    <label htmlFor="w_alotted_by">Alotted By</label>
               <Select
                 id="w_alotted_by"
                 name="w_alotted_by"
                 value={managerList.find((option) => option.value === modal.w_alotted_by)}
                 onChange={(selectedOption) => setModal({ ...modal, w_alotted_by: selectedOption.value })}
                 options={managerList}
                 isSearchable={true} // This enables the search box
                 placeholder="-- Select Employee --"
                    />
                </B.Col>
                <B.Col md={4}>
                <label htmlFor="w_alotted_to">Alotted To</label>
               <Select
                 id="w_alotted_to"
                 name="w_alotted_to"
                 value={associateList.find((option) => option.value === modal.w_alotted_to)}
                 onChange={(selectedOption) => setModal({ ...modal, w_alotted_to: selectedOption.value })}
                 options={associateList}
                 isSearchable={true} // This enables the search box
                 placeholder="-- Select Employee --"
                    />
           </B.Col>
                <B.Col md={4}>
                    <FormControl.Select name="w_status" label="Status" options={StatusList} />
                </B.Col> 
            </B.Row>
        </FormControl.FormContextProvider>
    </SaveDialog>
    
    </>
}


const WorkEntry_Dialog_ReadOnly = ({
    modal, setModal, error, open, setOpen, Save, title, ...props
}) => {
 
    const getLabel = (label, value)=>{
        return <>
            <div className="form-group">
                <label>{label}</label><br />
                {value}
            </div>
        </>;
    }
    return <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
    <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>

        <B.Row>
            <B.Col md={6}>
               {getLabel("Project",modal.project.proj_title)}           
            </B.Col>
            <B.Col md={6}>
               {getLabel("Cost",modal.cost.cost_title)}                
            </B.Col> 
        </B.Row>
        
        {getLabel("Description",modal.w_description)}         
 
        <B.Row>
            <B.Col md={4}>
                {getLabel("Rate",modal.w_rate)}                
            </B.Col>
            <B.Col md={4}>
                {getLabel("Quantity",modal.w_qty)}                
            </B.Col>
            <B.Col md={4}>
                {getLabel("Amount",modal.w_amount)}                
            </B.Col>
        </B.Row>

        {getLabel("Task Nature",modal.taskNature.task_title)}
        
        <B.Row>
            <B.Col md={6}>
                {getLabel("Estimated Date",moment(modal.w_estimated_date).format("DD MMM YYYY") )}                
            </B.Col>
            <B.Col md={6}>
                {getLabel("Completion Date",moment(modal.w_completion_date).format("DD MMM YYYY") )}                
            </B.Col> 
        </B.Row>

        <B.Row>
            <B.Col md={4}>
                {getLabel("Alotted By",modal.alottedBy.emp_fullname)}                     
            </B.Col>
            <B.Col md={4}>
                {getLabel("Alotted To",modal.alottedTo.emp_fullname)}                     
            </B.Col>
            <B.Col md={4}>
                {getLabel("Status",modal.w_status)}                     
            </B.Col> 
        </B.Row>

    </FormControl.FormContextProvider>
</SaveDialog>
}