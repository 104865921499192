 
import React from 'react';

 


 
import Modal from 'react-modal';
import * as FA from 'react-icons/fa';
const NormalDialog = ({    
    open=false,
    onClose=()=>{},
    title="",
    width=1100,   ...props
}) => {
     

    let styles = {
         
        btnClose : {
          //  position:'absolute',
          //  right:10,
          //  top:7,
            fontSize:20,
            background:'transparent',
            border:'none',
           
        },
        header : {
         //   position:'absolute',            
         //   top:0,
         //  left:0,
            fontSize:18,
            padding:10,
            borderBottom:"1px solid silver",
            width:'100%',
            height:50,
        },
        content :{  
         //   position:'absolute',
         //   top:50,
         //   bottom:55,
            overflow:'auto',
            width:'100%',
            //backgroundColor:'rgba(0,0,0,0.05)',
            
            left:0, right:0,
            padding:10, 
        },
        footer : {
           // position:'absolute',
          
          //  bottom:0, 
          //  left:0,
            width:'100%',
            paddingTop:10,
            borderTop:"1px solid silver",
        }
    }
    /*styles = {         
        btnClose : { },
        header : { },
        content :{  },
        footer : { }
    }*/

 
    let st = {
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        },
        content: {
          top:10, left:10, right:10, bottom:"initial",
          padding: 10,
          position: 'absolute',  
          //maxHeight:"calc(100vh - 40px)",    
          maxWidth:width,          
          marginLeft:'auto',
          marginRight:'auto',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',          
          boxShadow:'0px 0px 20px rgba(0,0,0,0.3)'
        } 
    };
     
    return (
        <Modal
        isOpen={open}
        //onAfterOpen={afterOpenModal}
        onRequestClose={onClose}
        style={st} 
        >
        <div className="clear" style={styles.header}>
            <div className="float-left">
                <div>{title}</div>
            </div>
            <div className="float-right">
                <button style={styles.btnClose} onClick={onClose}>
                    <FA.FaWindowClose />
                </button>
            </div>
        </div>    
        
            
        <div style={styles.content}>
            {props.children}
        </div>
        <div style={styles.footer}>
            {props.footer}
        </div>
          
      </Modal>
        
         
    )
}


/*

const NormalDialog = ({    
    open=false,
    onClose=()=>{},
    title="",
    width=1100, ...props
}) => {
 
    return (
        
        <Modal style={{width:width}} open={open} onClose={onClose} center>
            {title}
            <hr />
            {props.children}
            <hr />
            {props.footer}
        </Modal> 
         
    )
}


const NormalDialog = ({    
    open=false,
    onClose=()=>{},
    title="",
    width=1100, ...props
}) => {
 
    return (
        <div className="modal-bg" style={{display:open ? 'block' : 'none'}}>
 

        <B.Modal.Dialog     
        style={{display:open ? 'block' : 'none', width:width}}
        size={props.maxWidth ? props.maxWidth : "xl"}
        >
        <B.Modal.Header   closeButton onHide={onClose}>
            <B.Modal.Title>{title}</B.Modal.Title>
        </B.Modal.Header>

        <B.Modal.Body>
        {props.children} 
        </B.Modal.Body>

        <B.Modal.Footer>
            {props.footer}
        </B.Modal.Footer>
        </B.Modal.Dialog>
        </div>
         
    )
} */

export default NormalDialog;

