import React from 'react';
 
 

import { useState, useEffect } from 'react';
import { apiGet } from '../../../Lib/Helpers';
import moment from 'moment';
import { ApiBaseUrl } from '../../../Config';
import { useSelector  } from 'react-redux';

import * as B from 'react-bootstrap';

import PaymentSummery from './Payment_Summery';
import PaymentMonthData from './Payment_MonthData';

import PaymentAllData from './Payment_AllData';
 
 
var formattedNumber = (myNumber) => ("0" + myNumber).slice(-2);

const Payment = (props) => {
 
    const [data, setData] = useState(false);
    const [month, setMonth] = useState(moment().format("YYYY-MM"));
    
    const [refresh, setRefresh] = useState(false);

   

    const state = useSelector(state => state);
   

    useEffect(()=>{ 
        updateReport();
    },[month, refresh, state.PaymentState])

    useEffect(()=>{ 
        updateReport();
    },[])

    const updateReport = () => {
        apiGet("/admin/payment/report",{
            emp_id:props.emp_id,
            month:month
        },(r)=>{
         setData(r.data);
         
        });
    }

    var styles = `
    .tab-content { 
        padding:10px;
    }
    `;                    
    var styleSheet = document.createElement("style") 
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet);  

    

    let monthList = {"01":"Jan","02":"Feb","03":"Mar","04":"Apr","05":"May","06":"Jun","07":"Jul","08":"Aug","09":"Sep","10":"Oct","11":"Nov","12":"Dec"};
    let monthArray = [];
    for (let yr = 2019; yr <= 2035; yr++) {
        for (let mn = 1; mn <= 12; mn++) {
            let key = yr+"-"+formattedNumber(mn);
            let label = monthList[formattedNumber(mn)]+" "+yr;
            monthArray.push({
                key:key, label:label
            })
        }
    }
    
    return (
        <React.Fragment>
            <div className="bg-light p-2 mb-2">
                <B.Row>
                    <B.Col md={9}>
                    <button onClick={()=>{
                let url = ApiBaseUrl+"/admin/payment/report?print=true&emp_id="+props.emp_id+"&month="+month+"&token="+state.UserState.token;
                window.open(url);
            }} className="btn btn-primary">Print Report</button>
                    </B.Col>
                    <B.Col md={3}>
                        <select id="month" className="form-control" value={month} style={{ width:"100%"}}
                        onChange={(e)=>setMonth(e.target.value)}>                        
                            {monthArray.map((r)=>
                                <option key={r.key} value={r.key}>{r.label}</option>
                            )}
                        </select>
                    </B.Col>
                </B.Row>
            </div>

            <B.Tabs
            
            /*onSelect={(eventKey)=>{
                if(eventKey === "print_report"){
                    let url = ApiBaseUrl+"/admin/payment/report?print=true&emp_id="+props.emp_id+"&month="+month+"&token="+state.UserState.token;
                    window.open(url);
                     
                }
            }}*/ 
            
            defaultActiveKey="summary" id="uncontrolled-tab-example">
                <B.Tab eventKey="summary" title="Summary">
                      <PaymentSummery 
                    refresh={refresh} setRefresh={() => setRefresh(!refresh)}
                    month={month} 
                    data={data} 
                    emp_id={props.emp_id}                 
                    onChange={()=>{
                        updateReport();
                    }} />  
                </B.Tab>
                <B.Tab eventKey="monthly_records" title="Monthly Records">
                     <PaymentMonthData 
                    refresh={refresh} setRefresh={() => setRefresh(!refresh)}
                    month={month} 
                    data={data} 
                    emp_id={props.emp_id} />  
                </B.Tab>     
                <B.Tab eventKey="payment_records" title="Payment Records">
                     <PaymentAllData 
                    refresh={refresh} setRefresh={() => setRefresh(!refresh)}
                    emp_id={props.emp_id} 
                    onChange={()=>{
                        updateReport();
                    }} />  
                </B.Tab> 
                       
            </B.Tabs>

            
             
        </React.Fragment>
    )
};

export default Payment;
