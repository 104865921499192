import React from 'react';
import { useState, useEffect } from 'react';
import {    apiGet, apiPost } from '../../../Lib/Helpers';
import {    useSelector } from 'react-redux';

import * as FC from '../../../Lib/FormControl';
import moment from 'moment';

import { ApiBaseUrl } from '../../../Config';
import Pagination from "react-js-pagination";
 
import { Event_onAfterSave, Event_onEdit  } from './Events';
import { getStatusColor,  StatusListWithAll } from '../../../Constants/WorkEntry_StatusList';
import { Pie, PieChart, Cell } from 'recharts';
 
 
export default function Assignedwork_List(props){
    const state = useSelector(state => state);
   
    const [filters, setFilter] = useState({
        search : "",
        proj_id: "",
        cost_id: "",
        join_id: "",
        alotted_to:"",
        page:1,
       
        status:"",
        dateFilter:"",
        from:moment("01 Jan 2019").format("DD MMM YYYY"),
        to:moment().add(3,"M").format("DD MMM YYYY"),
        perpage:30,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const [chartData, setChartData] = useState({
        records:[],
        total:0,
    });
    const [view,setView] = useState("List");
     
    const Loadlist = () => {
        if(typeof filters.proj_id === "undefined")
        { setFilter({...filters, proj_id : ""}); }
        if(typeof filters.alotted_by === "undefined")
        { setFilter({...filters, alotted_by : ""}); }
        if(typeof filters.alotted_to === "undefined")
        { setFilter({...filters, alotted_to : ""}); }
        

        apiGet("/manager/assigned-work/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
       
    }
    const [associateList, setAssociateList] = useState([]); 
    const LoadAssociateList = () => {
       
    }
    useEffect(()=>{
        window.addEventListener(Event_onAfterSave,Loadlist);
        Loadlist();
        LoadAssociateList();
        return () => {
            window.removeEventListener(Event_onAfterSave,Loadlist);
        }        
    },[]);
    
    useEffect(()=>{
        Loadlist();
        
    },[filters]);

    const [costList, setCostList] = useState([]);
    const LoadCostList = (proj_id) => {
        apiGet("/manager/project-cost/listall",{proj_id:proj_id},function(r){            
            const list = r.data.records.map((r2)=>{
                return {value:r2.cost_id, label:r2.cost_title};
            });
            list.push({value:"",label:"--All/Any--"})
            setCostList(list);
            setFilter({...filters, cost_id:"", proj_id:proj_id});
           
        })
    }

    return <>  
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th colSpan="7">
                            <div className="float-right form-row">
                                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}>
                                <div className="col">
                                    <FC.AutoFillRemote name="proj_id" 
                                    onSelectOption={(item)=>{
                                        LoadCostList(item.value);                                        
                                    }}
                                    onInput={(e)=>{
                                        let input = e.target.value.toString();
                                        if(input.trim() == ""){
                                            setCostList([{"value":"","label":"--All/Any--"}]);
                                            setFilter({...filters, cost_id:""});
                                            setFilter({...filters, proj_id:""});
                                        }
                                    }}
                                    jsonSource={ApiBaseUrl+"/manager/suggest/project?token="+state.UserState.token}  label="Project" />
                                </div> 
                                <div className="col">
                                    <FC.Select name="status" label={"Status"} options={StatusListWithAll} />
                                </div>
                                                             
                                <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                                    <FC.DateInput name="from" label={"From"} />
                                </div>
                                <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                                    <FC.DateInput name="to" label={"To"} />
                                </div>
                                <div className="col">
                                    <FC.TextInput name="search" label={"Search"} />
                                </div> 

                                <div className="col">
                                    <FC.AutoFillRemote name="alotted_to" 
                                    jsonSource={ApiBaseUrl+"/manager/suggest/employee?token="+state.UserState.token+"&role=1"} label="Alotted To" />
                                </div>
                                
                                </FC.FormContextProvider>
                            </div>
                        </th>
                    </tr>
                    <tr className={view == "List" ? "" : "d-none"}>
                        <th>Project Title</th>
                        <th>Cost Type</th> 
                        <th>Rate</th> 
                        <th>Quantity</th> 
                        <th>Amount</th>                            
                        <th>Description</th>
                        <th>Alotted By</th>
                        <th>Alotted To</th>
                        <th>Creation Date</th>
                        <th>Completion Date</th>
                        <th>Last Updated On</th>
                        <th>Status</th>
                        
                    </tr>
                </thead>
                <tbody>
               
                {listData.records.map((r, index) => (
  // Check if cost_title is "Composing" before rendering the row
         r.cost.cost_title === "Composing" ? (
    <tr className={view === "List" ? "" : "d-none"}>
      <td>{r.project.proj_title}</td>
      <td>{r.cost.cost_title}</td>
      <td>{r.w_rate}</td>
      <td>{r.w_qty}</td>
      <td>{r.w_amount}</td>
      <td>{r.w_description}</td>
      <td>{r.alottedBy.emp_fullname}</td>
      <td>{r.alottedTo.emp_fullname}</td>
      <td>{moment(r.w_creation_date).format("DD MMM YYYY")}</td>
      <td>{moment(r.w_completion_date).format("DD MMM YYYY")}</td>
      <td>{moment(r.w_lastupdated_on).format("DD MMM YYYY")}</td>
      <td>
        <strong className={"text-" + getStatusColor(r.w_status)}>
          {r.w_status}
        </strong>
      </td>
    </tr>
  ) : null
))}
                
                </tbody>
                <tfoot className={view == "List" ? "" : "d-none"}>
                    <tr>
                        <td colSpan="30">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={30}
                                totalItemsCount={parseInt(listData.total)}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
       
    </>
}

function Chart({chartData, ...props}){
    let n = 0;
    let COLORS = [
        '#007bff','#6610f2','#e83e8c','#dc3545','#fd7e14','#ffc107','#28a745'
    ];
    let chartDataPoints = chartData.records.map((r)=> { 
        n++;        
        return {
        'w_status':r.w_status,
        'work_count':parseInt(r.work_count),
        'color':COLORS[n]
        }
    });

    

    return (
    <div {...props}>
    <div className="row">
        <div className="col-md-6">
            <PieChart width={800} height={600}> 
                <Pie data={chartDataPoints} dataKey="work_count" nameKey="w_status" cx="50%" cy="50%" outerRadius={300} fill="#8884d8">            
                    {chartDataPoints.map((entry, index) => <Cell fill={entry.color}/>)}
                </Pie>
            </PieChart>
        </div>
        <div className="col-md-6">
            <h5>Legend</h5>
            <table className="table table-bordered">
                <tbody>
                    {chartDataPoints.map(r=>{
                        return <tr key={r.w_status}>
                            <td>
                                <span className="p-2 mr-2 d-inline-block" style={{backgroundColor:r.color}}></span>
                                {r.w_status} - {r.work_count}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>
    </div>
    );
}