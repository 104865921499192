import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List  from './WorkEntry_List';
 
import { Event_onNew } from './Events';
import WorkEntry_Dialog from './WorkEntry_Dialog';
import WorkEntrySelf_Dialog from './WorkEntrySelf_Dialog';
import EmployeeLayout from '../../Layouts/EmployeeLayout';

export default function WorkEntry(props){


    return <><EmployeeLayout title="Work Entry">
        <WorkEntry_Dialog />
        <WorkEntrySelf_Dialog />
        <button  onClick={()=>{
            window.dispatchEvent(new CustomEvent(Event_onNew,{}));            
        }} className="btn btn-lg btn-primary mb-2">New</button>
        <WorkEntry_List />
        
        </EmployeeLayout></>
}