 



let initialState = {
    user : null,
    type : null,
    isLoggedIn: false,
    token:null
}

/*
console.log(Cookies.getItem("token"));
if(Cookies.getItem("token")!==null){
    var token = Cookies.getItem("token");
    var user = JSON.parse(Cookies.getItem("user"));
  
    initialState = {
      isLoggedIn: true,
      token: token,
      user: user,
    };
}
*/
export default function UserState(state = initialState, action)   {
    
    switch (action.type) {
        case "USER/SET":
            return {
                isLoggedIn : true,
                type:action.data.type,
                token:action.data.token,
                user:action.data.user
            }
        //    Cookies.setItem("token", action.data.token);
        //    Cookies.setItem("user", JSON.stringify(action.data.user));
             ;
        case "USER/GET":
            return state;
                  
        case "USER/LOGOUT":
 
            return {
                isLoggedIn : false,
                token:null,
                user:null,
                type:null
            } 
            
        //    Cookies.removeItem("token");
        //    Cookies.removeItem("user");            
            
        default:
            return state;
    }
    
}