import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
 
import { Event_onAfterSave, Event_onEditSelf, Event_onNew } from './Events';
 
import {   useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../Lib/Helpers';
import * as FormControl from '../../../Lib/FormControl';
import moment from 'moment';
import SaveDialog from '../../../Lib/SaveDialog';
import { ApiBaseUrl } from '../../../Config';
import {   StatusList  } from '../../../Constants/WorkEntry_StatusList';
import NormalDialog from '../../../Lib/NormalDialog';

import Select from 'react-select';

export default function WorkEntrySelf_Dialog(props){
    const state = useSelector(state => state);
 
    const userState = useSelector(state => state.UserState);

 

    const initialModalState = {
        w_id:0,
        w_description:"",
        w_rate:0, 
        w_qty:0,
        w_amount:0,
        w_estimated_date:moment().format("DD MMM YYYY"),
        w_completion_date:moment().format("DD MMM YYYY"),
        w_alotted_by:"",
        w_alotted_to:userState.user.user_id,
        w_status:"Pending",
        task_id:"",
        cost_id:"",
        proj_id:"", 
        cost : {

        },
        project:{
            proj_title: ""
        },
        taskNature  : {

        },
        alottedBy : {

        },
        alottedTo:{

        }
    }

    const initialErrorState = {
        w_id:"",
        w_description:"",
        w_rate:"",
        w_qty:"",
        w_amount:"",
        w_estimated_date:"",
        w_completion_date:"",
         w_alotted_by:"",
        w_alotted_to:"",
    //    w_status:"",
        task_id:"",
        cost_id:"",
        proj_id:"", 
        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [openReadOnly, setOpenReadOnly] = useState(false);

    const Save = () => {        
        apiPost("/associate/work-entry/save",{WorkEntry:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Event_onAfterSave, modal));
        },setError)        
    }

    const Get = (e) => {         
        let id = e.detail;
        apiGet("/associate/work-entry/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setModal(r.data); 
                setError(initialErrorState);  
                setOpen(true);
                if(r.data.w_status === "Sent to AWD"){
                    setOpenReadOnly(true);
                    setTitle("View");                    
                } else {
                    LoadCostList(r.data.proj_id,()=>{
                        setTitle("Edit");                        
                        setOpenReadOnly(false);
                        LoadEmployeeList(r.data.proj_id, () => {
                            setModal(r.data); 
                        });
                    }); 
                }
            } else {
                alert("Not Found");
            }
        });
    }
    
    const New = () => {
         
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
    }

    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");

    useEffect(()=>{
        window.addEventListener(Event_onNew,New);
        window.addEventListener(Event_onEditSelf,Get);
        return () =>{
            window.removeEventListener(Event_onNew,New);
            window.removeEventListener(Event_onEditSelf,Get);
        } 
    },[])

    const [taskNatureList,setTaskNatureList] = useState([]);
    const [managerList,setManagerList] = useState([]);
    const [associateList,setAssociateList] = useState([]);
 
    useEffect(()=>{ 
        LoadTaskNatureList();   
        //LoadEmployeeList();
    },[]);

    const LoadTaskNatureList = () => {
        apiGet("/associate/task/listall",{},(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.task_id, label: r.task_title };
            });
            data.push({value:"",label:"-- Select Task Nature --"});
            setTaskNatureList(data);
        });
    }

    const LoadEmployeeList = (proj_id, afterLoading=()=>{}) => {
        apiGet("/associate/employee/listall-managers",{   proj_id:proj_id },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.emp_id, label: r.emp_uid + " - " + r.emp_fullname };
            });
            data.push({value:"",label:"--Select Manager--"})
            setManagerList(data);
            afterLoading();
        });
       /*apiGet("/associate/employee/listall",{ role:1 },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.emp_id, label: r.emp_fullname+ " - "+r.alotted_work_count };
            });
            data.push({value:"",label:"-- Select Employee --"});
            setAssociateList(data);
        });*/
    }

    const [costList, setCostList] = useState([]);
    const LoadCostList = (id,childfunction=null) => {
        apiGet("/associate/project-cost/listall",{ proj_id: id },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.cost_id, label: r.cost_title };
            });
            data.push({value:"",label:"--Select Cost Type--"});   
            setCostList(data);
            if(childfunction!==null){
                childfunction();
            }
        });
    }
    if(openReadOnly){
        return <WorkEntry_Dialog_ReadOnly
            setOpen={setOpen} Save={Save} title={title} open={open} modal={modal} setModal={setModal} error={error}
        />
    }

    // let alottedBy = <FormControl.Select name="w_alotted_by" label="Alotted By" options={managerList} />
  
  
    // if(modal.w_id > 0){
      //  alottedTo = <><label>Alotted To</label><div>{modal.alottedTo.emp_fullname}</div></>
  //  }
  StatusList.splice(6,1); //Removing Send to AWD Status
  StatusList.splice(5,1); //Removing Approve
    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>

            <B.Row>
                <B.Col md={6}>
                 
                    <FormControl.AutoFillRemote 
                    onSelectOption={(r)=>{                       
                       LoadCostList(r.value,()=>{
                        setModal({...modal, cost_id : "", proj_id: r.value})
                       });
                       LoadEmployeeList(r.value);
                    }}
                    jsonSource={ApiBaseUrl+"/associate/suggest/self-project?token="+state.UserState.token}
                    initialInputText={modal.project.proj_title}
                    name="proj_id" label="Project" />
                </B.Col>
                <B.Col md={6}>
                    <FormControl.Select name="cost_id" label="Cost" options={costList} />
                </B.Col> 
            </B.Row>

            <FormControl.TextInput name="w_description" label="Work Description"/>
            <B.Row>
                <B.Col md={4}>
                    <FormControl.TextInput name="w_rate" label="Rate"/>
                </B.Col>
                <B.Col md={4}>
                    <FormControl.TextInput name="w_qty" label="Quantity"/>
                </B.Col>
                <B.Col md={4}>
                    AMOUNT: {(modal.w_rate * modal.w_qty)}
                </B.Col>
            </B.Row>

            <FormControl.Select name="task_id" label="Task Nature" options={taskNatureList} />

            <B.Row>
                <B.Col md={6}>
                    <FormControl.DateInput name="w_estimated_date" label="Estimated Date"/>
                </B.Col>
                <B.Col md={6}>
                    <FormControl.DateInput  name="w_completion_date" label="Completion Date"/>
                </B.Col>
               
            </B.Row>

            <B.Row>
                
                <B.Col md={6}>
                <label htmlFor="w_alotted_by">Alotted By</label>
               <Select
                 id="w_alotted_by"
                 name="w_alotted_by"
                 value={managerList.find((option) => option.value === modal.w_alotted_by)}
                 onChange={(selectedOption) => setModal({ ...modal, w_alotted_by: selectedOption.value })}
                 options={managerList}
                 isSearchable={true} // This enables the search box
                 placeholder="-- Select Employee --"
                    />
                </B.Col>
                <B.Col md={6}>
                    <FormControl.Select name="w_status" label="Status" options={StatusList} />
                </B.Col> 
            </B.Row>
            <B.Row>
                <B.Col md={12}>
                <FormControl.TextInput type="hidden" name="no_save"/>
               
                </B.Col> 
            </B.Row> 
        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}


const WorkEntry_Dialog_ReadOnly = ({
    modal, setModal, error, open, setOpen, Save, title, ...props
}) => {
 
    const getLabel = (label, value)=>{
        return <>
            <div className="form-group">
                <label>{label}</label><br />
                {value}
            </div>
        </>;
    }

    return <NormalDialog onClose={()=>setOpen(false)} open={open} title={title}>
    <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
        <table className="table table-bordered">
            <tr>
                <td>{getLabel("Project",modal.project.proj_title)}</td>
                <td>{getLabel("Cost",modal.cost.cost_title)}</td>
                <td>{getLabel("Task Nature",modal.taskNature.task_title)}</td>
            </tr>
            <tr>
                <td colSpan={3}>{getLabel("Work Description",modal.w_description)}</td>
            </tr>
            <tr>
                <td>{getLabel("Rate",modal.w_rate)}</td>
                <td>{getLabel("Quantity",modal.w_qty)}</td>
                <td>{getLabel("Amount",modal.w_amount)}</td>
            </tr>
            <tr>
                <td>{getLabel("Estimated Date",moment(modal.w_estimated_date).format("DD MMM YYYY") )}</td>
                <td>{getLabel("Completion Date",moment(modal.w_completion_date).format("DD MMM YYYY") )}</td>
                

                <td></td>
            </tr>
            <tr>
                <td>{getLabel("Alotted By",modal.alottedBy.emp_fullname)}</td>
                <td>{getLabel("Alotted To",modal.alottedTo.emp_fullname)}</td>
                <td>{getLabel("Status",modal.w_status)}</td>
            </tr>
        </table>
         
    </FormControl.FormContextProvider>
</NormalDialog>
}
