import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import Edit from './Edit';
import List from './List';
 
import {  Event_onNew   } from './Event';
import AdminLayout from '../../../Layouts/AdminLayout';
 
 
 

export default function Keyword(props){

    return <> 
    <AdminLayout title={props.type+" Manager"}>
        <Edit type={props.type} />
        
        <button onClick={()=>{
            window.dispatchEvent(new CustomEvent(Event_onNew,{}));            
        }} className="btn btn-lg mr-2 btn-primary mb-2">New</button>

        <List type={props.type} />        
        </AdminLayout>
        </>
}