 

export const TypeList = [
    {label:"Casual",value:"Casual"},
    {label:"Paid",value:"Paid"},
    {label:"Special",value:"Special"},
];

export const StatusList = [
    {label:"Approved",value:"Approved"},
    {label:"Rejected",value:"Rejected"},
    {label:"Pending",value:"Pending"},
];

export const getStatusColor = (status) => {
    switch (status) {
        case "Approved":
            return "success";
           
        case "Pending":
            return "warning";
           
        case "Rejected":
            return "danger";
             
    
        default:
            return "dark";
             
    }
}