import React, { useEffect, useState } from 'react';  
import SaveDialog from '../../../Lib/SaveDialog';
import moment from 'moment'; 
import DatePicker from 'react-datepicker';
 
const ProjectIncentive_Dialog = (props) => {
    let modal = props.modal;
    let error = props.error;
    let setModal = props.setModal;
   
   

    return (    
    <SaveDialog onClose={(e)=>props.onClose(e)} onSubmit={(e) => props.onSubmit(e)} open={props.open} title={props.title}>
            <div className="form-group">
                <label>Task</label>                
                <select className="form-control" value={modal.ts_id} onChange={(e)=>setModal({...modal, ts_id:e.target.value})}>
                    {props.tsList.map((r) => (
                    <option key={r.ts_id} value={r.ts_id}>{r.task.task_title} By {r.alottedTo ? r.alottedTo.emp_fullname : ""} - Amount {r.ts_amount} - Status: {r.ts_status}</option>
                    ))}
                </select>
                <div className="text-danger">{error.ts_id}</div>
            </div>

            <div className="form-group">
                <label>Incentive Amount</label>
                <input type="number" className="form-control" value={modal.inc_amount} onChange={(e)=>setModal({...modal, inc_amount:e.target.value})} />
                <div className="text-danger">{error.inc_amount}</div>
            </div>

            <div className="form-group">
                <label>Incentive Date</label>
                <DatePicker dateFormat="dd MMM yyyy" selected={new Date(modal.inc_date)} className="form-control" 
                onChange={(date) => setModal({...modal, inc_date:moment(date).format("DD MMM YYYY")})} />
                <div className="text-danger">{error.inc_date}</div>
            </div>
 
             

            <input type="hidden" id="inc_id" value={modal.inc_id} />

    </SaveDialog>
    );
}

export default ProjectIncentive_Dialog;