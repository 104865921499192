import React, { useEffect, useState } from 'react';
import Edit from './Edit';
import List from './List';
import { Events } from '../Constants';
import NormalDialog from '../../../../../Lib/NormalDialog';

export default function Main(props){
    
    const [title,setTitle] = useState("");
    const [open,setOpen] = useState(false);
    const [empId,setEmpId] = useState(0);

    const OpenDialog = (r) => {
        setEmpId(r.detail.emp_id);
        setTitle(r.detail.emp_fullname+ " - Leave Account");
        setOpen(true);
    };

    useEffect(()=>{ 
        window.addEventListener(Events.onTransactionButtonClick,OpenDialog);        
        return () =>{
            window.removeEventListener(Events.onTransactionButtonClick,OpenDialog);        
        } 
    },[])

    if(open===false){
        return <></>
    }

    return <>
        <NormalDialog title={title} onClose={(e)=>setOpen(false)} open={open}>
        <button onClick={()=>{
            window.dispatchEvent(new CustomEvent(Events.onNew,{}));            
        }} className="btn btn-lg mr-2 btn-primary mb-2">New Transaction</button>
        <Edit emp_id={empId} />
        <List emp_id={empId} />    
        </NormalDialog>
    </>
}