import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import View from './View';
import List from './List';
 
import {  Event_onNew   } from './Event';
import EmployeeLayout from '../../Layouts/EmployeeLayout';
 
 
 

export default function Page(props){

    return <> 
    <EmployeeLayout title="Pages">
        <View />
          
        <List />        
        </EmployeeLayout>
        </>
}