import React, { useEffect, useState } from 'react';
 
import {   Event_onAfterSave, Event_onEdit, Event_onNew } from './Event'; 
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../../Lib/Helpers';
import * as FormControl from '../../../../Lib/FormControl';
 
import SaveDialog from '../../../../Lib/SaveDialog';
 
export default function Edit(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState({});
    const initialModalState = {
        kw_id:0,
        kw_title:"",
        kw_type:props.type,                
    }

    const initialErrorState = {
        kw_id:"",
        kw_title:"",
        kw_type:"",        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const Save = (e) =>{
        e.preventDefault(); 
        apiPost("/admin/qb-keyword/save",{QbKeyword:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Event_onAfterSave, modal));
        },setError)  
    };

    const Get = (e) => {         
        let id = e.detail;
        setSelectedFile({});
        apiGet("/admin/qb-keyword/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                setModal(r.data);                  
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
 
    }

     
    
    useEffect(()=>{
 
        window.addEventListener(Event_onNew,New);
        window.addEventListener(Event_onEdit,Get);
        return () =>{
            window.removeEventListener(Event_onNew,New);
            window.removeEventListener(Event_onEdit,Get);
        } 
    },[])



    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
            <FormControl.TextInput name="kw_title" label={props.type+" Title"}/>
            

            <input type="hidden" name="kw_id" value={modal.kw_id} />

        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}