import React, { useEffect, useState } from 'react';
 
import { Events } from './Constants'; 
 
import { apiGet, apiPost } from '../../../../Lib/Helpers';
import * as FormControl from '../../../../Lib/FormControl'; 
import SaveDialog from '../../../../Lib/SaveDialog';
import moment from 'moment';
 
export default function Edit(props){

 
 
    const initialModalState = {
        dt_id:0,
        dt_title:"",
        dt_date:moment().format("DD MMM YYYY"),        
    }

    const initialErrorState = {
        dt_id:"",
        dt_title:"",
        dt_date:"",        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const Save = (e) =>{
        e.preventDefault(); 
        apiPost("/admin/leave/holiday-save",{LeaveHolidayList:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Events.onAfterSave, {detail:modal}));
        },setError)  
    };

    const Get = (e) => {         
        let id = e.detail;
  
        apiGet("/admin/leave/holiday-get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                setModal(r.data);                  
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
 
    }

     
    
    useEffect(()=>{
 
        window.addEventListener(Events.onNew,New);
        window.addEventListener(Events.onEdit,Get);
        return () =>{
            window.removeEventListener(Events.onNew,New);
            window.removeEventListener(Events.onEdit,Get);
        } 
    },[])



    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
            <FormControl.TextInput name="dt_title" label={"Title"}/>
            <FormControl.DateInput name="dt_date" label={"Date"}/>
            <input type="hidden" name="kw_id" value={modal.dt_id} />
        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}