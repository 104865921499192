import React from 'react';
import { useState, useEffect } from 'react';
import {   apiGet, apiPost } from '../../../Lib/Helpers';
 import * as B from 'react-bootstrap'
import moment from 'moment';
 import Pagination from 'react-js-pagination';
import ProjectIncentive_Dialog from './ProjectIncentive_Dialog';
import Confirmation from '../../../Lib/Confirmation';
const ProjectIncentive = (props) => {
    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [filters, setFilter] = useState({
        pr_id:props.pr_id,
        page:0,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        inc_id:0,
        inc_date:moment().format("DD MMM YYYY"),
        inc_amount:0,
        ts_id:0,
        pr_id:props.pr_id,
    }

    const initialErrorState = {
        inc_id:"", inc_date:"", inc_amount:"", ts_id:"", pr_id:"",
    }

    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialModalState})

    const [tsList, setTsList] = useState([]);

    useEffect(() => {
        apiGet("/admin/project-task/listall",{proj_id:props.proj_id},(r)=>{
            setTsList(r.data.records);
            
        })
    },[])

    useEffect(() => {
        Loadlist();        
    },[filters]);

    
    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
        setError({...initialErrorState});
        if(tsList.length > 0){
          
            setModal({...initialModalState, ts_id: tsList[0].ts_id});                         
        }
    };

    const Delete = () =>{
        apiPost("/admin/project-incentive/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/project-incentive/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project-incentive/get",{
            id:id
        },(r)=>{
            setModal( {...r.data, inc_date:moment(r.data.inc_date) });
            setError({...initialErrorState});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
        let modal2 = {...modal};
        modal2.inc_date = moment(modal.inc_date).format("DD MMM YYYY");
        apiPost("/admin/project-incentive/save",{
            ProjectIncentive: modal2,
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };

    return (
        <React.Fragment>

<ProjectIncentive_Dialog tsList={tsList}
setError={setError} setModal={setModal} error={error} modal={modal}
onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title} />
<Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
<div className="card mb-2">
    <div className="card-body">
        <button className="btn btn-primary" onClick={()=>New()} >New</button> 
    </div>
</div> 
<div className="card">
    <table className="table">
        <thead>
            <tr>
                <th>Actions</th>
                <th>To Employee</th>
                <th>Incentive Date</th>
                <th>Amount</th>
                <th>Task Nature</th>
                <th>Task Completion Date</th>
                <th>Cost</th>
                <th>Project</th>
            </tr>
        </thead>
        <tbody>
            {listData.records.map(r => (
                <tr>
                    <td>
                        <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                            <B.Dropdown.Item  
                                onClick={(e)=>Get(r.inc_id)} href="#">Edit</B.Dropdown.Item> 
                            <B.Dropdown.Item 
                            onClick={(e)=>{ 
                                e.preventDefault();
                                setConfirmDialogState({open:true, id:r.inc_id})                                    
                                }
                            } className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                    
                        </B.DropdownButton>
                    </td>
                    <td>{r.projectTask.alottedTo ? r.projectTask.alottedTo.emp_fullname : ""}</td>
                    <td>{moment(r.inc_date).format("DD MMM YYYY")}</td>
                    <td>{r.inc_amount}</td>
                    <td>{r.projectTask.task.task_title}</td>
                    <td>{moment(r.projectTask.ts_completion_date).format("DD MMM YYYY")}</td>
                    <td>{r.projectTask.cost.cost_title}</td>
                    <td>{r.projectTask.cost.project.proj_title}</td>
                </tr>
            ))}   
        </tbody>
        <tfoot>
            <tr>
                <td colSpan="8">
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={filters.page}
                        itemsCountPerPage={10}
                        totalItemsCount={listData.total}
                        pageRangeDisplayed={5}
                        onChange={page => {
                            setFilter({...filters, page})
                        }}
                    />
                </td>
            </tr>
        </tfoot>
    </table>
</div>
 
        </React.Fragment>
    )
}

export default ProjectIncentive;
