import React  from 'react';
 
import Edit from './Edit';
import List from './List';
 
 
import AdminLayout from '../../../Layouts/AdminLayout';
import { Events } from './Constants';
 
 
 

export default function Main(props){
     
    return <> 
    <AdminLayout title={"Holiday List Manager"}>
        
    <Edit type={props.type} />
        
        <button onClick={()=>{
            window.dispatchEvent(new CustomEvent(Events.onNew,{}));            
        }} className="btn btn-lg mr-2 btn-primary mb-2">New</button>

        <List />  
        </AdminLayout>
        </>
}