import React, { useEffect, useState } from 'react';
 
import { Events } from './Constants'; 
 import {  apiGet, apiPost } from '../../../../Lib/Helpers';
import * as FormControl from '../../../../Lib/FormControl'; 
import SaveDialog from '../../../../Lib/SaveDialog';
 
import * as Lv from '../../../../Constants/Leave';
 
import   DatePanel   from 'react-multi-date-picker/plugins/date_panel';
 

export default function Edit(props){

   
    const initialModalState = {
        rq_id:0,
        rq_dates:[],
 
        rq_subject:"",
        rq_message:"",
        rq_status:"",
        emp_id:0,
        rq_created_on:"",
        rq_updated_on:"",
    }

    const initialErrorState = {
        rq_id:"",
        rq_dates:"",
   
        rq_subject:"",
        rq_message:"",
        rq_status:"",
        emp_id:"",
        rq_created_on:"",
        rq_updated_on:"",        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const [accountBalance, setAccountBalance] = useState({"Casual":0,"Special":0,"Paid":0}); 
    
    
    

    const Save = (e) =>{
        e.preventDefault(); 
        apiPost("/admin/leave/request-save",{LeaveRequest:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Events.onAfterSave, {detail:modal}));
        },setError)  
    };

    const Get = (e) => {         
        let id = e.detail;
  
        
        apiGet("/admin/leave/request-get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                //console.log(JSON.parse(r.data.rq_dates));
                apiGet("/admin/leave/account-get-balance",{emp_id:r.data.emp_id},(r)=> setAccountBalance(r.data));
                setModal({...r.data, rq_dates:JSON.parse(r.data.rq_dates)})                    
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    
    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
 
    }
 
    useEffect(()=>{ 
        window.addEventListener(Events.onNew,New);
        window.addEventListener(Events.onEdit,Get);
        LoadEmpList();
        LoadHolidayList();
        return () =>{
            window.removeEventListener(Events.onNew,New);
            window.removeEventListener(Events.onEdit,Get);
        } 
    },[])

    const [empList, setEmpList] = useState([]);
    const LoadEmpList = () => {
        apiGet("/admin/employee/listall",{},(r)=>{            
            let list = r.data.records.map((r)=>{
                return {value:r.emp_id, label:r.emp_fullname};
            });
            list.unshift({value:"",label:"--Select Employee--"});
            setEmpList(list);
        });
    }

    const [holidayList, setHolidayList] = useState([]); 
    const LoadHolidayList = (year) => {      
        apiGet("/admin/leave/holiday-listall",{},(r)=>{            
            let holidays = r.data.records.map((r2)=>{
                return r2.dt_date;
            }); 
            setHolidayList(holidays);            
        })        
    }

    const DoAction = (modal, action) => {
        apiPost("/admin/leave/request-action",{modal:modal,action:action},(r)=>{            
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Events.onAfterSave, {detail:modal}));          
        })
    }
 
    let StatusList = [...Lv.StatusList];
    StatusList.unshift({value:"",label:"--Select Status--"});
    let TypeList = [...Lv.TypeList];
    TypeList.unshift({value:"",label:"--Select Leave Type--"});

    const ActionButtons = () => {
    
        if(modal.rq_status === "Pending"){
            return <>
            <button type="button" onClick={()=>DoAction(modal,"Approve")} className="btn btn-success">Approve</button>
            <button type="button" onClick={()=>DoAction(modal,"Reject")} className="btn btn-danger">Reject</button>
            </>
        }
        if(modal.rq_status === "Approved"){
            return <> 
            {/*<button type="button" onClick={()=>DoAction(modal,"Reject")} className="btn btn-danger">Reject</button>*/}
            </>
        }
        if(modal.rq_status === "Rejected"){
            return <> 
            <button type="button" onClick={()=>DoAction(modal,"Approve")} className="btn btn-success">Approve</button>
            </>
        }
    }
 
    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>

            
            <FormControl.TextInput name="rq_subject" label={"Subject"}/>
            <FormControl.Textarea name="rq_message" label={"Message"}/>

            
            <div className="row">
                <div className="col-md-6">
  
                    <FormControl.MultiDateInput plugins={[<DatePanel />]} mapDays={(dt)=>{
                        
                            let month = String(dt.date.month.number).padStart(2, '0');
                            let day = String(dt.date.day).padStart(2, '0');
                            let currentDate = dt.date.year+"-"+month+"-"+day;
                            
                            if(dt.date.weekDay.name === "Sunday"){
                                return {disabled:true}
                            }
                            if(holidayList.includes(currentDate.toString())){
                                    return {disabled:true}
                            } 
                        
                    }} type="calendar" name="rq_dates" label={"Date"}/>
                     
                </div>
                      
                <div className="col-md-3">
                     
                    {/*<FormControl.Select options={StatusList} name="rq_status" label={"Status"} />        */}
                    <FormControl.Select options={empList} name="emp_id" label={"Employee"} />        
                </div> 
                <div className="col-md-3">
                    <h5>Leave Account Balance</h5>
                    <hr />
                    <p><label>Casual:</label> {accountBalance.Casual}<br />
                    <label>Paid:</label> {accountBalance.Paid}<br />
                    <label>Special:</label> {accountBalance.Special}</p>

                    {ActionButtons()}

                </div>          
            </div>
            <input type="hidden" name="emp_id" value={modal.emp_id} />
        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}