import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout';
import { useState, useEffect } from 'react';
import {  apiGet, apiPost } from '../../../Lib/Helpers';
import { connect } from 'react-redux';
import Confirmation from '../../../Lib/Confirmation';

import SaveDialog from '../../../Lib/SaveDialog';
import NormalDialog from '../../../Lib/NormalDialog';
import EmployeeTypeHistory from './EmployeeTypeHistory';
import EmployeeTargetHistory from './EmployeeTargetHistory';
import * as B from 'react-bootstrap';
import Payment from '../Payment/Payment';
 
import * as FormControl from '../../../Lib/FormControl';
 
import Pagination from "react-js-pagination";
import { Multiselect } from 'multiselect-react-dropdown';

const Employee = (props) => {

    const [dialogState, setDialogState] = useState({open:false, title:""});    
    const [tsDialog, setTsDialog] = useState({open:false,title:" ",id:0});
    const [tgDialog, setTgDialog] = useState({open:false,title:" ",id:0});
    const [pmtDialog, setPmtDialog] = useState({open:false,title:"",id:0});

    const [confirmDialogState, setConfirmDialogState] = useState({open:false});

    const [filters, setFilter] = useState({
        search : "",
        role_id: "",
        page:1,
        perpage:10,
    })

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        emp_fullname:"",
        emp_email:"",
        emp_password:"",
        tg_amount: "",
        emp_id: "",
        emp_uid:"",
        role_ids: [],
        tp_type: "Associate"
    }

    const initialErrorState = {...initialModalState, tp_type:"", role_ids:""};

    const [modal, setModal] = useState({...initialModalState})
    
    const [error, setError] = useState({...initialErrorState})

    const [roleList, setRoleList] = useState([]);
   // const [selectedRoleList, setSelectedRoleList] = useState([]);
   // const [multiSelect, setMultiSelect] = useState(<></>);

    

    const New = () =>{
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState});
        
    };

    const Delete = () =>{
        apiPost("/admin/employee/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };
 
    const Loadlist = () =>{
        apiGet("/admin/employee/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/employee/get",{
            id:id
        },(r)=>{
            setModal({...r.data, emp_password :""});
            setError({...initialErrorState});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
 
        apiPost("/admin/employee/save",{
            Employee: modal,
            role_ids: modal.role_ids.map(r => r.role_id),
            tp_type: modal.tp_type,
            tg_amount: modal.tg_amount
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };

    useEffect(() => {
        Loadlist();
    },[filters, tsDialog, tgDialog, pmtDialog, dialogState]);
    
/*
    useEffect(()=>{
        apiGet("/admin/employee/roles-listall",{},(rs)=>{            
            let roleList = rs.data.records.map((r)=>{                 
                return {
                    id:r.role_id,
                    name:r.role_title
                }                       
            })
            setRoleList(roleList);

            let selectedRoles = [];
            rs.data.records.forEach((r)=>{
                let model_role_ids = modal.role_ids.map(r => parseInt(r.role_id));
               
                if(model_role_ids.includes(parseInt(r.role_id))){
                    selectedRoles.push({
                        id:r.role_id,
                        name:r.role_title
                    })
                }
            })
           
            setSelectedRoleList(selectedRoles);
 
            let multi = <Multiselect
            placeholder="Select Roles"
options={roleList} // Options to display in the dropdown
selectedValues={selectedRoles} // Preselected value to persist in dropdown
onSelect={(selection)=>{
    let sel = selection.map((r)=>{
        return {
            role_id:r.id,
            role_title:r.name
        }
    })
 
    setModal({...modal, role_ids: sel});
 
}} // Function will trigger on select event
onRemove={(selection)=>{
    let sel = selection.map((r)=>{
        return {
            role_id:r.id,
            role_title:r.name
        }
    })
    setModal({...modal, role_ids: sel});
}} // Function will trigger on remove event
displayValue="name"
/>
setMultiSelect(multi);
        })
    },[dialogState])
*/

    let selectedRoles = [];
    let model_role_ids = modal.role_ids.map(r => parseInt(r.role_id));
               
    if(model_role_ids.includes(1)){
        selectedRoles.push({
            id:1,
            name:"Associate"
        });
    }

    if(model_role_ids.includes(2)){
        selectedRoles.push({
            id:2,
            name:"Project Manager"
        });
    }


    return (
        <React.Fragment>
            <AdminLayout title="Manage Associates">

            <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />

            <NormalDialog height={500} title={tgDialog.title} onClose={(e)=>setTgDialog({...tsDialog, open:false})} open={tgDialog.open}>
                {tgDialog.open ? <EmployeeTargetHistory emp_id={tgDialog.id} /> : "" }
            </NormalDialog>

            <NormalDialog title={tsDialog.title} onClose={(e)=>setTsDialog({...tsDialog, open:false})} open={tsDialog.open}>
                {tsDialog.open ? <EmployeeTypeHistory emp_id={tsDialog.id} /> : "" }
            </NormalDialog>
             
            <NormalDialog title={pmtDialog.title} onClose={(e)=>setPmtDialog({...pmtDialog, open:false})} open={pmtDialog.open}>
                {pmtDialog.open ? <Payment emp_id={pmtDialog.id} /> : "" }
            </NormalDialog>
            
            <SaveDialog   onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
                    <FormControl.TextInput name="emp_uid" label={"Employee ID"}/>
                    <FormControl.TextInput name="emp_fullname" label={"Employee Full Name"}/>
                    <B.Row>
                        <B.Col md={6}>
                            <FormControl.TextInput name="emp_email" label={"Email"}/>
                        </B.Col>
                        <B.Col md={6}>
                            <FormControl.TextInput type="password" name="emp_password" label={"Password"}/>
                        </B.Col>    
                    </B.Row>
                    <FormControl.TextInput name="tg_amount" label={"Monthly Target"}/>
                    <FormControl.Select name="tp_type" label={"Associate Type"} options={[
                        {value:"Insider",label:"Insider"},
                        {value:"Outsider",label:"Outsider"},                        
                    ]}/>
                    <div className="form-group">
                        <label>Roles</label>
                        
                        <Multiselect
                        placeholder="Select Roles"
                        options={[
                            {name:"Associate",id:1},
                            {name:"Project Manager",id:2},
                        ]} // Options to display in the dropdown
                        selectedValues={selectedRoles} // Preselected value to persist in dropdown
                        onSelect={(selection)=>{
                            let sel = selection.map((r)=>{
                                return {
                                    role_id:r.id,
                                    role_title:r.name
                                }
                            }) 
                            setModal({...modal, role_ids: sel}); 
                        }} // Function will trigger on select event
                        onRemove={(selection)=>{
                            let sel = selection.map((r)=>{
                                return {
                                    role_id:r.id,
                                    role_title:r.name
                                }
                            })
                            setModal({...modal, role_ids: sel});
                        }} // Function will trigger on remove event
                        displayValue="name"
                        />
 
                        <div className="text-danger">{error.role_ids}</div>                        
                    </div>
                    <input type="hidden" id="emp_id" value={modal.emp_id} />
                </FormControl.FormContextProvider>
                      
                    {/*
                    <div className="form-group">
                        <label>Select Roles</label>
                         <select className="form-control" multiple onChange={(e)=>setModal({...modal, role_ids:e.target.value})} value={modal.role_ids}>
                            {roleList.map((r)=>
                                <option value={r.role_id}>{r.role_title}</option>
                            )}
                            </select>
                        <div className="text-danger"></div>                        
                    </div>
                            */} 
            </SaveDialog>

            <button className="btn btn-primary btn-lg mb-2" onClick={()=>New()} >New</button>

             
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="7">
                                <div className="form-row float-right">
                                    <div className="col">
                                        <input placeholder="Search..." type="search" onChange={ e => setFilter({ ...filters, search:e.target.value,}) }  value={filters.search} className="form-control" />                                     
                                    </div>
                                    <div className="col-auto">
                                        <select value={filters.role_id} onChange={(e)=>setFilter({...filters, role_id:e.target.value})} className="form-control">
                                            <option key={0} value=" ">--All/Any Role--</option>
                                            {roleList.map((r)=> {
                                                return <option value={r.id} key={r.id}>{r.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style={{width:120}}>Actions</th>
                            <th>#ID</th>
                            <th>Employee Name</th>                        
                            <th>Email</th>
                            <th>Roles</th>                        
                            <th>Present Type</th>
                            <th>Present Target</th>        
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.emp_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e)=>Get(r.emp_id)} href="#">Edit</B.Dropdown.Item>
                                    <B.Dropdown.Item  
                                        onClick={() => setPmtDialog({open:true, title:"Payment Records for "+r.emp_fullname, id:r.emp_id}) } href="#">Payments</B.Dropdown.Item>   
                                    <B.Dropdown.Item  
                                        onClick={(e)=>setTsDialog( { ...tsDialog, open:true, id:r.emp_id, "title": r.emp_fullname+" - Type History" } )} href="#">Type History</B.Dropdown.Item>   
                                    <B.Dropdown.Item  
                                        onClick={(e)=>setTgDialog( { ...tgDialog, open:true, id:r.emp_id, "title": r.emp_fullname+" - Target Amount History" } )} href="#">Target Amount History</B.Dropdown.Item>   
  
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.emp_id})                                    
                                        }
                                    } 
                                    className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                 
                                </B.DropdownButton> 
                            </td>
                            <td>{r.emp_uid}</td>
                            <td>{r.emp_fullname}</td>
                            <td>{r.emp_email}</td>
                            
                            <td>
                                {r.employeeRoles.map((r) => (
                                    <h5><span key={r.role_id} class="badge badge-secondary">{r.role_title}</span></h5>
                                ))}
                            </td>
                            <td>
                                <button onClick={(e)=>setTsDialog( { ...tsDialog, open:true, id:r.emp_id, "title": r.emp_fullname+" - Type History" } )} className="btn btn-primary btn-sm">
                                {r.currentEmployeeType === null ? "NONE" : r.currentEmployeeType.tp_type}
                                </button> 
                            </td>
                            <td>         
                                <button onClick={(e)=>setTgDialog( { ...tgDialog, open:true, id:r.emp_id, "title": r.emp_fullname+" - Target Amount History" } )} className="btn btn-primary btn-sm">
                                {r.currentEmployeeTarget === null ? "NONE" : r.currentEmployeeTarget.tg_amount}
                                </button>      
                            </td>
                        </tr>
                    ))}        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="7">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>                    
            </div>


            
            </AdminLayout>
        </React.Fragment>
    );

};



const mapStateToProps = state =>{ return state };
const mapDispatchToProps = dispatch => { return { do: (action) => { dispatch(action) } } };
export default connect(mapStateToProps, mapDispatchToProps)(Employee);
