import React from 'react';
 

import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FormControl from '../../../Lib/FormControl';

import moment from 'moment';

import NormalDialog from '../../../Lib/NormalDialog';
import Confirmation from '../../../Lib/Confirmation';
import SaveDialog from '../../../Lib/SaveDialog';
import {Modal,Button } from 'react-bootstrap';

import axios from 'axios';
import { ApiBaseUrl } from '../../../Config';
import DeleteLink from '../../../Lib/DeleteLink';
import DatePicker from 'react-datepicker';
import Pagination from "react-js-pagination";
import { filter, forEach } from 'underscore';
import ProjectFile from './ProjectFile';
import ProjectCost from './ProjectCost';

import PrintOrder from '../PrintOrder/PrintOrder';
import { MdDescription } from 'react-icons/md';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

 

export default function ProjectQB(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();

 

    const initialModalState = {
        q_id:0,
        q_agency_name: "",
        q_exam_name: "",
        q_admit_card_date:moment().format("DD MMM YYYY"),
        q_exam_date:moment().format("DD MMM YYYY"),
        q_exam_mode: "Online",
        q_paper_available: "No",
        q_exam_shifts: 0,
        q_anskey_start_date:moment().format("DD MMM YYYY"),
        q_anskey_end_date:moment().format("DD MMM YYYY"),
        q_anskey_type: "",
        q_anskey_pre_paper: 0,
        q_anskey_final_paper: 0,
        q_anskey_last_date:moment().format("DD MMM YYYY"),
        q_paper_downloaded_pre: 0,
        q_paper_received_final: 0,
        proj_id: props.id
    }

    const initialErrorState = {
        q_id:"",
        q_agency_name: "",
        q_exam_name: "",
        q_admit_card_date:"",
        q_exam_date:"",
        q_exam_mode: "",
        q_paper_available:"",
        q_exam_shifts:"",
        q_anskey_start_date:"",
        q_anskey_end_date:"",
        q_anskey_type: "",
        q_anskey_pre_paper:"",
        q_anskey_final_paper:"",
        q_anskey_last_date:"",
        q_paper_downloaded_pre:"",
        q_paper_received_final:"",
        proj_id: ""
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
      
 

    const Save = () => {
        
        apiPost("/admin/project/qbank-save",{ProjectQBank:modal},(r)=>{
    
            props.onClose();
        },setError)        
    }

    const Get = (id) => {         
        apiGet("/admin/project/qbank-get",{id:id},(r)=>{
            if(r.data !== null){
                setModal(r.data);                
            } else {
                setModal(initialModalState);
            }  
        });
    }

    
    useEffect(()=>{ 
        if(props.id === "" || props.id === 0){
            setModal(initialModalState);
            return;
        }
        Get(props.id); 
    },[props.random])
 
 
    
    return <>
    <SaveDialog onClose={props.onClose} onSubmit={Save} open={props.open} title={props.title}>
        
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
            <FormControl.TextInput name="q_agency_name" label="Agency Name"/>
            <FormControl.TextInput name="q_exam_name" label="Exam Name"/>
            <B.Row>
                <B.Col md={6}>
                    <FormControl.DateInput name="q_admit_card_date" label="Admit Card Date"/>
                </B.Col>
                <B.Col md={6}>
                    <FormControl.DateInput name="q_exam_date" label="Exam Date"/>
                </B.Col>
            </B.Row>    
            <B.Row>
                <B.Col md={6}>
                    <FormControl.Select options={ [
                        {value:"Offline",label:"Offline"},
                        {value:"Online",label:"Online"},
                    ] } name="q_exam_mode" label="Exam Mode"/>
                </B.Col>
                <B.Col md={6}>
                    <FormControl.Select options={ [
                    {value:"Yes",label:"Yes"},
                    {value:"No",label:"No"},
                ] } name="q_paper_available" label="Paper Available (If Online)"/>
                </B.Col>
            </B.Row>         
            <FormControl.TextInput name="q_exam_shifts" type="number" label="Total Exam Shifts Conducted"/>
            
            <p><strong>Preliminary Answer Key Availablity Date</strong></p>

            <B.Row>
                <B.Col md={6}>
                    <FormControl.DateInput name="q_anskey_start_date" label="From"/>                
                </B.Col>
                <B.Col md={6}>
                    <FormControl.DateInput name="q_anskey_end_date" label="To"/>                
                </B.Col>
            </B.Row>    

            
            <FormControl.DateInput name="q_anskey_last_date" label="Final Answer Key Availability Last Date"/>            

            <p><strong>Answer Key</strong></p>

            <B.Row>
                <B.Col md={6}>
                    <FormControl.TextInput name="q_anskey_pre_paper" label="Preliminary"/>                
                </B.Col>
                <B.Col md={6}>
                    <FormControl.TextInput name="q_anskey_final_paper" label="Final"/>                
                </B.Col>
            </B.Row>

            <p><strong>Total Papers Downloaded</strong></p>

            <B.Row>
                <B.Col md={6}>
                    <FormControl.TextInput name="q_paper_downloaded_pre" label="Preliminary"/>                
                </B.Col>
                <B.Col md={6}>
                    <FormControl.TextInput name="q_paper_received_final" label="Final"/>                
                </B.Col>
            </B.Row>

        </FormControl.FormContextProvider>


         

 

          
         
        
        

        <input type="hidden" name="q_id" value={modal.q_id} />
        <input type="hidden" name="proj_id" value={modal.proj_id} />

 

    </SaveDialog>    
    </>
}