import Assignedwork_List  from './Assignedwork_List';
import EmployeeLayout from '../../Layouts/EmployeeLayout';

export default function WorkEntry(props){
    return <>
                <EmployeeLayout title="Operator Work Entries">
                
                    <Assignedwork_List />
                </EmployeeLayout>
            </>
}