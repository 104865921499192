import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout';

import WorkHistory from './WorkHistory';
import { useState, useEffect } from 'react';
// import React, { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../Config';
 
import Pagination from "react-js-pagination";
 
import { Multiselect } from 'multiselect-react-dropdown';
import { Event_onAfterLoadlist, Event_onAfterSave, Event_onEdit } from './Events';
import { getStatusColor, StatusList, StatusListWithAll } from './Satus';
import Files from './Files';
import NormalDialog from '../../../Lib/NormalDialog';
import { Pie, PieChart, Cell } from 'recharts';



 
export default function WorkEntry_List(props){
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [fileDialog, setFileDialog] = useState({open:false, id:0, title:"Files"});
    const [hisDialog, setHisDialog] = useState({open:false,title:" ",id:0});
    const [transDialog, setTransDialog] = useState({open:false, id:0, title:"Transfer"});
    const [filters, setFilter] = useState({
        search : "",
        page:1,
        perpage:10,
        alotted_by:"",
        alotted_to:"",
        dateFilter:"",
        status:"",
        proj_id:"",
        cost_id: "",
        from:moment("01 Jan 2019").format("DD MMM YYYY"),
        to:moment().add(3,"M").format("DD MMM YYYY"),
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const [chartData, setChartData] = useState({
        records:[],
        total:0,
    });

    const [associates,setAssociates] = useState([]);
    const [managers,setManagers] = useState([]);
    const [view,setView] = useState("List");
     
    const Delete = () => {
        apiPost("/admin/work-entry/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    }

    const [transferTo,setTransferTo] = useState(0);
 
    const Transfer = () => {
        apiPost("/admin/work-entry/transfer",{
            w_id:transDialog.id,
            emp_id:transferTo
        },(r)=>{
            setTransDialog({...transDialog, open:false});
            Loadlist();
        })
    }

    const [associateList, setAssociateList] = useState([]); 
    const LoadAssociateList = () => {
        apiGet("/admin/employee/listall",{ role:1 },(rs)=>{
            let data = rs.data.records.map((r) => {
                return { value:r.emp_id, label: r.emp_fullname+" - "+r.alotted_work_count };
            });          
            setAssociateList(data);
            
        });
    }
//console.log("Status: " + filters.status);
    const Loadlist = () => {
         
        if(typeof filters.proj_id === "undefined")
        { setFilter({...filters, proj_id : ""}); }
        if(typeof filters.alotted_by === "undefined")
        { setFilter({...filters, alotted_by : ""}); }
        if(typeof filters.alotted_to === "undefined")
        { setFilter({...filters, alotted_to : ""}); }
 
        apiGet("/admin/work-entry/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
            window.dispatchEvent(new CustomEvent(Event_onAfterLoadlist, {detail: {listData:listData, filters:filters} }));
        })
        apiGet("/admin/work-entry/report-loadlist",filters,(r)=>{
            setChartData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }
    useEffect(() => {
        const intervalId = setInterval(() => {
          console.log('Auto-refreshing data...');
          Loadlist();
        }, 500);
    
        return () => clearInterval(intervalId); // Clean up the interval when the component unmounts
      }, [filters]); // Run the effect whenever the filters change
    
    
    useEffect(()=>{
        Loadlist();
    },[filters]);
 
    let dateFilterList = [
        {value:"", label:"--None--"},
        {value:"w_completion_date", label:"Completion Date"},
        {value:"w_estimated_date", label:"Estimated Date"},
        {value:"w_lastupdated_on", label:"Last Updated Date"},
        {value:"w_created_on", label:"Create Date"},
    ]
    const Action = {
        SendToAwd(w_id){
            let answer = window.confirm("Are you sure? You won't be able to edit this entry anymore.");
            if(answer){
                apiPost("/admin/work-entry/action-send-to-awd",{w_id:w_id},(r)=>{
                    Loadlist();
                });
            }            
        },
        Approve(w_id){
            let answer = window.confirm("Are you sure?");
            if(answer){
                apiPost("/admin/work-entry/action-approve",{w_id:w_id},(r)=>{
                    Loadlist();
                });
            }            
        }
    }

    const [costList, setCostList] = useState([]);
    const LoadCostList = (proj_id) => {
        apiGet("/admin/project-cost/listall",{proj_id:proj_id},function(r){            
            const list = r.data.records.map((r2)=>{
                return {value:r2.cost_id, label:r2.cost_title};
            });
       
            list.push({value:"",label:"--All/Any--"})
            setCostList(list);
            setFilter({...filters, cost_id:"", proj_id:proj_id});
           
        })
    }

    const [costList2, setCostList2] = useState([]);
    const LoadCostList2 = (proj_id) => {
        apiGet("/admin/project-cost/listall",{proj_id:proj_id},function(r){            
            const list = r.data.records.map((r2)=>{
                return {value:r2.cost_id, label:r2.cost_title};
            });
       
            list.push({value:"",label:"--Select One--"})
            setCostList2(list);
            setEditModal({...editModal, cost_id:"", proj_id:proj_id});           
        })
    }
    
    const [selected,setSelected] = useState(
        new Array(listData.records.length).fill(false)
    );
    
    const [bulkEditDialog, setBulkEditDialog] = useState(false);
    
    const [editModal, setEditModal] = useState({
        "proj_id":"",
        "cost_id":""
    });

    const bulkUpdate = () => {
        let answer = window.confirm("Are you sure?");
        if(answer){
            let w_ids = [];
            selected.forEach((element, index) => {
                if(element === true){
                    let w_id = listData.records[index].w_id;
                    let status = listData.records[index].w_status;
                    if(status !== "Sent to AWD"){
                        w_ids.push(w_id);
                    }
                }
            });
            apiPost("/admin/work-entry/bulk-save",{ 
                w_ids:w_ids,
                proj_id:editModal.proj_id,
                cost_id:editModal.cost_id
            },(r)=>{  
                setBulkEditDialog(false);
                Loadlist();
            })
        }
    }

    return <> 
        <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
        <NormalDialog onClose={(e)=>{ setFileDialog({...fileDialog, open:false}); }} title={fileDialog.title} open={fileDialog.open}>
            {fileDialog.open ? <Files w_id={fileDialog.id} /> : "" } 
        </NormalDialog>
        <NormalDialog onClose={(e)=>{ setHisDialog({...hisDialog, open:false}); }} title={hisDialog.title} open={hisDialog.open}>
                {hisDialog.open ? <WorkHistory forUser="admin" w_id={hisDialog.id} /> : "" } 
        </NormalDialog>



        <NormalDialog
        width={400}
        onClose={(e)=>{ setTransDialog({...transDialog, open:false}); }} 
        title={transDialog.title} open={transDialog.open}>
            <div className="form-group text-center">
                <label>Transfer To</label>  
                <select className="form-control" name="transferTo" onChange={(e)=>setTransferTo(e.target.value)} value={transferTo}>
                    <option key="0" value="0">--Select Associate--</option>                 
                    {associateList.map((r)=>{                
                        return <option key={r.value} value={r.value}>{r.label}</option>
                    })}
                </select>
            </div>
            <div className="text-center">
                <button onClick={Transfer} className="btn btn-lg btn-primary">Transfer</button>
            </div>
        </NormalDialog> 

        <NormalDialog
        width={400}
        onClose={(e)=>{ setBulkEditDialog(false); }} 
        title={"Set Project Details"} open={bulkEditDialog}>
            <FC.FormContextProvider value={{modal:editModal,setModal:setEditModal,error:[]}}>
                <FC.AutoFillRemote 
                    onSelectOption={(item)=>{
                        LoadCostList2(item.value);                                        
                    }}
                    onInput={(e)=>{
                        let input = e.target.value.toString();
                        if(input.trim() == ""){
                            setCostList2([{"value":"","label":"--All/Any--"}]);
                            setEditModal({...editModal, cost_id:"", proj_id: ""});                            
                        }
                    }}
                    name="proj_id" 
                jsonSource={ApiBaseUrl+"/admin/suggest/project?token="+state.UserState.token} name="proj_id" label="Project" />   

                <FC.Select name="cost_id" label={"Cost"} options={costList2} />
 
            </FC.FormContextProvider>

            <div className="text-center">
                <button onClick={bulkUpdate} className="btn btn-lg btn-primary">Update</button>
            </div>
        </NormalDialog> 

        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th colSpan="10">
                            
                            <div className="float-right form-row">
                                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}>

                                <div className="col">
                                    <FC.AutoFillRemote 
                                    onSelectOption={(item)=>{
                                        LoadCostList(item.value);                                        
                                    }}
                                    onInput={(e)=>{
                                        let input = e.target.value.toString();
                                        if(input.trim() == ""){
                                            setCostList([{"value":"","label":"--All/Any--"}]);
                                            setFilter({...filters, cost_id:""});
                                            setFilter({...filters, proj_id:""});
                                        }
                                    }}
                                    name="proj_id" 
                                    jsonSource={ApiBaseUrl+"/admin/suggest/project?token="+state.UserState.token} name ="proj_id" label="Project" />
                                </div>
                                
                                <div className="col">
                                    <FC.Select name="cost_id" label={"Cost"} options={costList} />
                                </div> 
                                <div className="col">
                                    <FC.AutoFillRemote name="alotted_by"
                                    jsonSource={ApiBaseUrl+"/admin/suggest/employee?token="+state.UserState.token+"&role=2"} name ="alotted_by" label="Alotted By" />
                                </div>
                                <div className="col">
                                    <FC.AutoFillRemote name="alotted_to" 
                                    jsonSource={ApiBaseUrl+"/admin/suggest/employee?token="+state.UserState.token+"&role=1"}  name ="alotted_to" label="Alotted To" />
                                </div>
                                <div className="col">
                                    <FC.Select name="status" label={"Status"} options={StatusListWithAll} />
                                </div>
                                <div className="col">
                                    <FC.Select name="dateFilter" label={"Date Filter"} options={dateFilterList} />
                                </div>                                
                                <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                                    <FC.DateInput name="from" label={"From"} />
                                </div>
                                <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                                    <FC.DateInput name="to" label={"To"} />
                                </div>

                                <div className="col">
                                    <FC.TextInput name="search" label={"Search"} />
                                </div> 
                                </FC.FormContextProvider>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={10}>
                            <button type="button" onClick={()=>setBulkEditDialog(true)} className="btn btn-primary">
                                Bulk Edit...
                            </button>
                            <div className="form-row d-print-none float-right">
                                <div className="col">
                                    <label class="mr-2">View</label> 
                                                                  
                                        <input type="radio" onChange={(e)=>setView(e.target.value)} checked={view === 'List' ? true : false} value="List" name="view" /> List
                                        <input className="ml-2" type="radio" onChange={(e)=>setView(e.target.value)} checked={view === 'Chart' ? true : false} value="Chart" name="view" /> Status Report
                                        <button type="button" onClick={()=>window.print()} className="btn ml-2 btn-primary">Print</button>
                                </div>   
                                      
                            </div>
                        </th>
                        </tr>
                    <tr className={view === "List" ? "" : "d-none"}>
                        <th style={{width:150}} className="action-col d-print-none">
                            <input type="checkbox"
                            onClick={(e)=>{
                                setSelected(new Array(listData.records.length).fill(e.target.checked));
                            }}
                            className="mr-2" name="selectAllItems" />
                            Actions </th>
                        <th>Project Title</th>
                        <th>Cost Type</th>
                        <th>Rate</th> 
                        <th>Quantity</th> 
                        <th>Amount</th>                            
                        <th>Description</th>
                        <th>Alotted By</th>
                        <th>Alotted To</th>
                        <th>Estimated Date</th>
                        <th>Completion Date</th>
                        <th>Last Updated On</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                <tr className={view === "Chart" ? "" : "d-none"}>
                        <td colSpan={10}>
                         <Chart  chartData={chartData} />
                        </td>
                    </tr>
                {listData.records.map((r,index) => (
                    <tr key={r.w_id} className={view === "List" ? "" : "d-none"}>
                        <td className="d-print-none"> 
                            <input type="checkbox"
                            checked={selected[index]}
                            className="mr-2" value={r.w_id} name="selectItem" onClick={(e)=>{ 
                                let x = [...selected];

                                x[index] = e.target.checked;
                                setSelected(x);  
                            }} />
                            <B.DropdownButton id="dropdown-basic-button" className="d-inline" size={"sm"} title="Actions">
                                <B.Dropdown.Item  
                                    onClick={(e)=>{
                                        window.dispatchEvent(new CustomEvent(Event_onEdit, { detail: r.w_id }));
                                    }} href="#">{r.w_status === "Sent to AWD"  ? "View" : "Edit" }</B.Dropdown.Item>


                                <B.Dropdown.Item  
                                    onClick={(e)=>{                                        
                                        setFileDialog({id:r.w_id, title:"Files for "+r.w_description, open:true})
                                    }} href="#">
                                        Files
                                </B.Dropdown.Item>
                                <B.Dropdown.Item  
                                    onClick={(e) => setHisDialog({open:true, id:r.w_id, title:"Work History - "+r.w_description+" ("+r.project.proj_title+")"})} href="#">History</B.Dropdown.Item>

                                {r.w_status !== "Sent to AWD" &&
                                <B.Dropdown.Item onClick={(e)=>{                                        
                                        setTransDialog({id:r.w_id, title:"Transfer Work: "+r.w_description, open:true})
                                    }} href="#">Transfer</B.Dropdown.Item>}

                                {r.w_status === "Approved" &&
                                <B.Dropdown.Item onClick={(e)=>{                                                                                
                                        Action.SendToAwd(r.w_id);
                                    }} href="#">Send To AWD</B.Dropdown.Item>}
                                
                                {r.w_status === "Completed" &&
                                <B.Dropdown.Item onClick={(e)=>{                                                                                
                                        Action.Approve(r.w_id);
                                    }} href="#">Approve</B.Dropdown.Item>}

                                <B.Dropdown.Item 
                                onClick={(e)=>{ 
                                    e.preventDefault();
                                    setConfirmDialogState({open:true, id:r.w_id})                                    
                                    }
                                } 
                                className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>
                                                      
                            </B.DropdownButton>
                        </td>

                        <td>{r.project.proj_title}</td>
                        <td>{r.cost.cost_title}</td>
                        <td>{r.w_rate}</td>
                        <td>{r.w_qty}</td>
                        <td>{r.w_amount}</td>
                        <td>{r.w_description}</td>
                        <td>{r.alottedBy.emp_fullname}</td>
                        <td>{r.alottedTo.emp_fullname}</td>
                       
                        <td>{moment(r.w_estimated_date).format("DD MMM YYYY")}</td>
                        <td>{moment(r.w_completion_date).format("DD MMM YYYY")}</td>                       
                        <td>{moment(r.w_lastupdated_on).format("DD MMM YYYY")}</td>
                        <td>
                            <strong className={"text-"+getStatusColor(r.w_status)}>
                            {r.w_status}
                            </strong>
                        </td>
                    </tr>   
                ))}
                </tbody>
                <tfoot className={view === "List" ? "d-print-none" : "d-none d-print-none"}>
                    <tr>
                        <td colSpan="10">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={10}
                                totalItemsCount={listData.total}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        
        
    
    </>
}


function Chart({chartData, ...props}){
    let n = 0;
    let COLORS = [
        '#007bff','#6610f2','#e83e8c','#dc3545','#fd7e14','#ffc107','#28a745'
    ];
    let chartDataPoints = chartData.records.map((r)=> { 
        n++;        
        return {
        'w_status':r.w_status,
        'work_count':parseInt(r.work_count),
        'color':COLORS[n]
        }
    });

    

    return (
    <div {...props}>
    <div className="row">
        <div className="col-md-6">
            <PieChart width={800} height={600}> 
                <Pie data={chartDataPoints} dataKey="work_count" nameKey="w_status" cx="50%" cy="50%" outerRadius={300} fill="#8884d8">            
                    {chartDataPoints.map((entry, index) => <Cell key={index} fill={entry.color}/>)}
                </Pie>
            </PieChart>
        </div>
        <div className="col-md-6">
            <h5>Legend</h5>
            <table className="table table-bordered">
                <tbody>
                    {chartDataPoints.map(r=>{
                        return <tr  key={r.w_status}>
                            <td>
                                <span className="p-2 mr-2 d-inline-block" style={{backgroundColor:r.color}}></span>
                                {r.w_status} - {r.work_count}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>
    </div>
    );
}