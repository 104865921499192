import * as B from 'react-bootstrap';
import React from 'react';
import { useState, useEffect } from 'react';
import { apiGet } from '../../../Lib/Helpers';
import AdminLayout from '../../Layouts/AdminLayout';
import Pagination from "react-js-pagination";
import moment from 'moment';
 
import * as FC from '../../../Lib/FormControl';
 
  
export default function Report_LastDateExpired(){
 
    const [filters, setFilter] = useState({
        //month:moment().format("MM"),
        //year:moment().format("YYYY"),
        from:moment().format("01 MMM YYYY"),
        to:moment().format("DD MMM YYYY"),
        page:1,
        perpage:100, 
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    
    const Loadlist = () => {
 
        apiGet("/admin/project/report-lastdate-expired",filters,(r)=>{      
            //console.log(r.data.records);      
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }
  
    useEffect(()=>{
        Loadlist();
    },[filters]);
/*
    let monthList = [
        {value:"01", label:"Jan"},
        {value:"02", label:"Feb"},
        {value:"03", label:"Mar"},
        {value:"04", label:"Apr"},
        {value:"05", label:"May"},
        {value:"06", label:"Jun"},
        {value:"07", label:"Jul"},
        {value:"08", label:"Aug"},
        {value:"09", label:"Sep"},
        {value:"10", label:"Oct"},
        {value:"11", label:"Nov"},
        {value:"12",label:"Dec"},
    ]

    let yearList = [];
    for (let index = 2019; index < 2050; index++) {
        yearList.push({
            value:index, label:index
        });
    }
*/
    let perpageList = [
        {value:10, label:10},
        {value:20, label:20},
        {value:50, label:50},
        {value:100, label:100},
        {value:200, label:200},
        {value:500, label:500},
        {value:1000, label:1000},
        {value:2000, label:2000},
        {value:5000, label:5000},        
    ]

    let n = ( filters.page * filters.perpage ) - filters.perpage;
    return <> 
    <AdminLayout title={"Report - Last Dates Projects - "+moment(filters.from).format("DD MMM YYYY")+" - "+moment(filters.to).format("DD MMM YYYY")+" - Page: "+filters.page}>            
     

        <div style={{minHeight:400}} className="table-responsive">
            <table className="table">
                <thead> 
                    <tr>
      <th colSpan={11}>
        <div class="d-print-none">
                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}>
                    <div className="form-row "> 
                        {/*<div className="col-md-2">
                            <FC.Select name="year" label={"Year"} options={yearList} />
                        </div>
                        <div className="col-md-2">
                            <FC.Select name="month" label={"Month"} options={monthList} />
                        </div> */}
                        <div className="col-md-2">
                            <FC.DateInput name='from' label={'From'} />                            
                        </div>
                        <div className="col-md-2">
                            <FC.DateInput name='to' label={'To'} />
                        </div>
                        <div className="col-md-2">
                            <FC.Select name="perpage" label={"Perpage"} options={perpageList} />
                        </div>
                        <div className="col-md-2">
                            <label>&nbsp;</label><br />
                            <button onClick={()=> window.print()} type="button" className="btn btn-primary">Print</button>
                        </div>
                    </div>
                </FC.FormContextProvider>
            </div>
             </th>
             </tr>
                     <tr>
                        <th>S.No</th>
                        <th>Project Title</th>
                        <th>Type</th>                            
                        <th>Hard/Soft</th>
                        <th>Cost</th>
                        <th>Creation Date</th>
                        <th>Completion Date</th>
                        <th>Last Task Date</th>
                    </tr>
                </thead>
                <tbody>
                
                {listData.records.map(r => { n++; return (
                    <tr key={r.proj_id}>
                        <td>{n}</td>
                        <td>{r.proj_title}</td>
                        <td>{r.proj_type}</td>
                        <td>{r.proj_type2}</td>
                        <td>{r.proj_cost}</td>
                        <td>{moment(r.proj_creation_date).format("DD MMM YYYY")}</td>
                        <td>{moment(r.proj_completion_date).format("DD MMM YYYY")}</td>                       
                        <td>{moment(r.proj_lasttaskdate).format("DD MMM YYYY")}</td> 
                    </tr>   
                ) } )}
                </tbody>
                <tfoot className="d-print-none">
                    <tr>
                        <td colSpan="7">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={filters.perpage}
                                totalItemsCount={listData.total}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        
        
    



    
    </AdminLayout>
    </>
}