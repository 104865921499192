import React from 'react';
 
 

import { useState, useEffect } from 'react';

import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import {  useDispatch, useSelector } from 'react-redux';
 
import SaveDialog from '../../../Lib/SaveDialog';

import * as B from 'react-bootstrap';
import { ApiBaseUrl } from '../../../Config';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Confirmation from '../../../Lib/Confirmation';
import moment from 'moment';
const ProjectFile = (props) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [fsDialog, setFsDialog] = useState({open:false,title:" ",id:0});
    const [selectedFile, setSelectedFile] = useState({});
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const [filters, setFilter] = useState({
        search : "",
        proj_id:props.proj_id,
        page:1,
        perpage:10,
    })
    const initialModalState = {
        file_title:"",        
        file_path:"",
        file_id: "",        
        proj_id: props.proj_id,
        file:"",
    }
    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialModalState})
    
    useEffect(() => {
        Loadlist();
    },[filters]);

    const New = () => {
        setModal({...initialModalState});
        setSelectedFile("");
        setFsDialog({open:true,title:"Upload New File",id:0});
    }

    const Delete = () =>{
        apiPost("/admin/project-file/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/project-file/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project-file/get",{
            id:id
        },(r)=>{
            setModal({ ...r.data});
            setSelectedFile("");
            setError({...initialModalState});
            setFsDialog({open:true,title:"Edit"});            
        })
    };

    const Save = (e) =>{
        e.preventDefault(); 
        let formData = new FormData();
        for (const [key, value] of Object.entries(modal)) {            
            formData.append("ProjectFile["+key+"]",value);
        }
 
        formData.append('token',state.UserState.token);
        dispatch({type: "GS/LOADING_START"});
        axios.post(ApiBaseUrl+"/admin/project-file/save",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).then((r)=>r.data).then((r)=>{  
            if(AjaxValidate(r,setError)){
                setFsDialog({open:false, title:""});
                Loadlist();                
            }
            dispatch({type: "GS/LOADING_STOP"});
        }).catch((err)=>{
            dispatch({type: "GS/LOADING_STOP"});
        });
 
    };
    
 
    
    return (
    <>
       
        <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
        
        
        
<SaveDialog onClose={(e)=>setFsDialog({open:false})} onSubmit={(e) => Save(e)} open={fsDialog.open} title={fsDialog.title}>


    
        <div className="form-group">
            <label>File Title</label>
            <input type="text" className="form-control"
                value={modal.file_title}
                onChange={(e)=>setModal({...modal, file_title:e.target.value})} />
            <div className="text-danger">{error.file_title}</div>
        </div>
        <div className="form-group">
 
            <input type="file" id="file" name="file" style={{display:'none'}} onChange={(e)=>{
                    setModal({...modal, file:e.target.files[0]});
                    setSelectedFile(e.target.files[0]);
                }} />  

            <label htmlFor="file">
                <span className="btn btn-primary">Browse...</span>
            </label> 
            <a target="_blank" className="btn btn-info ml-2"
                href={ApiBaseUrl+"/uploads/"+modal.file_path}
                style={  modal.file_path.trim() == '' ?  {display:'none'}: {}  } variant="outlined" color="secondary">
                Download File
            </a>
            <div style={{color:'gray'}}>{selectedFile.name}</div>           
            <div style={{color:'red'}}>{error.file}</div>
        </div>

        
          
        <input type="hidden" id="proj_id" value={modal.proj_id} />
        <input type="hidden" id="file_id" value={modal.file_id} />
</SaveDialog>

<B.Button variant="primary" size="lg" className="mb-2" onClick={()=>New()}>New</B.Button>

<div className="table-responsive">


        <table className="table">
            <thead>
                <tr>
                    <th style={{width:100}}>Actions</th>
                    <th>Title</th>
                    <th style={{width:200}}>Date</th>
                </tr>
            </thead>
            <tbody>
                {listData.records.map(r => (
                    <tr key={r.cost_id}>
                        <td>
                            <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                <B.Dropdown.Item  
                                    onClick={(e)=>Get(r.file_id)} href="#">Edit</B.Dropdown.Item>
                                <B.Dropdown.Item 
                                    onClick={(e)=>window.open(ApiBaseUrl+"/uploads/"+r.file_path)} href="#">Download</B.Dropdown.Item>                                  
                                <B.Dropdown.Item 
                                onClick={(e)=>{ 
                                    e.preventDefault();
                                    setConfirmDialogState({open:true, id:r.file_id})                                    
                                    }
                                }
                                className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                
                            </B.DropdownButton>
                        </td>
                        <td>{r.file_title}</td>
                        <td>{moment(r.file_date).format("DD MMM YYYY h:mm:ss a")}</td>
                    </tr>  
                    ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3">
                    <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={listData.total}
                pageRangeDisplayed={5}
                onChange={page => {
                    setFilter({...filters, page})
                }}
            />
                    </td>
                </tr>
            
            </tfoot>
        </table> </div>
    </>
    );

}


 

export default ProjectFile;