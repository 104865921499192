import React, { useEffect, useState } from 'react';
import {Events} from '../Constants';
 
import { apiGet, apiPost } from '../../../../../Lib/Helpers';
import * as FormControl from '../../../../../Lib/FormControl'; 
import SaveDialog from '../../../../../Lib/SaveDialog';
 

export default function Edit(props){

   
    const initialModalState = {
        txn_id:0,
        txn_comment:"",
        txn_leave_type:"Casual",
        txn_type:"Debit",
        txn_amount:1,
        emp_id:props.emp_id
    }

    const initialErrorState = {
        txn_id:"",
        txn_comment:"",
        txn_leave_type:"",
        txn_type:"",
        txn_amount:"",
        emp_id:""
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const Save = (e) =>{
        e.preventDefault(); 
        apiPost("/admin/leave/account-save",{LeaveAccount:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Events.onAfterSave, {detail:modal}));
        },setError)  
    };

    const Get = (e) => {         
        let id = e.detail;
 
        apiGet("/admin/leave/account-get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                setModal(r.data);                  
                setOpen(true);                
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
    }

     
    
    useEffect(()=>{ 
        window.addEventListener(Events.onNew,New);
        window.addEventListener(Events.onEdit,Get);
        return () =>{
            window.removeEventListener(Events.onNew,New);
            window.removeEventListener(Events.onEdit,Get);
        } 
    },[])


    return <>
        <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
            <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
                <FormControl.Select name="txn_type" label={"Transaction Type"} options={[
                    {value:"Credit",label:"Credit"},
                    {value:"Debit",label:"Debit"},
                ]}/>
                <FormControl.Select name="txn_leave_type" label={"Leave Type"} options={[
                    {value:"Paid",label:"Paid"},
                    {value:"Casual",label:"Casual"},
                    {value:"Special",label:"Special"},
                ]}/>
                <FormControl.TextInput name="txn_amount" label={"Number of Days"}/>
                <FormControl.Textarea name="txn_comment" label={"Comments"}/>

                
                <input type="hidden" name="txn_id" value={modal.txn_id} />
            </FormControl.FormContextProvider>
        </SaveDialog>
    </>
}