import React from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Helmet } from 'react-helmet';


export default function BlankLayout(props){
    return <>
    
    <Helmet>
        <title>{props.title}</title>
    </Helmet>
 
  
        {props.children}
 
        
    </>
}