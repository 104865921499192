import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import Edit from './Edit';
import List from './List';

import EmployeeLayout from '../../../Layouts/EmployeeLayout';
import { Events } from './Constants';
import NormalDialog from '../../../../Lib/NormalDialog';
import AccountMain from '../Account/Main.js';
 

export default function Main(props){
    const [accDialog, setAccDialog] = useState(false);
    return <> 
    <EmployeeLayout title={"Request Manager"}>
        <Edit type={props.type} />        
        <button onClick={()=>{
            window.dispatchEvent(new CustomEvent(Events.onNew,{}));            
        }} className="btn btn-lg mr-2 btn-primary mb-2">New</button>

        <button onClick={()=>{                      
            setAccDialog(true);
        }} className="btn btn-lg mr-2 btn-primary mb-2">Leave History</button>

        <NormalDialog title="Leave History" open={accDialog} onClose={()=>setAccDialog(false)}>
            <AccountMain />
        </NormalDialog>

        <List />
    </EmployeeLayout>
    </>
}