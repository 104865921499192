import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List from './WorkEntry_List';
import { Event_onAfterSave, Event_onNew, Event_onEdit,Event_onAfterStatusChange   } from './Events';
 
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../Lib/Helpers';
import * as FormControl from '../../../Lib/FormControl';
import moment from 'moment';
import SaveDialog from '../../../Lib/SaveDialog';
import { ApiBaseUrl } from '../../../Config';
import { getStatusColor, StatusList, StatusListWithAll } from '../../../Constants/WorkEntry_StatusList';
import NormalDialog from '../../../Lib/NormalDialog';
import Files from './Files';
export default function WorkEntry_Dialog(props){
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const initialModalState = {
        w_id:0,
        w_description:"",
        w_rate:0,
        w_qty:0,
        w_amount:0,
        w_estimated_date:moment().format("DD MMM YYYY"),
        w_completion_date:moment().format("DD MMM YYYY"),
      //  w_alotted_by:1,
        w_alotted_to:1,
       // w_status:"",
        task_id:"",
        cost_id:"",
        proj_id:"", 
      
        project:{
            proj_title: ""
        },
        cost:{
            cost_title:""
        },
        alottedTo:{
            emp_fullname:""
        },
        alottedBy:{
            emp_fullname:""
        },
        taskNature:{
            task_title:""
        }
    }

    const [modal, setModal] = useState(initialModalState);
    
    const Get = (e) => {         
        let id = e.detail;
        apiGet("/associate/work-entry/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("View");
                setModal(r.data);                  
                setOpen(true);                     
            } else {
                alert("Not Found");
            }
        });
    }
 
    const [open,setOpen] = useState(false);

    const [title,setTitle] = useState("");
    useEffect(()=>{
//window.addEventListener(Event_onAfterStatusChange,Get);
     
        window.addEventListener(Event_onEdit,Get);
        return () =>{
           // window.removeEventListener(Event_onAfterStatusChange,Get);
   
            window.removeEventListener(Event_onEdit,Get);
        } 
    },[])
 
    const getLabel = (label, value)=>{
        return <>
            <div className="form-group">
                <label>{label}</label><br />
                {value}
            </div>
        </>;
    }

    const DoAction = (status) => {
        let answer = window.confirm("Are you sure?");
        if(answer){
            apiPost("/associate/work-entry/change-status",{status:status, w_id:modal.w_id},(r)=>{
                setModal({...modal, w_status:status});                                  
                window.dispatchEvent(new CustomEvent(Event_onAfterStatusChange,{detail:modal.w_id}));                  
                if(status !== "Accepted"){
                    setOpen(false);
                }
                /*if(status == "Accepted"){
                    window.dispatchEvent(new CustomEvent(Event_onEdit, { detail: modal.w_id }));
                } else {
                    setOpen(false);
                    window.dispatchEvent(new CustomEvent(Event_onAfterSave)); 
                }*/
            })
        }
    }

    let ActionButtons = <></>;
    if(modal.w_status == "Pending" || modal.w_status == "Transferred"){
        ActionButtons = <><button onClick={()=>DoAction("Accepted")} className="btn btn-success">Accept</button><button onClick={()=>DoAction("Rejected")} className="btn btn-danger">Reject</button></>
    } else if(modal.w_status == "Accepted") {
        ActionButtons = <><button onClick={()=>DoAction("Completed")} className="btn btn-success">Complete</button></>        
    }

    let readonly = true;
    if(modal.w_status == "Accepted"){
        readonly = false;
    }

    return <>
    
    <NormalDialog footer={ActionButtons} onClose={()=>setOpen(false)}  open={open} title={title}>
        <B.Row> 
            <B.Col md={4}>
                {getLabel("Project",modal.project.proj_title)}
                {getLabel("Cost",modal.cost.cost_title)}
                {getLabel("Task Nature",modal.taskNature.task_title)}
                {getLabel("Description",modal.w_description)}

                <B.Row>
                    <B.Col md={4}>
                    {getLabel("Rate",modal.w_rate)}
                    </B.Col>
                    <B.Col md={4}>
                    {getLabel("Quantity",modal.w_qty)}    
                    </B.Col>
                    <B.Col md={4}>
                    {getLabel("Amount",modal.w_amount)}    
                    </B.Col>
                </B.Row>

                <B.Row>
                    <B.Col md={6}>
                        {getLabel("Estimated Date",moment(modal.w_estimated_date).format("DD MMM YYYY") )}
                    </B.Col>
                    <B.Col md={6}>
                        {getLabel("Completion Date",moment(modal.w_completion_date).format("DD MMM YYYY") )}
                    </B.Col>                
                </B.Row>

                <B.Row>
                    <B.Col md={6}>
                        {getLabel("Alotted By",modal.alottedBy.emp_fullname)}
                    </B.Col>
                    <B.Col md={6}>
                        {getLabel("Alotted To",modal.alottedTo.emp_fullname)}
                    </B.Col>                
                </B.Row>
                
                {getLabel("Status",modal.w_status)} 
            </B.Col>
            <B.Col md={8}>
                {console.log(modal.w_id)}
                <Files readonly={readonly} w_id={modal.w_id} />
            </B.Col>
        </B.Row> 
    </NormalDialog>
    
    
    </>
}

 
/*
<NormalDialog  onClose={()=>setOpen(false)}  open={open} title={title}>
        <B.Row>
            <B.Col md={6}>
                <B.Row>
                    <B.Col md={6}>{getLabel("Project",modal.project.proj_title)}</B.Col>
                    <B.Col md={6}>{getLabel("Cost",modal.cost.cost_title)}</B.Col> 
                </B.Row>

                {getLabel("Task Nature",modal.w_description)}

                <B.Row>
                    <B.Col md={4}>{getLabel("Rate",modal.w_rate)}</B.Col>
                    <B.Col md={4}>{getLabel("Quantity",modal.w_qty)}</B.Col>
                    <B.Col md={4}>{getLabel("Amount",modal.w_amount)}</B.Col>
                </B.Row>

                {getLabel("Task Nature",modal.taskNature.task_title)}
                
                <B.Row>
                    <B.Col md={6}>{getLabel("Estimated Date",moment(modal.w_estimated_date).format("DD MMM YYYY") )}</B.Col>
                    <B.Col md={6}>{getLabel("Completion Date",moment(modal.w_completion_date).format("DD MMM YYYY") )}</B.Col> 
                </B.Row>

                <B.Row>
                    <B.Col md={4}>{getLabel("Alotted By",modal.alottedBy.emp_fullname)}</B.Col>
                    <B.Col md={4}>{getLabel("Alotted To",modal.alottedTo.emp_fullname)}</B.Col>
                    <B.Col md={4}>{getLabel("Status",modal.w_status)}</B.Col> 
                </B.Row>
                {ActionButtons}
            </B.Col>
            <B.Col md={6}>
                <Files workentry={modal} w_id={modal.w_id} />
            </B.Col>
        </B.Row>
        
        

 
    </NormalDialog>
    */