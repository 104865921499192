import React from 'react';
import AdminLayout from '../../Layouts/AdminLayout';

import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import {  useDispatch, useSelector  } from 'react-redux';
 
import * as B from 'react-bootstrap';
 
import moment from 'moment';

import NormalDialog from '../../../Lib/NormalDialog';
import Confirmation from '../../../Lib/Confirmation';
import SaveDialog from '../../../Lib/SaveDialog';
 
import axios from 'axios';
import { ApiBaseUrl } from '../../../Config';
 
import Pagination from "react-js-pagination";
 
import ProjectFile from './ProjectFile';
import ProjectCost from './ProjectCost';
import ProjectHistory from './ProjectHistory';
import PrintOrder from '../PrintOrder/PrintOrder';
 
import ProjectQB from './ProjectQB';
import * as FormControl from '../../../Lib/FormControl';
 
import { Multiselect } from 'multiselect-react-dropdown';
 
export default function Project(props){
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState({});
    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [filters, setFilter] = useState({
        search : "",
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const initialModalState = {
        proj_title:"",
        proj_creation_date: moment().format("DD MMM YYYY"),
        proj_completion_date: moment().format("DD MMM YYYY"),
        proj_lasttaskdate: moment().format("DD MMM YYYY"),
        proj_id: "",
        proj_file:"",
        proj_cost:0,
        proj_type:"Default",
        proj_type2:"Hard",
        file:"",
    }
    
    const initialErrorState = {
        proj_title:"",
        proj_creation_date: "",
        proj_completion_date: "",
        proj_lasttaskdate: "",
        proj_id: "",
        proj_file:"",
        proj_cost:"",
        proj_type:"",
        proj_type2:"",
        file:"",
    }
    
    const [selManagerList, setSelManagerList] = useState([]);
    const [managerList, setManagerList] = useState([]);

    const [selAssociateList, setSelAssociateList] = useState([]);
    const [associateList, setAssociateList] = useState([]);

    const [modal, setModal] = useState({...initialModalState});
    const [error, setError] = useState({...initialErrorState});
    const [csDialog, setCsDialog] = useState({open:false,title:" ",id:0});
    const [prDialog, setPrDialog] = useState({open:false,title:" ",id:0});
    const [fileDialog, setFileDialog] = useState({open:false,title:" ",id:0});
    const [projectQB, setProjectQB] = useState({open:false,title:" ",id:0}); 
    const [hisDialog, setHisDialog] = useState({open:false,title:" ",id:0});
 
    useEffect(() => {
        Loadlist();
    },[filters]);

    const New = () =>{
        setModal({...initialModalState});
        setError({...initialErrorState});
        setSelectedFile("");
        setDialogState({ open : true, title: "New" });

        apiGet("/admin/project/list-managers",{},(rs)=>{
                
            let list = rs.data.map((r)=>{
                return {
                    id:r.emp_id,
                    name:r.emp_uid + " - " + r.emp_fullname
                }
            });
            setManagerList(list);         
            setSelManagerList([]);
        });

        apiGet("/admin/project/list-associates",{},(rs)=>{
                
            let list = rs.data.map((r)=>{
                return {
                    id:r.emp_id,
                    name:r.emp_fullname
                }
            });
            setAssociateList(list);         
            setSelAssociateList([]);
        });

    };


    const Delete = () =>{
        apiPost("/admin/project/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };
 
    const Loadlist = () =>{
        apiGet("/admin/project/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project/get",{
            id:id
        },(r)=>{
            setModal({ ...r.data, 
                proj_creation_date:r.data.proj_creation_date,
                proj_completion_date:r.data.proj_completion_date,
                proj_lasttaskdate:r.data.proj_lasttaskdate 
            });
            setSelectedFile("");
            setError({...initialErrorState});
            
            apiGet("/admin/project/list-managers",{},(rs)=>{
                
                let list = rs.data.map((r)=>{
                    return {
                        id:r.emp_id,
                        name:r.emp_uid + " - " + r.emp_fullname
                        
                    }
                });
                setManagerList(list);


             
                apiGet("/admin/project/list-managers",{proj_id : id},(rs2)=>{
                    let list = rs2.data.map((r)=>{
                        return {
                            id:r.emp_id,
                            name:r.emp_uid + " - " + r.emp_fullname
                        }
                    });
                    setSelManagerList(list);
                });
            }); 
            apiGet("/admin/project/list-associates",{},(rs)=>{
                
                let list = rs.data.map((r)=>{
                    return {
                        id:r.emp_id,
                        name:r.emp_fullname
                    }
                });
                setAssociateList(list);

                apiGet("/admin/project/list-associates",{proj_id : id},(rs2)=>{
                    let list = rs2.data.map((r)=>{
                        return {
                            id:r.emp_id,
                            name:r.emp_fullname
                        }
                    });
                    setSelAssociateList(list);
                });
            }); 
            setDialogState({open:true,title:"Edit"});
            
        })
    };

    const Save = (e) =>{
        e.preventDefault(); 
        let modal2 = {...modal};
        modal2.proj_creation_date = modal.proj_creation_date;
        modal2.proj_completion_date = modal.proj_completion_date;
        modal2.proj_lasttaskdate = modal.proj_lasttaskdate;

        let formData = new FormData();
        for (const [key, value] of Object.entries(modal2)) {            
            formData.append("Project["+key+"]",value);
        }
      
        formData.append('token',state.UserState.token);

        selManagerList.forEach((r)=>{
            formData.append('managers[]',r.id);
        })
        selAssociateList.forEach((r)=>{
            formData.append('associates[]',r.id);
        })
        
        dispatch({type: "GS/LOADING_START"});
        axios.post(ApiBaseUrl+"/admin/project/save",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).then((r)=>r.data).then((r)=>{  
                  
            if(AjaxValidate(r,setError)){
                setDialogState({open:false, title:""});
                Loadlist();                
            }
            dispatch({type: "GS/LOADING_STOP"});
        }).catch((err)=>{
            dispatch({type: "GS/LOADING_STOP"});
        });
 
    };

    /*

    const totalCost = (projectCosts) => {
        if(projectCosts === null){
            return 0;
        }
        let total = 0;
        projectCosts.forEach((r)=>{
            total += parseFloat(r.cost_amount);
        });
        return total;
    }*/
 
    const DeleteAttachedFile = (e) => {
        e.preventDefault();
        let answer =  window.confirm("Are you sure?");
        if(answer){
            apiPost("/admin/project/delete-attached-file",{proj_id:modal.proj_id},(r)=>{
                Get(modal.proj_id);
            });
        }
    }
    
    return <>
        <AdminLayout title="Project Manager">            
  
        <B.Button variant="primary mb-2" size="lg" onClick={()=>New()}>New</B.Button>
             
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="8">
                                <div className="float-right">
                                    <input type="search" name="search" value={filters.search} placeholder="Search..."
                                    onInput={(e)=>setFilter({...filters,search:e.target.value})} className="form-control" />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="action-col">Actions</th>
                            <th>Project Title</th>
                            <th>Type</th>                            
                            <th>Hard/Soft</th>
                            <th>Cost</th>
                            <th>Creation Date</th>
                            <th>Completion Date</th>
                            <th>Task Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr>
                            <td> 
                            <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                <B.Dropdown.Item  
                                    onClick={(e)=>Get(r.proj_id)} href="#">Edit</B.Dropdown.Item>
                                <B.Dropdown.Item 
                                    onClick={(e)=>window.open(ApiBaseUrl+"/admin/project-task/report?by=project&proj_id="+r.proj_id+"&token="+state.UserState.token)} href="#">Report</B.Dropdown.Item>                            
                                <B.Dropdown.Item  
                                    onClick={() => setFileDialog({open:true, id:r.proj_id,title:"Files for "+r.proj_title}) } href="#">Files</B.Dropdown.Item>
                                <B.Dropdown.Item  
                                    onClick={(e) => setCsDialog({open:true, id:r.proj_id, title:"Cost Breakdown for "+r.proj_title})} href="#">Cost Breakdown</B.Dropdown.Item>
                                <B.Dropdown.Item  
                                    onClick={(e) => setHisDialog({open:true, id:r.proj_id, title:"Project History - "+r.proj_title})} href="#">History</B.Dropdown.Item>
                                <B.Dropdown.Item  
                                    onClick={() => setPrDialog({open:true, id:r.proj_id,title:"Print Orders for "+r.proj_title}) } href="#">Print Orders</B.Dropdown.Item>
                                { r.proj_type === "Question Bank" ? <B.Dropdown.Item  
                                    onClick={() =>  setProjectQB({open:true, id:r.proj_id, title:r.proj_title, random:Math.random()})  } href="#">Question Bank Details</B.Dropdown.Item> : ""}
                                
                                <B.Dropdown.Item 
                                onClick={(e)=>{ 
                                    e.preventDefault();
                                    setConfirmDialogState({open:true, id:r.proj_id})                                    
                                    }
                                } 
                                className="text-danger link-delete" href="#">Delete</B.Dropdown.Item> 
                                
                            </B.DropdownButton>


                            </td>
                            <td>{r.proj_title}</td>
                            <td>{r.proj_type}</td>
                            <td>{r.proj_type2}</td>
                            <td>{r.proj_cost}</td>
                            <td>{moment(r.proj_creation_date).format("DD MMM YYYY")}</td>
                            <td>{moment(r.proj_completion_date).format("DD MMM YYYY")}</td>                       
                            <td>{moment(r.proj_lasttaskdate).format("DD MMM YYYY")}</td>
                        </tr>   
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="7">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
            <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
            
            <NormalDialog onClose={(e)=>{ setCsDialog({...csDialog, open:false}); Loadlist(); }} title={csDialog.title} open={csDialog.open}>
                 {csDialog.open ? <ProjectCost proj_id={csDialog.id} /> : "" }  
                  </NormalDialog>
            <NormalDialog style={{width:500}} onClose={(e)=>{ setPrDialog({...prDialog, open:false}); Loadlist(); }} title={prDialog.title} open={prDialog.open}>
                 {prDialog.open ? <PrintOrder proj_id={prDialog.id} /> : "" } 
            </NormalDialog>

            <NormalDialog onClose={(e)=>{ setFileDialog({...fileDialog, open:false}); }} title={fileDialog.title} open={fileDialog.open}>
                  {fileDialog.open ? <ProjectFile proj_id={fileDialog.id} /> : "" } 
            </NormalDialog>

            <NormalDialog onClose={(e)=>{ setHisDialog({...hisDialog, open:false}); }} title={hisDialog.title} open={hisDialog.open}>
                  {hisDialog.open ? <ProjectHistory proj_id={hisDialog.id} /> : "" } 
            </NormalDialog>
         
            <ProjectQB id={projectQB.id} random={projectQB.random}
            onClose={() => setProjectQB({...projectQB, open:false})}  
            open={projectQB.open} title={projectQB.title} />
             
            <EditProject
            
            onClose={(e)=>setDialogState({open:false})}
            onSubmit={(e) => Save(e)}
            open={dialogState.open}
            title={dialogState.title}
            
            DeleteAttachedFile={DeleteAttachedFile}
            managerList={managerList} setManagerList={setManagerList} selManagerList={selManagerList} setSelManagerList={setSelManagerList}
            associateList={associateList} setAssociateList={setAssociateList} selAssociateList={selAssociateList} setSelAssociateList={setSelAssociateList}
           modal={modal} setModal={setModal} error={error} setError={setError} setSelectedFile={setSelectedFile} selectedFile={selectedFile} />

             
        </AdminLayout>
    </>
}


const EditProject = (props) => {
    //let setError = props.setError;
    let setModal = props.setModal;
    let modal = props.modal;
    let error = props.error;
    let selectedFile = props.selectedFile;
    let setSelectedFile = props.setSelectedFile;
    
    let managerList = props.managerList;
    //let setManagerList = props.setManagerList;
    let selManagerList = props.selManagerList;
    let setSelManagerList = props.setSelManagerList;


    let associateList = props.associateList;
    let selAssociateList = props.selAssociateList;
    let setSelAssociateList = props.setSelAssociateList;

    let DeleteAttachedFile = props.DeleteAttachedFile;
   
    return (
        <>
        <SaveDialog onClose={props.onClose} onSubmit={props.onSubmit} open={props.open} title={props.title}>
        <FormControl.FormContextProvider value={{setModal, error, modal}}>
            <FormControl.TextInput name="proj_title" label="Project Title"/>
            <FormControl.TextInput name="proj_cost" label="Cost"/>
            <B.Row>
                <B.Col md={6}>
                        <FormControl.Select name="proj_type" label="Type" options={[
                            {value:"Default",label:"Default"},
                            {value:"Question Bank",label:"Question Bank"},
                        ]} />
                </B.Col>
                <B.Col md={6}>
                        <FormControl.Select name="proj_type2" label="Type 2" options={[
                            {value:"Hard",label:"Hardcopy - Work"},
                            {value:"Soft",label:"Softcopy - Work"},
                        ]} />
                </B.Col>                
            </B.Row>
            
            <B.Row>
                <B.Col md={4}>
                    <FormControl.DateInput name="proj_creation_date" label="Creation Date"/>
                </B.Col>
                <B.Col md={4}>
                    <FormControl.DateInput name="proj_completion_date" label="Completion Date"/>
                </B.Col>
                <B.Col md={4}>
                    <FormControl.DateInput name="proj_lasttaskdate" label="Last Task Date"/>
                </B.Col>
            </B.Row>
            
        </FormControl.FormContextProvider>
       
        <B.Row>
                <B.Col md={12}>

        <div className="form-group">
                <label>Managers</label>
                <Multiselect  placeholder="Select Managers" options={managerList} // Options to display in the dropdown
                selectedValues={selManagerList} // Preselected value to persist in dropdown
                onSelect={(selection)=>{                                        
                    setSelManagerList(selection);
                }}
                onRemove={(selection)=>{                     
                    setSelManagerList(selection);
                }} // Function will trigger on remove event */
                displayValue="name"
                />             
        </div>
        </B.Col>
        </B.Row>
       
 
        <input type="file" id="file" style={{display:'none'}} onChange={(e)=>{
        setModal({...modal, file:e.target.files[0]});
        setSelectedFile(e.target.files[0]);
        }} />
 
        <label htmlFor="file">
            <span className="btn btn-info" type="button">Upload File</span>        
        </label>

        <a target="_blank"  rel="noreferrer" href={ApiBaseUrl+"/uploads/"+modal.proj_file}
        style={  modal.proj_file.trim() === '' ?  {display:'none'}: {}  } className="btn btn-info  ml-1">Download Attached File</a>

        <a target="_blank" href="#" onClick={DeleteAttachedFile}
        style={  modal.proj_file.trim() === '' ?  {display:'none'}: {}  } className="btn btn-danger  ml-1">Delete Attached File</a>

        <div style={{color:'gray'}}>{selectedFile.name}</div>
        <div style={{color:'red'}}>{error.file}</div>
        <input type="hidden" id="proj_id" value={modal.proj_id} />




</SaveDialog>
        </>
    )
}