
let initialState = false

export default function PaymentState(state = initialState, action)   {
    
    switch (action.type) {
        case "PAYMENT/UPDATELIST":
            return !state;
                         
        default:
            return state;
          
    }
    
}