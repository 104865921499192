import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import Edit from './Edit';
import List from './List';
 
import {  Event_onNew   } from './Event';
import EmployeeLayout from '../../../Layouts/EmployeeLayout';
import { apiGet } from '../../../../Lib/Helpers';
 
 
 

export default function QBankFiles(props){
    const [readOnly,setReadOnly] = useState(true);    
    useEffect(()=>{
        apiGet("/manager/qb-bank/get-access",{},(r)=>{
            if(r.data == "Read"){
                setReadOnly(true);
            } else {
                setReadOnly(false);
            }            
        });
    },[]);
    return <> 
        <EmployeeLayout title="Question Bank File Manager">
        {!readOnly ? <Edit /> : "" }
        {!readOnly ? 
            <button onClick={()=>{
                window.dispatchEvent(new CustomEvent(Event_onNew,{}));            
            }} className="btn btn-lg mr-2 btn-primary mb-2">New</button>
        : "" }
        <List readOnly={readOnly} />        
        </EmployeeLayout>
        </>
}