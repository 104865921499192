import { combineReducers, createStore } from "redux";
import UserState from "./UserState";
import GlobalState from "./GlobalState";
 
import PaymentState from "./PaymentState";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = persistReducer(persistConfig, combineReducers({ UserState, GlobalState, PaymentState }));
export let store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export let persistor = persistStore(store) 

/*

const reducers = combineReducers({ UserState });
export const store = createStore(
  reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
 
);



export const store = createStore(
  reducers,
+  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
*/