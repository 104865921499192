import * as B from 'react-bootstrap';
import React from 'react';
import { useState, useEffect } from 'react';
import { apiGet } from '../../../Lib/Helpers';
import AdminLayout from '../../Layouts/AdminLayout';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { getStatusColor, StatusList } from '../../../Constants/WorkEntry_StatusList';
import * as FC from '../../../Lib/FormControl';
import { MdPrint } from 'react-icons/md';
import { StatusListWithAll } from './Satus';

import Select from 'react-select'; 

import { filter, reduceRight } from 'underscore';
export default function EmployeeReport(){
    const [filters, setFilter] = useState({
        alotted_to : "",        
        
        status:"",
        dateFilter:"w_completion_date",
        from:moment("01 Jan 2019").format("DD MMM YYYY"),
        to:moment().add(3,"M").format("DD MMM YYYY"),
        page:1,
        perpage:1000000, 
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const [currentEmployee, setCurrentEmployee] = useState("");

    const Loadlist = () => {

        if(
            filters.alotted_to === "" ||
             
            filters.dateFilter === "" 
        ){
             
            return;
        }
        apiGet("/admin/employee/get",{id:filters.alotted_to},(r)=>{                  
            setCurrentEmployee(r.data.emp_fullname);
        })
        apiGet("/admin/work-entry/loadlist",filters,(r)=>{      
            //console.log(r.data.records);      
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    useEffect(()=>{
        Loadlist();
    },[filters]);

    let dateFilterList = [
 
        {value:"w_completion_date", label:"Completion Date"},
        {value:"w_estimated_date", label:"Estimated Date"},
        {value:"w_lastupdated_on", label:"Last Updated Date"},
    ]

    const getDateFilterName = (key) => {
        let list = dateFilterList.map((r)=>{
            if(r.value === key){
                return r.label;
            }
        });
        return list[0];
    }
    let [empList, setEmpList] = useState([]);

    useEffect(()=>{
        apiGet("/admin/employee/listall",{},(r)=>{
            let list = r.data.records.map((r)=>{
                return {
                    value:r.emp_id, label:r.emp_uid + " - " +r.emp_fullname
                }
            });
            list.push({
                value:"", label:"-- Select Associate --"
            });
            setEmpList(list);
        })
    },[])
     let n = ( filters.page * filters.perpage ) - filters.perpage;

 
     let totalAmount = 0; // Initialize a variable to hold the sum
     listData.records.forEach(r => {
        totalAmount += parseFloat(r.w_amount); // Make sure to parse the amount as a float before adding
      });

    return <> 
    <AdminLayout title="Employee Work Report">            
               <div className="text-center d-none d-print-block">
                <h3>Associate Name: {currentEmployee}</h3>
                <h4>{getDateFilterName(filters.dateFilter)} - From: {filters.from} - To: {filters.to}</h4>
                <h4>For Status: {filters.status == "" ? "All" : filters.status}</h4>
            </div>

             <div className="table-responsive">
                <table className="table">
                <thead> 
                    <tr>
               <th colSpan={11}>
                <div class="d-print-none">
                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}>
                    <div className="form-row float-left">
                        <div className="col">
                            {/* <FC.Select name="alotted_to" label={"Associate"} options={empList} /> */}
                            <label htmlFor="Associate">Associate</label>
                            <Select
                                name="alotted_to"
                                options={empList}
                                isSearchable
                                placeholder="-- Select Associate --"
                                value={empList.find((option) => option.value === filters.alotted_to)}
                                onChange={(selectedOption) => setFilter({ ...filters, alotted_to: selectedOption.value })}
                            />
              
                        </div>
                        <div className="col">
                            <FC.Select name="status" label={"Status"} options={StatusListWithAll} />
                        </div>
                        <div className="col">
                            <FC.Select name="dateFilter" label={"Date Filter"} options={dateFilterList} />
                        </div>                                
                        <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                            <FC.DateInput name="from" label={"From"} />
                        </div>
                        <div className={filters.dateFilter === "" ? "d-none" : "col"}>
                            <FC.DateInput name="to" label={"To"} />
                        </div>
                        <div className="col">
                            <label>&nbsp;</label><br />
                            <button onClick={()=> window.print()} type="button" className="btn btn-primary">Print</button>
                        </div>
                    </div>
                </FC.FormContextProvider>
            </div>
                 </th>
                  </tr>
                     <tr>
                        <th>S.No.</th>
                        <th>Project Title</th>
                        <th>Cost Type</th>                            
                        <th>Description</th>
                        <th>Rate</th>
                        <th>Qty</th>
                        <th>Amount</th>
                        <th>Alotted By</th>
                        <th>Alotted To</th> 
                        <th>Completion Date</th> 
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                
                {listData.records.map(r => { n++;
                
                return (
                    <tr key={r.w_id}>
                        <td>{n}</td>
                        <td>{r.project.proj_title}</td>
                        <td>{r.cost.cost_title}</td>
                        <td>{r.w_description}</td>
                        <th>{r.w_rate}</th>
                        <th>{r.w_qty}</th>
                        <th>{r.w_amount}</th>
                        <td>{r.alottedBy.emp_fullname}</td>
                        <td>{r.alottedTo.emp_fullname}</td> 
                        <td>{moment(r.w_completion_date).format("DD MMM YYYY")}</td>   
                        <td> <strong>{r.w_status} </strong>
                        </td>
                    </tr>   
                ) } )}
                <tr>
              <td colSpan="6">Total Amount:</td>
              <td colSpan="4"> 
              
            {totalAmount}
               </td>
               </tr>
                </tbody>
                <tfoot className="d-none">
                    <tr>
                        <td colSpan="10">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={10}
                                totalItemsCount={listData.total}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </AdminLayout>
    </>
}