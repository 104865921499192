export const Event_onNew = "WorkEntry_Event_onNew";
export const Event_onEdit = "WorkEntry_Event_onEdit";
export const Event_onAfterSave = "WorkEntry_Event_onAfterSave";

export const Event_onNewFile = "WorkEntry_Event_onNewFile";
export const Event_onEditFile = "WorkEntry_Event_onEditFile";
export const Event_onAfterSaveFile = "WorkEntry_Event_onAfterSaveFile";

export const Event_onAfterFileLinking = "WorkEntry_Event_onAfterFileLinking";

export const Event_onOpenTransferDialog = "WorkEntry_Event_onOpenTransferDialog";