import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as FC from '../../../../../Lib/FormControl';

import {apiGet, apiPost} from '../../../../../Lib/Helpers';
import Pagination from 'react-js-pagination';
import { Events } from '../Constants';
import * as B from 'react-bootstrap';
import Confirmation from '../../../../../Lib/Confirmation';
export default function List(props){
    const [filters, setFilter] = useState({
        from : moment("01 Jan 2019").format("DD MMM YYYY"),
        to : moment().format("DD MMM YYYY"),
        emp_id: props.emp_id,
        leave_type:"",
        page:1,
        perpage:10,
    })

    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
     
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    useEffect(() => {
        Loadlist();
    },[filters]);

    const Delete = () => {
        apiPost("/admin/leave/account-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    }

    const Loadlist = () =>{
        apiGet("/admin/leave/account-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
        apiGet("/admin/leave/account-get-balance",{
            emp_id:props.emp_id,
            from:filters.from,
            to:filters.to
        },(r)=> setAccountBalance(r.data));
    };

    const leaveTypes = [
        {value:"",label:"--Select Leave Type--"},
        {value:"Paid",label:"Paid"},
        {value:"Casual",label:"Casual"},
        {value:"Special",label:"Special"},
    ];

    const [accountBalance, setAccountBalance] = useState({"Casual":0,"Special":0,"Paid":0,"Casual_Duration":0,"Special_Duration":0,"Paid_Duration":0}); 
    useEffect(()=>{
        window.addEventListener(Events.onAfterSave,Loadlist);  
        
        Loadlist();
        
        return () => {
            window.removeEventListener(Events.onAfterSave,Loadlist);            
        }        
    },[]);

    


    return <>
    <Confirmation open={confirmDialogState.open} text={confirmDialogState.title} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
    <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="8">
                                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                                <div className="row">
                                    <div className="col">                                
                                        <FC.DateInput name="from" label="From" />
                                    </div>
                                    <div className="col">                                
                                        <FC.DateInput name="to" label="To" />
                                    </div>
                                    <div className="col">
                                        <FC.Select name="leave_type" label={"Leave Type"} options={leaveTypes} />
                                    </div>                                    
                                </div>
                                </FC.FormContextProvider>
                            </th>
                        </tr>
                        <tr>
                            <th style={{width:120}}>Actions</th>
                            <th style={{width:100}}>TXN ID</th>
                            <th style={{width:140}}>Date</th>
                            <th style={{width:120}}>Credit/Debit</th>                        
                            <th style={{width:120}}>Leave Type</th>
                            <th style={{width:120}}>Amount</th>                                                    
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => (
                        <tr key={r.txn_id}>
                            <td>
                                <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                    <B.Dropdown.Item  
                                        onClick={(e) => window.dispatchEvent(new CustomEvent(Events.onEdit,{detail:r.txn_id}))  } 
                                        href="#">Edit</B.Dropdown.Item>
                                    <B.Dropdown.Item 
                                    onClick={(e)=>{ 
                                        e.preventDefault();
                                        setConfirmDialogState({open:true, id:r.txn_id, title:"Are you sure want to DELETE?"})                                    
                                        }
                                    } className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>

                                </B.DropdownButton>
                            </td> 
                            <td>{r.txn_id}</td>
                            <td>{moment(r.txn_date).format("DD MMM YYYY")}</td>
                            <td>{r.txn_type}</td>
                            <td>{r.txn_leave_type}</td>
                            <td>{r.txn_type === "Debit" ? "-" : "+"}{r.txn_amount}</td>
                            <td>{r.txn_comment}</td>  
                        </tr>
                    ))}        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="8">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="7">
                                <div className="row">
                                    <div className="col-md-3">
                                        <strong>OVERALL BALANCE</strong>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Casual: </label> {accountBalance.Casual}
                                    </div>
                                    <div className="col-md-3">
                                        <label>Paid: </label> {accountBalance.Paid}
                                    </div>
                                    <div className="col-md-3">
                                        <label>Special: </label> {accountBalance.Special}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <strong>BALANCE IN DURATION</strong>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Casual: </label> {accountBalance.Casual_Duration}
                                    </div>
                                    <div className="col-md-3">
                                        <label>Paid: </label> {accountBalance.Paid_Duration}
                                    </div>
                                    <div className="col-md-3">
                                        <label>Special: </label> {accountBalance.Special_Duration}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>                    
            </div>    
    </>
}