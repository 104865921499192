import React, { useContext } from 'react';
 
 
import DatePicker from 'react-datepicker';
import moment from 'moment';
 
 
import Finder from './Finder';
 
import MultiDatePicker from 'react-multi-date-picker';
import { Calendar } from 'react-multi-date-picker';


export const FormContext = React.createContext();
export const FormContextProvider = (props) => {
    return <FormContext.Provider value={props}>
        {props.children}
    </FormContext.Provider>
}

export function TextInput({type='text', name, label=false, ...props}) {
    let {modal, error, setModal} = useContext(FormContext).value;  
    return <div className="form-group">
        {label!==false ? <label>{label}</label> : ''}
        <input type={type} value={modal[name]} onChange={(e) => setModal({...modal, [name]:e.target.value}) } className="form-control" />
        <div className="text-danger">{error[name]}</div>
    </div>
}

export function Textarea({name, label=false, ...props}) {
    let {modal, error, setModal} = useContext(FormContext).value;  
    
    return <div className="form-group">
        {label!==false ? <label>{label}</label> : ''}
        <textarea value={modal[name]} onChange={(e) => setModal({...modal, [name]:e.target.value}) } className="form-control" />
        <div className="text-danger">{error[name]}</div>
    </div>
}

export function DateInput({type='text', name, label=false, ...props}) {
    let {modal, error, setModal} = useContext(FormContext).value;  
    
    return <div className="form-group">
        {label!==false ? <label>{label}</label> : ''} 
        <DatePicker dateFormat="dd MMM yyyy"  value={modal[name]} className="form-control" onChange={(date) => { 
            setModal({...modal, [name]:moment(date).format("DD MMM YYYY")})  
        } } {...props} />
        <div className="text-danger" {...props}>{error[name]}</div>
    </div>
}

export function MultiDateInput({  name, label=false, type="calendar", ...props}) {
    let {modal, error, setModal} = useContext(FormContext).value;  
    if(type==="calendar"){
        return <div className="form-group">
            {label!==false ? <label>{label}</label> : ''} 
            <Calendar format="DD MMM YYYY" value={modal[name]}   multiple 
            
            onChange={(values) => {  
                let dates = values.map((r)=>{
                    return r.day+" "+r.month.shortName+" "+r.year ;
                }); 
                setModal({...modal, [name]:dates})  
            }} {...props} type={type}  
            />        
            <div className="text-danger">{error[name]}</div>
        </div>    
    }
    return <div className="form-group">
        {label!==false ? <label>{label}</label> : ''} 
        <MultiDatePicker format="DD MMM YYYY" value={modal[name]}   multiple 
        
        onChange={(values) => {  
            let dates = values.map((r)=>{
                return r.day+" "+r.month.shortName+" "+r.year ;
            }); 
            setModal({...modal, [name]:dates})  
        }} {...props} type={type} 
        />        
        <div className="text-danger">{error[name]}</div>
    </div>
}

export function Select({options = [], name, multiple=false, label=false,  ...props}) {
    let {modal, error, setModal} = useContext(FormContext).value;
    
    

    let optionList = options.map((r)=>{
        return <option key={r.value} value={r.value}>{r.label}</option>
    })
     
    return <div className="form-group">
        {label!==false ? <label>{label}</label> : ''}
        <select multiple={multiple} value={modal[name]} 
            onChange={(e) => setModal({...modal, [name]:e.target.value}) } className="form-control">
            {optionList}
        </select>
        <div className="text-danger">{error[name]}</div>
    </div>
}
/*
export function AutoFillRemote({jsonSource,name,label=false, ...props}) {
    let {modal, error, setModal} = useContext(FormContext).value;
    const [value, setValue] = useState("");
    const [suggestions,setSuggestions] = useState([]);



    const onSuggestionsFetchRequested = (value) => {
        setSuggestions(getSuggestions(value));
    }
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    }
    const getSuggestionValue = (suggestion) => {
        return suggestion.label;
    }
    const renderSuggestion = (suggestion) => {
        <div>{suggestion.label}</div>
    }    
    const onChange = (newValue) =>{
        setValue(newValue);
    }


    const getSuggestions = (value) => {
        console.log(value);
    }

    return <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
            placeholder: label,
            value: modal.value,
            onChange: onChange
        }}
    />
}

*/

export function AutoFillRemote({jsonSource, 
    initialInputText='',    
    valueField='value',
    labelField='label',
    label=false, 
    onSelectOption,
    name, ...props}){
    let {modal, error, setModal} = useContext(FormContext).value;
    const setValue = (r) => { 
        setModal({...modal, [name]:r[valueField]});
    }
  
    if(initialInputText.trim() !== ""){
        return <>
        <div className="form-group">
            
            {label!==false ? <label>{label}</label> : ''}
            <div>{initialInputText}</div>           
            <div className="text-danger">{error[name]}</div>
        </div>
        </>
    }
    
    return <>
    <div className="form-group">
        
        {label!==false ? <label>{label}</label> : ''}
        <Finder onSelectOption={onSelectOption} remoteUrl={jsonSource} setValue={setValue} initialInputText={initialInputText} {...props} valueField={valueField} labelField={labelField} open={true} />
        <div className="text-danger">{error[name]}</div>
    </div>
    </>
}