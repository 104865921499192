import React, { useEffect, useState } from 'react';

import * as B from 'react-bootstrap';
import { FaTasks, FaUsers, FaUmbrellaBeach,FaUsersCog } from 'react-icons/fa';
import { GoProject } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { getStatusColor as workEntryGetStatusColor } from '../../../Constants/WorkEntry_StatusList';
import { getStatusColor as leaveRequestGetStatusColor } from '../../../Constants/Leave';

import { apiGet } from '../../../Lib/Helpers';
import AdminLayout from '../../Layouts/AdminLayout';
import moment from 'moment';
export default function Main(){
    const [dashData, setDashData] = useState({
        count:{
            projects:0,
            employees:0,
            project_tasks:0,
            work_entries:0,
            leave_requests:0
        },
        list:{
            work_entries:[],
            leave_requests:[],
            qbank_files:[]
        }
    })

    

   



    const loadData = () => {
        apiGet("/admin/default/dashboard",{},(r)=>{
            setDashData(r.data);
        });
    }
    useEffect(()=>{
        loadData();
    },[])
    return <AdminLayout title="Dashboard">
        

       
        <B.Row>
            <B.Col md={4}>
                <_WorkEntries records={dashData.list.work_entries} />
            </B.Col>
            <B.Col md={4}>
                <_LeaveRequests records={dashData.list.leave_requests} />
            </B.Col>
            <B.Col md={4}>
                <_QBankFiles records={dashData.list.qbank_files} />
            </B.Col>
        </B.Row>
    </AdminLayout>
}

function _QBankFiles({records, ...props}){
    return <>
        <Card title="Latest Uploads in Question Bank" link={"/admin/question-bank/files"}>
        <table className="table">
            <thead>
                <tr>
                    <th>File</th>
                    <th>Employee</th>                    
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
            {records.map(r =>         
                <tr>
                    <td>
                        {r.file_title}<br />
                        <small>{r.file_path}</small>
                    </td>
                    <td>
                        {r.employee.emp_fullname}                        
                    </td>
                    <td>
                        {moment(r.file_updated_on).format("DD MMM YYYY")}
                    </td>
                                    </tr>
            )}
            </tbody>
        </table>
        </Card>
    </>
}

function _LeaveRequests({records, ...props}){
    return <>
        <Card title="Pending Leave Requests" link={"/admin/leave/request"}>
        <table className="table">
            <thead>
                <tr>
                    <th>Employee</th>
                    <th>Last Updated On</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            {records.map(r =>         
                <tr>
                    <td>
                        {r.employee.emp_fullname}                        
                    </td>
                    <td>
                        {moment(r.rq_updated_on).format("DD MMM YYYY")}
                    </td>
                    <td>
                        <strong className={"text-"+leaveRequestGetStatusColor(r.rq_status)}>{r.rq_status}</strong>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        </Card>
    </>
}

function _WorkEntries({records,  ...props}){
    
    return <>
        <Card title="Latest Work Entries" link={"/admin/work-entry"}>
        <table className="table">
            <thead>
                <tr>
                    <th>Details</th>
                    <th>Last Updated On</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            {records.map(r =>         
                <tr>
                    <td>
                        {r.project.proj_title}
                        <br />
                        <small>{r.w_description}</small>
                    </td>
                    <td>
                        {moment(r.w_lastupdated_on).format("DD MMM YYYY")}
                    </td>
                    <td>
                        <strong className={"text-"+workEntryGetStatusColor(r.w_status)}>{r.w_status}</strong>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        </Card>
    </>
}

function Card({title, link, ...props}){
    return <>
        <div className="card border border-primary">
            <div className="card-header bg-primary text-white">
                <strong>{title}</strong>
            </div>
            {props.children}
            <div className="card-footer text-right">
                <Link className="btn btn-primary btn-sm" to={link}>More...</Link>                
            </div>
        </div>
    </>
}

function Box({title, count, icon = '', ...props}){
    return <>
        <div className="bg-primary text-white p-3 mb-2">
            <h3>{title}</h3>
            <h3 className="display-4">
                <div style={{  
                        marginRight:10,
                        paddingTop:16,
                        paddingBottom:16,
                        paddingLeft:20,
                        paddingRight:20,
                        display: 'inline-block',
                        textAlign: 'center',
                        backgroundColor:'rgba(0,0,0,0.2)',
                        borderRadius: '50%'                      
                }}>{icon}</div>
                {count}</h3>            
        </div>
    </>
}