import React, {   } from 'react';
import AdminLayout from '../../../Layouts/AdminLayout';

import EmployeeList from './EmployeeList';
import TransactionMain from './Transaction/Main';

export default function Main(){

    return <>
    <AdminLayout title="Employee Leave Accounts">
    <TransactionMain />
        <EmployeeList />
    </AdminLayout>    
    </>
}