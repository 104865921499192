import * as B from 'react-bootstrap';
import React from 'react';
import { useState, useEffect } from 'react';
import { apiGet } from '../../../Lib/Helpers';
import AdminLayout from '../../Layouts/AdminLayout';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { getStatusColor, StatusList } from '../../../Constants/WorkEntry_StatusList';
import * as FC from '../../../Lib/FormControl';
import { MdPrint } from 'react-icons/md';
export default function StatusReport(){
    const [filters, setFilter] = useState({
        search : "",
        order:"proj_id DESC",
        page:1,
        perpage:10, 
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
 

    const Loadlist = () => {
        apiGet("/admin/work-entry/project-status-report",filters,(r)=>{      
            //console.log(r.data.records);      
             setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    useEffect(()=>{
        Loadlist();
    },[filters]);

    let title = "Project Wise Work Status Report  <span class='d-none d-print-inline'>| Page - "+filters.page+"</span>";
 if(filters.search.trim() !== ""){
    title = "Project Wise Work Status Report <span class='d-none d-print-inline'>| Search: '"+filters.search+"' Page - "+filters.page+"</span>";
 }
    return <> 
    <AdminLayout title={title}>            
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr className="d-print-none">
                        <th colSpan="8">
                            
                            <div className="form-row float-left">
                                <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                                    <div className="col">
                                        <FC.Select name="order" label={"Order"} options={[
                                            {"label":"Project ID Desc","value":"proj_id DESC"},
                                            {"label":"Project ID Asc","value":"proj_id ASC"},
                                            {"label":"Project Title Desc","value":"proj_title DESC"},
                                            {"label":"Project Title Asc","value":"proj_title ASC"},
                                            {"label":"Project Status Asc","value":"work_count_completed ASC"},
                                            {"label":"Project Status Desc","value":"work_count_completed DESC"},
                                        ]} />
                                    </div>                     
                                    <div className="col">
                                        <FC.TextInput name="search" label={"Search"} />
                                    </div> 
                                    <div className="col">
                                        <FC.Select name="perpage" label={"Per Page"} options={[
                                            
                                            {"label":"10","value":"10"},
                                            {"label":"20","value":"20"},
                                            {"label":"50","value":"50"},
                                            {"label":"100","value":"100"},
                                            {"label":"200","value":"200"},
                                            {"label":"500","value":"500"},
                                        ]} />
                                    </div>
                                    <div className="col">
                                        <label>&nbsp;</label><br />
                                        <button onClick={()=> window.print()} type="button" className="btn btn-primary">Print</button>
                                    </div>
                                </FC.FormContextProvider>
                                
                         
                            </div>
                        </th>
                    </tr>
                     
                    <tr className=" d-table-row"> 
                        <th>Project Title</th>
                        {StatusList.map((r2)=>{
                                return <th><strong>{r2.value}</strong></th>
                            })}
                    </tr>
                </thead>
                <tbody>
                {listData.records.map(r => {
 
                    let total = parseInt(r.work_count_completed) + parseInt(r.work_count_pending);
                   
                    let utilization = 100 * r.work_count_completed / total ;
                    let variant = "secondary";
                    if(utilization >=80){
                        variant = "success";
                    } else if(utilization >=60){
                        variant = "primary";
                    } else if(utilization >=40){
                        variant = "warning";
                    } else if(utilization >=0){ 
                        variant = "danger";
                    }
                    return  <>
                    <tr> 
                        <td rowSpan={2}>{r.proj_title}
                        <br />
                        {r.proj_type} - {r.proj_type2} - {r.proj_cost}
                        </td>

                        {StatusList.map((r2)=>{
                                return <td>
                                     <strong class=" ">  { r.status[r2.value] ? r.status[r2.value] : 0 }</strong>
                                    </td>
                            })}

                          
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <span  className="d-block d-print-none">
                            <B.ProgressBar variant={variant} now={utilization} />  
                            </span>
                            <span  className="d-none d-print-block">
                            <B.ProgressBar variant={"dark"} now={utilization}  />  
                            </span>
                        </td>
                        <td>
                            <span className="d-block d-print-none">
                                <strong className={"text-"+variant}>{utilization.toFixed(2)}%</strong>
                            </span>
                            <span  className="d-none d-print-block">
                                <strong className={"text-dark"}>{utilization.toFixed(2)}%</strong>
                            </span>
                            
                        </td>
                    </tr>   
                    </> 
                })}
                </tbody>
                <tfoot class="d-print-none">
                    <tr>
                        <td colSpan="8">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={filters.page}
                                itemsCountPerPage={filters.perpage}
                                totalItemsCount={listData.total}
                                pageRangeDisplayed={5}
                                onChange={page => {
                                    setFilter({...filters, page})
                                }}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </AdminLayout>
    </>
}