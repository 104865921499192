import React from 'react';
 


import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../Config';
 
import Pagination from "react-js-pagination";
 
import { Multiselect } from 'multiselect-react-dropdown';
import { Event_onAfterFileLinking, Event_onAfterSaveFile, Event_onAfterStatusChange, Event_onEdit, Event_onEditFile, Event_onNewFile } from './Events';
 
import { getStatusColor, StatusList, StatusListWithAll } from '../../../Constants/WorkEntry_StatusList';
import NormalDialog from '../../../Lib/NormalDialog';
export default function Files_List(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
     const [filters, setFilter] = useState({
        search : "",
        w_id : props.w_id,
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    
    const Delete = () => {
        apiPost("/associate/work-entry/file-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    }

     

    const Loadlist = () => {
                 
        if(filters.w_id == 0 || filters.w_id == null || filters.w_id == undefined || filters.w_id == false){
            return;
        }
        apiGet("/associate/work-entry/file-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    const Loadlist_onStatusChange = (e) => {
        let id = e.detail;
       
        setFilter({...filters, w_id:id});
        Loadlist();
    }

    useEffect(()=>{
        window.addEventListener(Event_onAfterSaveFile,Loadlist_onStatusChange);
        window.addEventListener(Event_onAfterStatusChange,Loadlist_onStatusChange);
        window.addEventListener(Event_onEdit,Loadlist_onStatusChange);
        Loadlist();
        return () => {
            window.removeEventListener(Event_onAfterSaveFile,Loadlist_onStatusChange);
            window.removeEventListener(Event_onAfterStatusChange,Loadlist_onStatusChange);
            window.removeEventListener(Event_onEdit,Loadlist_onStatusChange);
        }        
    },[]);


    
    useEffect(()=>{        
        Loadlist();
    },[filters]);

   
  
    return <> 
    <Confirmation open={confirmDialogState.open} text={confirmDialogState.title} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
        
    <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="3">
                        <div className="float-left">
                        
                        {!props.readonly ?    
                        <button onClick={()=>{
                            window.dispatchEvent(new CustomEvent(Event_onNewFile,{}));            
                        }} className="btn mr-2 btn-primary mb-2">Upload New File</button>
                        : ""}

                        </div>
                        <div className="float-right form-row">
                            <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                            <div className="col">
                                <FC.TextInput label={false} name="search"  />
                            </div> 
                            </FC.FormContextProvider>
                        </div>
                    </th>                
                </tr>
                <tr>
                    <th style={{width:120}}>Actions</th>
                    <th>Title</th>
                    
                    <th>Employee</th>
                </tr>
            </thead>
            <tbody>
            {listData.records.map(r => (
                <tr key={r.f_id}>
                <td> 
                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                    
                   

                    {   r.readonly === false ?
                        <B.Dropdown.Item  
                            onClick={(e)=>{
                                window.dispatchEvent(new CustomEvent(Event_onEditFile, { detail: r.f_id }));
                            }} href="#">Edit</B.Dropdown.Item>
                        : ""}

                        <B.Dropdown.Item 
                        onClick={(e)=>{ 
                            e.preventDefault();
                            window.open(ApiBaseUrl+"/uploads/"+r.f_path);                            
                            }
                        } href="#">Download</B.Dropdown.Item>

                        {  r.readonly === false ?
                        <B.Dropdown.Item 
                        onClick={(e)=>{ 
                            e.preventDefault();
                            setConfirmDialogState({open:true, id:r.f_id, title:"Are you sure want to <strong class='text-danger'>DELETE</strong> this file?"})                                    
                            }
                        } className="text-danger" href="#">Delete</B.Dropdown.Item>
                        : ""}

                    </B.DropdownButton>
                </td>

                <td>{r.f_title}<br />
                <small>UP: {moment(r.f_uploaded_on).format("DD MMM YYYY h:mm a")} </small>
                <small>MD: {moment(r.f_modified_on).format("DD MMM YYYY h:mm a")} </small>
                </td>               
                                    
                <td>{r.employee.emp_fullname}</td> 
            </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
        </>
}