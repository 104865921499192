import config from '../package.json';

//export const ApiBaseUrl = "http://projects.nginx/ghatna/pms";
export const ApiBaseUrl = config.apiBaseUrl; 

export const PushNotification = {
    publicKey:config.pushNotification.publicKey
}

// "https://demos.abhinavsoftware.com/pms/api";

export const monthArray = [
{id:"01",title:"Jan"},
{id:"02",title:"Feb"},
{id:"03",title:"Mar"},
{id:"04",title:"Apr"},
{id:"05",title:"May"},
{id:"06",title:"Jun"},
{id:"07",title:"Jul"},
{id:"08",title:"Aug"},
{id:"09",title:"Sep"},
{id:"10",title:"Oct"},
{id:"11",title:"Nov"},
{id:"12",title:"Dec"},
];


export const yearArray = [2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035];

export const paymentModeArray = [
    {id:"Net Banking",title:"Net Banking"},
    {id:"Cheque",title:"Cheque"},
    {id:"Cash",title:"Cash"},

]