import React from 'react';
 


import { useState, useEffect } from 'react';
import {    apiGet, apiPost } from '../../../../Lib/Helpers';
 
 
import * as B from 'react-bootstrap';
import * as FC from '../../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../../Lib/Confirmation';
 
 
 
import Pagination from "react-js-pagination";
 
 import { Events } from './Constants';


export default function List(props){

 
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
     
    const [filters, setFilter] = useState({
        search : "",
        year : "",
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    
    const Delete = () => {
        apiPost("/admin/leave/holiday-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    }
 

    const Loadlist = () => {
          
        apiGet("/admin/leave/holiday-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    useEffect(()=>{
        window.addEventListener(Events.onAfterSave,Loadlist);        
        Loadlist();
        return () => {
            window.removeEventListener(Events.onAfterSave,Loadlist);            
        }        
    },[]);
    
    useEffect(()=>{
        Loadlist();
    },[filters]);

    let yearList = [
        {label:"-- Select Year --", value:""},
        {label:2021, value:2021},
        {label:2022, value:2022},
        {label:2023, value:2023},
        {label:2024, value:2024},
        {label:2025, value:2025},
        {label:2026, value:2026},
        {label:2027, value:2027},
        {label:2028, value:2028},
        {label:2029, value:2029},
        {label:2030, value:2030},
        {label:2031, value:2031},
        {label:2032, value:2032},
        {label:2033, value:2033},
        {label:2034, value:2034},
        {label:2035, value:2035},
    ];
     
    return <> 
    <Confirmation open={confirmDialogState.open} text={confirmDialogState.title} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
         
    <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="3">
                        <div className="float-right form-row">
                            <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                            <div className="col">
                                <FC.Select name="year" label={"Year"} options={yearList} />
                            </div>
                            <div className="col">                                
                                <FC.TextInput name="search" label={"Search"} />
                            </div> 
                            </FC.FormContextProvider>
                        </div>
                    </th>                
                </tr>
                <tr>
                    <th style={{width:120}}>Actions</th>
                    <th style={{width:200}}>Date</th>
                    <th>Title</th>                     
                </tr>
            </thead>
            <tbody>
            {listData.records.map(r => (
                <tr>
                <td> 
                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                        
                        <B.Dropdown.Item  
                            onClick={(e)=>{
                                window.dispatchEvent(new CustomEvent(Events.onEdit, { detail: r.dt_id }));
                            }} href="#">Edit</B.Dropdown.Item>
                         

                        <B.Dropdown.Item 
                        onClick={(e)=>{ 
                            e.preventDefault();
                            setConfirmDialogState({open:true, id:r.dt_id, title:"Are you sure want to DELETE?"})                                    
                            }
                        } className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>
                                                    
                    </B.DropdownButton>
                </td>
                <td>{moment(r.dt_date).format("DD MMM YYYY")}</td>        
                <td>{r.dt_title}</td>              
                
                
            </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
        </>
}