import React from 'react';
 


import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../Config';
 
import Pagination from "react-js-pagination";
 
import { Multiselect } from 'multiselect-react-dropdown';
import { Event_onAfterFileLinking, Event_onAfterSaveFile, Event_onEditFile } from './Events';
 
import NormalDialog from '../../../Lib/NormalDialog';
export default function Importer(props){
    const [items, setItems] = useState([]);
    const state = useSelector(state => state);
    const dispatch = useDispatch();
     const [filters, setFilter] = useState({
        search : "",
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

     

    const Loadlist = () => { 
        apiGet("/manager/work-entry/file-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }
 
    
    useEffect(()=>{
        Loadlist();
    },[filters]);

    useEffect(()=>{
        Loadlist();
    },[]);

    const LinkSelected = (e) => {
        apiPost("/manager/work-entry/bulk-file-linking",{
            w_id:props.w_id,
            file_ids:items
        },(r)=>{
            alert("Success!");
            window.dispatchEvent(new CustomEvent(Event_onAfterFileLinking));
        })
    }

    
    const SelectItem = (e) => {
        let id = e.target.value;
        let nitems = items;
        

        if(!items.includes(id)){            
            nitems.push(id);            
            
        } else {
            const index = nitems.indexOf(id);
            if (index > -1) {
                nitems.splice(index, 1);
            }
        }
     
        setItems(nitems);
        setListData({
            records:listData.records,
            total:listData.total,
        });
    }
  
    const [selectAll,setSelectAll] = useState(false);

    const doSelectAll = () => {
        let file_ids = []
        listData.records.map((r)=>{
            file_ids.push(r.f_id);
        })
        if(!selectAll){
            setItems(file_ids);
            setSelectAll(true);
        } else {
            setItems([]);
            setSelectAll(false);
        }
        
    }

    return <> 
    <button type="button" onClick={LinkSelected} className="btn btn-lg btn-primary">Link Selected</button>     
    <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    
                    <th colSpan="6">
                        <div className="float-right form-row">
                            <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                            <div className="col">
                                <FC.TextInput name="search" label={"Search"} />
                            </div> 
                            </FC.FormContextProvider>
                        </div>
                    </th>                
                </tr>
                <tr>
                    <th colSpan="6" style={{paddingLeft:0}}>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </th> 
                </tr>
                <tr>
                    <th style={{width:60}}>
                        <input checked={selectAll} type="checkbox" value={1} onClick={()=>doSelectAll()} />
                    </th>
                    <th>File</th>
                    <th>Work</th>                    
                    <th>Uploaded On</th>
                    <th>Modified On</th>
                    <th>Uploaded By</th>
                </tr>
            </thead>
            <tbody>
            {listData.records.map(r => (
                <tr>
                <td> 
                <input checked={items.includes(r.f_id)} type="checkbox" value={r.f_id} onClick={SelectItem} />
                </td>
                <td>{r.f_title}</td>       
                <td>                    
                    <div className="file-link-details">
                    {r.workEntryFileJoin.map((r2)=>{
                        return <p className="mb-1">
                            <strong>{r2.workEntry.project.proj_title}</strong><br />
                            {r2.workEntry.w_description}
                        </p>
                    })}
                    </div>
                </td>
                <td>{moment(r.f_uploaded_on).format("DD MMM YYYY h:mm a")}</td>
                <td>{moment(r.f_modified_on).format("DD MMM YYYY h:mm a")}</td>                       
                <td>{r.employee.emp_fullname}</td> 
            </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="6">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
        </>
}