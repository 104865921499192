import React from 'react';

import * as B from 'react-bootstrap';

import { useState, useEffect } from 'react';

import {   apiGet, apiPost } from '../../../Lib/Helpers';
import Confirmation from '../../../Lib/Confirmation'; 
import SaveDialog from '../../../Lib/SaveDialog';
import { connect, useSelector } from 'react-redux';
import ProjectTask from './ProjectTask';
 
import NormalDialog from '../../../Lib/NormalDialog';
import { ApiBaseUrl } from '../../../Config';
import numeral from 'numeral';
import Pagination from "react-js-pagination";
const ProjectCost = (props) => {

    const state = useSelector(state => state);

    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [filters, setFilter] = useState({
        proj_id:props.proj_id,
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const initialModalState = {
        cost_id:0,
        cost_title:"",
        cost_amount:"",
        cost_incentive_rate:"",
        proj_id: props.proj_id
    }

    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialModalState})

    const [utilization, setUtilization] = useState({
        total:0,
        used:0,
        remaining:0,
        total_percentage:100,
        used_percentage:0,
        remaining_percentage:0
    })

    useEffect(() => {
        Loadlist();
    },[filters]);

    const New = () =>{
        let remain = 100;
        listData.records.forEach(element => {
            remain = remain - element.cost_amount;
        });
        
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState, cost_amount:remain});
    };

    const Delete = () =>{
        apiPost("/admin/project-cost/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/project-cost/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
        apiGet("/admin/project/get",{id:props.proj_id},(r)=>{

            let used_percentage = (100 * r.data.proj_cost_used) / r.data.proj_cost;
            let remaining_percentage = 100 - used_percentage;

            setUtilization({
                total:r.data.proj_cost,
                used:r.data.proj_cost_used,
                remaining:r.data.proj_cost - r.data.proj_cost_used,

                total_percentage:100,
                used_percentage:used_percentage,
                remaining_percentage:remaining_percentage
            })
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project-cost/get",{
            id:id
        },(r)=>{
            setModal(r.data);
            setError({...initialModalState});
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
        apiPost("/admin/project-cost/save",{
            ProjectCost: modal,
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };
 
    const [tsDialog, setTsDialog] = useState({open:false,title:" ",id:0});
    return (<>
        
        <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
            
            <NormalDialog   onClose={(e)=>{ setTsDialog({...tsDialog, open:false}); Loadlist(); }} title={tsDialog.title} open={tsDialog.open}>
                 {tsDialog.open ? <ProjectTask cost_id={tsDialog.id} /> : "" }  
            </NormalDialog>

            <SaveDialog onClose={(e)=>setDialogState({open:false})} onSubmit={(e) => Save(e)} open={dialogState.open} title={dialogState.title}>
                    <div className="form-group">
                        <label>Cost Title</label>
                        <input type="text" className="form-control"
                            value={modal.cost_title} onChange={(e)=>setModal({...modal, cost_title:e.target.value})} />
                        <div className="text-danger">{error.cost_title}</div>
                    </div>
                    <div className="form-group">
                        <label>Cost Amount(%)</label>
                        <input type="number" className="form-control"
                            value={modal.cost_amount} onChange={(e)=>setModal({...modal, cost_amount:e.target.value})} />
                        <div className="text-danger">{error.cost_amount}</div>
                    </div>
                    <div className="form-group">
                        <label>Incentive Rate(%)</label>
                        <input type="number" className="form-control"
                            value={modal.cost_incentive_rate} onChange={(e)=>setModal({...modal, cost_incentive_rate:e.target.value})} />
                        <div className="text-danger">{error.cost_incentive_rate}</div>
                    </div>
                    <input type="hidden" id="proj_id" value={modal.proj_id} />
                    <input type="hidden" id="cost_id" value={modal.cost_id} />
            </SaveDialog>
 
            <B.Button variant="primary" className="mb-2" onClick={()=>New()}>New</B.Button>
                
 
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="6" className="p-0">
                            <strong>Project Budget Utilization: </strong> Total Budget Rs {utilization.total} - Rs {utilization.used} Utilized = Rs {utilization.remaining} Remaining
                         
                         <B.ProgressBar variant="secondary" now={utilization.used_percentage} />  
                         <br />
                            </th>
                        </tr>
                        <tr>
                            <th>Actions</th>
                            <th>Project Title</th>
                            <th>Cost</th>
                            <th>Creation Date</th>
                            <th>Completion Date</th>
                            <th>Task Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listData.records.map(r => {

                        let totalAmount = 0;
                        r.projectTasks.forEach(el => {
                            totalAmount += parseFloat(el.ts_amount);
                        });

                        let progress_color = "primary";
                                                
                        let expectedAmount = (r.project.proj_cost * r.cost_amount)/100;                    
                        let progress_amount =  (totalAmount/expectedAmount)*100; 
                        let styleRow = {};
                        if(expectedAmount < totalAmount){
                            progress_color = "secondary";
                            styleRow = {
                                backgroundColor:"#ffbdbd",
                                fontWeight:"bold"
                            }
                        }

                        return (
                            <tr key={r.cost_id}>
                                <td>
                                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                        <B.Dropdown.Item  
                                            onClick={(e)=>Get(r.cost_id)} href="#">Edit</B.Dropdown.Item>
                                        <B.Dropdown.Item  
                                            onClick={(e)=>setTsDialog({open:true, id:r.cost_id, title: "Tasks for "+r.project.proj_title+" / "+r.cost_title})} href="#">Tasks</B.Dropdown.Item>
                                        <B.Dropdown.Item 
                                            onClick={(e)=>window.open(ApiBaseUrl+"/admin/project-task/report?by=cost&cost_id="+r.cost_id+"&token="+state.UserState.token)} href="#">Report</B.Dropdown.Item>                                                                      
                                        <B.Dropdown.Item 
                                        onClick={(e)=>{ 
                                            e.preventDefault();
                                            setConfirmDialogState({open:true, id:r.cost_id})                                    
                                            }
                                        } 
                                        className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                         
                                    </B.DropdownButton>
 
                                </td>
                                <td>{r.cost_title}</td>
                                <td>{r.cost_amount}%</td>
                                <td>{numeral(expectedAmount).format("0.00")}</td> 
                                <td>
                                    <B.ProgressBar variant="secondary" now={progress_amount} />
                                    {numeral(progress_amount).format("0.00")}% ({numeral(totalAmount).format("0.00")})
                                </td>
                                <td>{r.cost_incentive_rate}%</td>
                            </tr>
                        )

                    }       
                    )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="6">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                    </table>
            </div>
            </>
      
    )
}

export default ProjectCost;
