import React from 'react';
 


import { useState, useEffect } from 'react';
import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
 
import * as B from 'react-bootstrap';
import * as FC from '../../../Lib/FormControl';
import moment from 'moment';
 
import Confirmation from '../../../Lib/Confirmation';
 
import { ApiBaseUrl } from '../../../Config';
 
import Pagination from "react-js-pagination";
 
import { Multiselect } from 'multiselect-react-dropdown';
import {  Event_onAfterSave, Event_onEdit } from './Event';


export default function List(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
     
    const [filters, setFilter] = useState({
        search : "", 
        page:1,
        perpage:10,
    })
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

     

    const Loadlist = () => {          
        apiGet("/associate/page/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    }

    useEffect(()=>{
        window.addEventListener(Event_onAfterSave,Loadlist);        
        Loadlist();
        return () => {
            window.removeEventListener(Event_onAfterSave,Loadlist);            
        }        
    },[]);
    
    useEffect(()=>{
        Loadlist();
    },[filters]);

      

    return <> 
    
         
    <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th colSpan="5">
                        <div className="float-right form-row">
                            <FC.FormContextProvider value={{modal:filters,setModal:setFilter,error:[]}}> 
                            
                            <div className="col">
                                <FC.TextInput name="search" label={"Search"} />
                            </div>
                            </FC.FormContextProvider>
                        </div>
                    </th>                
                </tr>
                <tr>
                    <th style={{width:120}}>Actions</th>
                    <th>Title</th>                     
                    <th style={{width:200}}>Posted On</th> 
                    <th style={{width:200}}>Modified On</th>                     
                                  
                </tr>
            </thead>
            <tbody>
            {listData.records.map(r => (
                <tr>
                <td> 
                    <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                        
                        <B.Dropdown.Item  
                            onClick={(e)=>{
                                window.dispatchEvent(new CustomEvent(Event_onEdit, { detail: r.page_id }));
                            }} href="#">View</B.Dropdown.Item> 
                    </B.DropdownButton>
                </td>

                <td>{r.page_title}</td>               
                
                <td>{moment(r.page_created_on).format("DD MMM YYYY HH:m:s")}</td>               
                <td>{moment(r.page_updated_on).format("DD MMM YYYY HH:m:s")}</td>               
               
            </tr>
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="4">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={filters.page}
                            itemsCountPerPage={10}
                            totalItemsCount={listData.total}
                            pageRangeDisplayed={5}
                            onChange={page => {
                                setFilter({...filters, page})
                            }}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
        </>
}