import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import { apiPost } from '../../../Lib/Helpers';
 
import EmployeeLayout from '../../Layouts/EmployeeLayout';

export default function ChangePassword(props){
    const [modal, setModal] = useState({
        new_password: "",
        new_password2: ""
    });

    const ChangePassword = () => {
        if(modal.new_password.trim() == ""){
            alert("Please enter a password");
        } if(modal.new_password !== modal.new_password2) {
            alert("Both passwords must be same");
        } else {
            apiPost("/associate/me/change-password",modal,(r)=>{
                alert("Password Changed!");
                setModal({
                    new_password : "",
                    new_password2 : ""
                });
            });
        }
    }

    return <><EmployeeLayout title="Change Password">
        <div className="card">
            <div className="card-body">
                <div className="mb-2">
                    <label>New Password</label>
                    <input type="password" value={modal.new_password} onInput={(e)=>setModal({...modal, new_password: e.target.value})} className="form-control" />
                </div>
                <div className="mb-2">
                    <label>Confirm New Password</label>
                    <input type="password" value={modal.new_password2}  onInput={(e)=>setModal({...modal, new_password2: e.target.value})} className="form-control" />
                </div>
                <button onClick={ChangePassword} class="btn btn-primary" type="button">Change Password</button>
                
            </div>
        </div>
        </EmployeeLayout></>
}