import React from 'react';
import AdminLayout from '../../../Layouts/AdminLayout';
import { useState } from 'react';
import {apiPost} from '../../../../Lib/Helpers';

export default function Main(){

    const [cLeave, setCLeave] = useState({
        type:'Paid',        
    });

    const CloseLeaves = () => {
        let answer = window.confirm('Are you sure?');
        if(answer){
            apiPost("/admin/leave/bulk-close-leaves",cLeave,(r)=>{
                alert("Done");
            })
        }
    };

    const [credit, setCredit] = useState({
        type:'Paid',        
        days:0,
    });

    const CreditLeaves = () => {
        let answer = window.confirm('Are you sure?');
        if(answer){
            apiPost("/admin/leave/bulk-credit",credit,(r)=>{
                alert("Done");
            })  
        }
        
    }

    return <>
    <AdminLayout title="Bulk Actions">
        <div className="row">
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header">
                        Bulk Close Leaves
                    </div>
                    <div className="card-body">
                        <label>Type</label>
                        <select onChange={(e)=>setCLeave({type:e.target.value})} value={cLeave.type} className="form-control">
                            <option value="Paid">Paid</option>
                            <option value="Casual">Casual</option>
                            <option value="Special">Special</option>
                        </select>
                    </div>
                    <div className="card-footer">
                        <button onClick={()=>CloseLeaves()} className="btn btn-danger btn-lg">Close Leaves for All Employees</button>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
            <div className="card">
                    <div className="card-header">
                        Bulk Credit
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label>Days</label>
                            <input type="text" onInput={(e)=>setCredit({...credit, days:e.target.value})} className="form-control" value={credit.days} />
                        </div>
                        <div className="form-group">
                            <label>Type</label>
                            <select onInput={(e)=>setCredit({...credit, type:e.target.value})} value={credit.type} className="form-control">
                                <option value="Paid">Paid</option>
                                <option value="Casual">Casual</option>
                                <option value="Special">Special</option>
                            </select>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button onClick={()=>CreditLeaves()} className="btn btn-lg btn-primary">Bulk Credit</button>
                    </div>
                </div>
            </div>
        </div>
    </AdminLayout>
    </>
}