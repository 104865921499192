import React from 'react';
 
 

import { useState, useEffect } from 'react';

import {   AjaxValidate, apiGet, apiPost } from '../../../Lib/Helpers';
import {  useDispatch, useSelector } from 'react-redux';
 
import SaveDialog from '../../../Lib/SaveDialog';

import * as B from 'react-bootstrap';
import { ApiBaseUrl } from '../../../Config';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Confirmation from '../../../Lib/Confirmation';
import moment from 'moment';
const ProjectHistory = (props) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [fsDialog, setFsDialog] = useState({open:false,title:" ",id:0});
    const [selectedFile, setSelectedFile] = useState({});
    const [listData, setListData] = useState({
        records:[],
        total:0,
    });
    const [filters, setFilter] = useState({
        search : "",
        proj_id:props.proj_id,
        page:1,
        perpage:10,
    })
    const initialModalState = {
        his_id:"",        
        his_date:moment().format("DD MMM YYYY"),
        his_title:"",
        his_description: "",        
        proj_id: props.proj_id,        
    }
    const initialErrorState = {
        his_id:"",        
        his_date:"",
        his_title:"",
        his_description: "",        
        proj_id: "",        
    }
    const [modal, setModal] = useState({...initialModalState})
    const [error, setError] = useState({...initialErrorState})
    
    useEffect(() => {
        Loadlist();
    },[filters]);

     

    const Delete = () =>{
        apiPost("/admin/project/history-delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
            Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/project/history-loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project/history-get",{
            id:id
        },(r)=>{
            setModal({ ...r.data}); 
            setError({...initialErrorState});
            setFsDialog({open:true,title:"View"});            
        })
    };

     
 
    
    return (
    <>
       
        <Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
                setConfirmDialogState({open:false});                
            } } />
        
        
        
<SaveDialog onClose={(e)=>setFsDialog({open:false})} buttonLabel="Close" onSubmit={(e)=>setFsDialog({open:false})} open={fsDialog.open} title={fsDialog.title}>
        <table className="table table-bordered">
            <tbody>
                <tr><th>Date</th><td>{moment(modal.his_date).format("DD MMM YYYY")}</td></tr>
                <tr><th>Title</th><td>{modal.his_title}</td></tr>
                <tr><th>Description</th><td>{modal.his_description}</td></tr>
            </tbody>
        </table> 
</SaveDialog>

 
<div className="table-responsive">


        <table className="table">
            <thead>
                <tr>
                    <th colSpan={3}>
                    <div className="float-right">
                                    <input type="search" name="search" value={filters.search} placeholder="Search..."
                                    onInput={(e)=>setFilter({...filters,search:e.target.value})} className="form-control" />
                                </div>
                    </th>
                </tr>
                <tr>
                    <th style={{width:100}}>Actions</th>
                    <th>Description</th>
                    <th style={{width:200}}>Date</th>
                </tr>
            </thead>
            <tbody>
                {listData.records.map(r => (
                    <tr key={r.cost_id}>
                        <td>
                            <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
                                <B.Dropdown.Item  
                                    onClick={(e)=>Get(r.his_id)} href="#">View</B.Dropdown.Item>
                                {/*<B.Dropdown.Item 
                                onClick={(e)=>{ 
                                    e.preventDefault();
                                    setConfirmDialogState({open:true, id:r.file_id})                                    
                                    }
                                }
                            className="text-danger link-delete" href="#">Delete</B.Dropdown.Item>                                */}
                            </B.DropdownButton>
                        </td>
                       
                        <td><strong>{r.his_title}</strong><br /> {r.his_description.substring(0,40)+"..."}</td>
                        <td>{moment(r.his_date).format("DD MMM YYYY h:mm:ss a")}</td>
                    </tr>  
                    ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3">
                    <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={listData.total}
                pageRangeDisplayed={5}
                onChange={page => {
                    setFilter({...filters, page})
                }}
            />
                    </td>
                </tr>
            
            </tfoot>
        </table> </div>
    </>
    );

}


 

export default ProjectHistory;