import React, { useEffect, useState } from 'react';
 
import {   Event_onAfterSave, Event_onEdit, Event_onNew } from './Event'; 
import { useDispatch, useSelector } from 'react-redux';
import { apiGet, apiPost } from '../../../Lib/Helpers';
 

import NormalDialog from '../../../Lib/NormalDialog';
 
export default function View(props){

    const state = useSelector(state => state);
    const dispatch = useDispatch();
 
    const initialModalState = {
        page_id:0,
        page_title:"",
        page_status:"Public"
    }
 
    const [modal, setModal] = useState(initialModalState);
 
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
  
    const Get = (e) => {         
        let id = e.detail;       
        apiGet("/associate/page/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("View");
                setModal(r.data);                  
                setOpen(true);  
            } else {
                alert("Not Found");
            }
        });
    }

    const DisableRightClick = (e) => {
        e.preventDefault();
    }
 
    useEffect(()=>{  
        window.addEventListener(Event_onEdit,Get);
        document.addEventListener("contextmenu", DisableRightClick);
        return () =>{ 
            window.removeEventListener(Event_onEdit,Get);
            document.removeEventListener("contextmenu", DisableRightClick);
        } 
    },[])

    



    return <>
    
    <NormalDialog onClose={()=>setOpen(false)}  open={open} title={title}>
        <h3 className="d-print-none">{modal.page_title}</h3>
        <div className="pt-2 pb-2 d-print-none"
        style={{maxHeight:600, minHeight:400, overflowY:"scroll"}}
        dangerouslySetInnerHTML={{ __html:  modal.page_content }} /> 
            
    </NormalDialog>
    
    
    </>
}