import React, { useEffect, useState } from 'react';
 
import {   Event_onAfterSave, Event_onEdit, Event_onNew } from './Event'; 
 
import { apiGet, apiPost } from '../../../Lib/Helpers';
import * as FormControl from '../../../Lib/FormControl';

 
import SaveDialog from '../../../Lib/SaveDialog';
import { Editor } from '@tinymce/tinymce-react';
 
export default function Edit(props){

    
 
    const initialModalState = {
        page_id:0,
        page_title:"",
        page_status:"Public"
    }

    const initialErrorState = {
        page_id:"",
        page_title:"",
        page_status:"",        
    }

    const [modal, setModal] = useState(initialModalState);
    const [error, setError] = useState(initialErrorState);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("");
    const [pageContent, setPageContent] = useState("");
    const Save = (e) =>{
        e.preventDefault(); 
        apiPost("/admin/page/save",{Page:modal},(r)=>{    
            setOpen(false);
            window.dispatchEvent(new CustomEvent(Event_onAfterSave, modal));
        },setError)  
    };

    const Get = (e) => {         
        let id = e.detail;       
        apiGet("/admin/page/get",{id:id},(r)=>{             
            if(r.data !== null){ 
                setTitle("Edit");
                setModal(r.data);                  
                setOpen(true);   
                setPageContent(r.data.page_content);
            } else {
                alert("Not Found");
            }
        });
    }

    const New = () => { 
        setModal(initialModalState);
        setError(initialErrorState);
        setOpen(true);
        setTitle("New");
        setPageContent("");
    }
 
    useEffect(()=>{ 
        window.addEventListener(Event_onNew,New);
        window.addEventListener(Event_onEdit,Get);
        return () =>{
            window.removeEventListener(Event_onNew,New);
            window.removeEventListener(Event_onEdit,Get);
        } 
    },[])



    return <>
    
    <SaveDialog onClose={()=>setOpen(false)} onSubmit={Save} open={open} title={title}>
        <FormControl.FormContextProvider value={{modal:modal,setModal:setModal,error:error}}>
            <FormControl.TextInput name="page_title" label="Page Title"/>
            
            <div className="form-group">
                <label>Content</label>
                <Editor
                    initialValue={pageContent}
                    init={{
                    plugins: 'link image code',
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                    }}
                    onChange={(e) => setModal({...modal, page_content:e.target.getContent()}) }
                /> 
                <div className="text-danger">{error['page_content']}</div>
            </div>


            <FormControl.Select name="page_status" label="Status" options={[
                {label:"Public", value:"Public"},{label:"Private", value:"Private"},
            ]}/>
             
            <input type="hidden" name="page_id" value={modal.page_id} />
        </FormControl.FormContextProvider>
    </SaveDialog>
    
    
    </>
}