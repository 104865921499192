import React, { useState, useEffect } from 'react';
 
import moment from 'moment';
import { paymentModeArray } from  '../../../Config';
import { apiPost } from '../../../Lib/Helpers';
 
const Payment_Quick = (props) => {

    let balance = 0;
 
    if(props.data !== false){
        balance = props.data.summery.balance;
    }
    
    const [quickPay, setQuickPay] = useState({
        pmt_id : "",
        pmt_description : "",
        pmt_amount : balance,
        pmt_month : props.month,
        pmt_date: moment().format("DD MMM YYYY"),
        pmt_mode: "Net Banking",
        emp_id:props.emp_id,
    })

    useEffect(()=>{
        setQuickPay({
            pmt_id : "",
            pmt_description : "",
            pmt_amount : balance,
            pmt_month : props.month,
            pmt_date: moment().format("DD MMM YYYY"),
            pmt_mode: "Net Banking",
            emp_id:props.emp_id,
        })
      
    },[balance,props.month,props.emp_id]);


    const initialErrorState = {
        pmt_id:"",
        pmt_date: "",
        pmt_amount: "",
        pmt_mode:"",
        pmt_description:"",
        pmt_month:"", 
        emp_id:""
    }

    const [error, setError] = useState(initialErrorState)

     

    const saveIt = () => { 
   
        if(props.data.summery.balance <= 0){
            alert("You can not pay Rs 0");
            return;
        }

        apiPost("/admin/payment/save",{
            "Payment":quickPay
        },(r)=>{    
            setQuickPay({
                pmt_id : "",
                pmt_description : "",
                pmt_amount : balance,
                pmt_month : props.month,
                pmt_date: moment().format("DD MMM YYYY"),
                pmt_mode: "Net Banking",
                emp_id:props.emp_id,
            }) 
            props.onSubmit();
        },setError)
    }

    return (<div className="card">
            <div className="card-body">
                <div className="form-group">
                    <label>Mode</label>
                    <select value={quickPay.pmt_mode} className="form-control"
                        onChange={(e)=>setQuickPay({...quickPay, pmt_mode:e.target.value})}>
                        {paymentModeArray.map( (r) => (
                            <option key={r.id} value={r.id}>{r.title}</option>                                    
                        ))}
                    </select>
                    <div className="text-danger">{error.pmt_mode}</div>
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea
                        onChange={(e)=>setQuickPay({...quickPay, pmt_description:e.target.value})}
                        value={quickPay.pmt_description}
                        id="pmt_description" className="form-control" />
                    <div className="text-danger">{error.pmt_description}</div>
                </div>
            </div>
            <div className="card-footer text-center">
                <button className="btn btn-primary" onClick={()=>saveIt()}>Quick Pay Rs {props.data.summery.balance}</button>    
            </div>
            
        </div>);
}

export default Payment_Quick;