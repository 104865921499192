import React from 'react';

 

import { useState, useEffect } from 'react';


import {  apiGet, apiPost } from '../../../Lib/Helpers';
import { connect } from 'react-redux';
import Confirmation from '../../../Lib/Confirmation';
 
import SaveDialog from '../../../Lib/SaveDialog';
import ProjectTask_Dialog from './ProjectTask_Dialog';

 import Pagination from 'react-js-pagination';
import moment from 'moment';
import DeleteLink from '../../../Lib/DeleteLink';
import * as B from 'react-bootstrap';
import _ from 'underscore';

const ProjectTask = (props) => {

    const [dialogState, setDialogState] = useState({open:false, title:""});
    const [confirmDialogState, setConfirmDialogState] = useState({open:false});
    const [filters, setFilter] = useState({
        cost_id:props.cost_id,
        user_id:0,
        page:1,
        perpage:10,
    });

    const [listData, setListData] = useState({
        records:[],
        total:0,
    });

    const [ employeeList, setEmployeeList ] = useState([]);

    const initialModalState = {
        ts_id:0,
        ts_description:"",
        ts_rate:"",
        ts_qty:"",
        ts_amount:"",
        ts_estimated_date:moment().format("DD MMM YYYY"),
        ts_completion_date:moment().format("DD MMM YYYY"),
        ts_alotted_by:"",
        ts_alotted_to:"",
        ts_status:"Completed",
        cost_id:props.cost_id,
        task_id:"",
        user_id:props.UserState.user.user_id,
    }

    const [modal, setModal] = useState({...initialModalState, ts_status:"Completed"})
    const [error, setError] = useState({...initialModalState,
        cost_id:"",user_id:"",
        ts_estimated_date:"", ts_completion_date:"",
        ts_status:""
    })

    const [taskList, setTaskList] = useState([]);
    const [associateList, setAssociateList] = useState([]);
    const [managerList, setManagerList] = useState([]);

    //////////////////////////////////


    useEffect(() => {
        Loadlist();
    },[filters]);



    const New = () =>{
        updateAssociateList();
        updateManagerList();
        setDialogState({ open : true, title: "New" });
        setModal({...initialModalState,ts_status:"Completed",});
        setError({...initialModalState,
            cost_id:"",user_id:"",ts_status:"",
            ts_estimated_date:"", ts_completion_date:""
        });
    };

    const Delete = () =>{
        apiPost("/admin/project-task/delete",{
            id:confirmDialogState.id
        },(r)=>{
            setConfirmDialogState({open:false, title:""});
                Loadlist();
        })
    };

    const Loadlist = () =>{
        apiGet("/admin/project-task/loadlist",filters,(r)=>{
            setListData({
                records:r.data.records,
                total:r.data.total,
            });
        })
    };

    const Get = (id) =>{
        apiGet("/admin/project-task/get",{
            id:id
        },(r)=>{
 
            updateAssociateList(r.data.ts_alotted_to);
            updateManagerList(r.data.ts_alotted_by);

            setModal({...r.data,
                ts_estimated_date: moment(r.data.ts_estimated_date).format("DD MMM YYYY"),
                ts_completion_date: moment(r.data.ts_completion_date).format("DD MMM YYYY"),         
            });
            setError({...initialModalState,
                cost_id:"", user_id:"",
                ts_estimated_date:"", ts_completion_date:"",
                ts_status:""
            });
            setDialogState({open:true,title:"Edit"});
        })
    };

    const Save = (e) =>{
        e.preventDefault();
       
        let modal2 = {...modal};
      //  modal2.ts_estimated_date = moment(modal.ts_estimated_date).format("DD MMM YYYY");
      //  modal2.ts_completion_date = moment(modal.ts_completion_date._d).format("DD MMM YYYY");
        modal2.cost_id = props.cost_id;
        modal2.user_id = props.UserState.user.user_id;


        apiPost("/admin/project-task/save",{
            ProjectTask: modal2,
        },(r)=>{
            setDialogState({open:false, title:""});
            Loadlist();
        }, setError)
    };



    useEffect(()=>{
        apiGet("/admin/task/listall",{},(r)=>{
            setTaskList(r.data.records);
        });
        apiGet("/admin/employee/listall",{},(r)=>{
            setEmployeeList(r.data.records);
        });
        updateAssociateList();
        updateManagerList();
    },[]);

    const updateAssociateList = (include=0) => {
        apiGet("/admin/employee/loadlist",{search:'', include: include, role_id:1, perpage:1000000000},(r)=>{
            setAssociateList(r.data.records);
        });
    }

    const updateManagerList = (include=0) => {
        apiGet("/admin/employee/loadlist",{search:'', include: include, role_id:2, perpage:1000000000},(r)=>{
            setManagerList(r.data.records);
        });
    }

    const [userList, setUserList] = useState([]);
    useEffect(()=>{
        apiGet("/admin/user/listall",{},(r)=>{
            setUserList(r.data.records);
        });
    },[])

 


    return (
        <React.Fragment>
          
<ProjectTask_Dialog 
taskList = {taskList} 
managerList = {managerList} 
associateList = {associateList}
setError={setError} 
setModal={setModal} 
error={error} 
modal={modal}
onClose={(e)=>setDialogState({open:false})} 
onSubmit={(e) => Save(e)} 
open={dialogState.open} 
title={dialogState.title} />
   
<Confirmation open={confirmDialogState.open} onYes={()=>Delete(confirmDialogState.id)} onNo={(e)=>{              
            setConfirmDialogState({open:false});                
        } } />
            
 
 
<button className="btn btn-primary mb-2" onClick={()=>New()} >New</button>
 <div className="table-responsive">
<table className="table">
    <thead>
        <tr>
            <th colSpan="12">
                <div className="float-right">
                <select value={filters.user_id} onChange={(e)=>setFilter({...filters, user_id:e.target.value})} className="form-control">
                    <option key={0} value={0}>Any/All User</option>
                    {userList.map((r) => (
                        <option key={r.user_id} value={r.user_id}>{r.user_name}</option>
                    ))}
                </select>
                </div>
            </th>
        </tr>
        <tr>
            <th style={{width:130}}>Actions</th>
            <th>ID</th>
            <th>Cost Title</th>
            <th>Nature</th>
            <th>Description</th>
            <th>Alotted By</th>
            <th>Alotted To</th>
            <th>Rate</th>
            <th>Qty</th>
            <th>Amount</th>
            <th>Status</th>
            <th>User</th>
        </tr>
    </thead>
    <tbody>
    {listData.records.map(r => (
<tr key={r.ts_id}>
   <td>

   <B.DropdownButton id="dropdown-basic-button" size={"sm"} title="Actions">
        <B.Dropdown.Item  
            onClick={(e)=>Get(r.ts_id)} href="#">Edit</B.Dropdown.Item>
            
        <B.Dropdown.Item 
        onClick={(e)=>{ 
            e.preventDefault();
            setConfirmDialogState({open:true, id:r.ts_id})                                    
            }
        } 
        className="text-danger link-delete" href="#">Delete</B.Dropdown.Item> 
        
    </B.DropdownButton>

        
   </td>
   <td>{r.ts_id}</td>
   <td>{r.cost.cost_title}</td>
   <td>{r.task.task_title}</td>
   <td>{r.ts_description}</td>
   <td>{r.alottedBy ? r.alottedBy.emp_fullname : ""}</td>
   <td>{r.alottedTo ? r.alottedTo.emp_fullname : ""}</td>
   <td>{r.ts_rate}</td>
   <td>{r.ts_qty}</td>
   <td>{r.ts_amount}</td>
   <td>{r.ts_status}</td>
   <td>{r.user.user_name}</td>
</tr>
))}
    </tbody>
    <tfoot>
        <tr>
            <td colSpan="12">
            <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={filters.page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={listData.total}
                                    pageRangeDisplayed={5}
                                    onChange={page => {
                                        setFilter({...filters, page})
                                    }}
                                />
            </td>
        </tr>
    </tfoot>
</table>
</div> 
        </React.Fragment>
    );
}


const mapStateToProps = state => { return state };
const mapDispatchToProps = dispatch => { return { do: (action) => { dispatch(action) } } };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectTask);
