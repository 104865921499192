import React from 'react';
 
 
 
import TaskRecords from './Payment_MonthData_TaskRecords';
import IncentiveRecords from './Payment_MonthData_IncentiveRecords';
import PaymentRecords from './Payment_MonthData_PaymentRecords';
 
import PaymentSummery from './Payment_Summery';
 
 

const Payment_MonthData = (props) => {
   // const [refresh, setRefresh] = useState(false);
 
    if(props.data === false){
        return <React.Fragment />;
    }

    let status = "Unpaid";
    let color = "red";
    if(props.data.summery.balance <= 0){
        status = "Paid";
        color = "green";
    }

    

   // let month_date = moment(props.month+"-01").format("YYYY-MM-DD");
 
    return (<React.Fragment>


        <h3>Tasks Completed</h3>
        <TaskRecords records={props.data.month_records.projecttasks} status={status} color={color} /> 
        <hr />
        <h3>Incentive Records</h3>
        <IncentiveRecords emp_id={props.emp_id} records={props.data.month_records.incentive}
        
        

        status={status} color={color} />             
        <hr />
        <h3>Payment Records</h3>
        <PaymentRecords records={props.data.month_records.payment} />             
        <hr />
        <h3>Summery</h3> 
        
        <PaymentSummery setRefresh={() => props.setRefresh(!props.refresh)} emp_id={props.emp_id} data={props.data}  month={props.month} />

    </React.Fragment>);
}

export default Payment_MonthData;