import React, { useState } from 'react';
import BlankLayout from '../Layouts/BlankLayout';
import { useDispatch, useSelector } from 'react-redux';
import { apiPost } from '../../Lib/Helpers';
import { Redirect, useLocation, useHistory } from 'react-router';
export default function OperatorLogin() {

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [modal, setModal] = useState({ username: "", password: "", type: "Employee" });

    const location = useLocation();

    const login = (e) => {
        e.preventDefault();

        apiPost("/site/login", modal, (r) => {
            dispatch({
                type: "USER/SET",
                data: {
                    token: r.data.token,
                    user: r.data.user,
                    type: modal.type,
                }
            });
        })
    }

    if (state.UserState.isLoggedIn) {
        if (state.UserState.type === "Employee") {
            if (state.UserState.user.roles === undefined) {
                dispatch({
                    type: "USER/LOGOUT",
                });
            }

            if (location.state !== undefined) {
                return <Redirect to={location.state.from} />;
            }

            if (state.UserState.user.roles.includes("Project Manager")) {
                return <Redirect to='/manager' />;
            } else {
                return <Redirect to='/associate/work-entry' />;
            }

        } else {
            if (location.state !== undefined) {
                return <Redirect to={location.state.from} />;
            }
            return <Redirect to='/admin' />;
        }
    }

    let idType = "Username";

    if (modal.type == "Employee") {

       
        idType = "Email";
       
    }

    return <BlankLayout title="Operator Login">
        <form onSubmit={login} style={{ maxWidth: 400, margin: "auto" }}>
            <div className="card mt-4">
                <div className="card-header bg-primary text-white text-center p-4">
                    <div className="h3 p-0 m-0">Login</div>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <label>{idType}</label>
                        <input type="text" name="username"
                            onInput={(e) => setModal({ ...modal, username: e.target.value })} value={modal.username} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" name="password"
                            onInput={(e) => setModal({ ...modal, password: e.target.value })} value={modal.password} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Type</label>
                        <select name="type"
                            onChange={(e) => setModal({ ...modal, type: e.target.value })} value={modal.type} className="form-control">
                            <option key="1" value="User">Admin/User</option>
                            <option key="2" value="Employee">Associates/Manager</option>
                         
                        </select>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button className="btn btn-primary btn-lg" type="submit">Login</button>
                </div>
            </div>
        </form>
    </BlankLayout>
}