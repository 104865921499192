import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List  from './WorkEntry_List';
import AdminLayout from '../../Layouts/AdminLayout';
import { Event_onAfterLoadlist, Event_onNew } from './Events';
import WorkEntry_Dialog from './WorkEntry_Dialog';
import axios from 'axios';
export default function WorkEntry(props){
    const [loadListTrigger, setLoadListTrigger]  = useState("");
  
    /**
     * Dialog me project_title nahi aa raha hai
     * Filter proper kam nahi kar rhe
     * Status dena hai
     * Task nature limit enforce karna hai
     */
    useEffect(()=>{
        window.addEventListener(Event_onAfterLoadlist,setPageTitle);
        return () => {
            window.removeEventListener(Event_onAfterLoadlist,setPageTitle);
        }
    },[])
    const [title, setTitle] = useState("Work Entry");
    const setPageTitle = (e) => {
   
        setTitle("Work Entry <span class='d-none d-print-inline'>- Page: "+e.detail.filters.page+"</span>");
    }
   
    return <><AdminLayout title={title}>
        <WorkEntry_Dialog />
        <button  onClick={()=>{
            window.dispatchEvent(new CustomEvent(Event_onNew,{}));            
        }} className="btn btn-lg btn-primary d-print-none mb-2 " style={{ marginRight: '900px' }}>New</button> 
        
       
        <WorkEntry_List 
        loadListTrigger={loadListTrigger}/>
        </AdminLayout></>
}