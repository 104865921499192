import React, { useState, useEffect } from 'react';
 
import { Helmet } from 'react-helmet';
import * as B from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MonthReportDialog from '../Admin/MonthReport/MonthReportDialog';
import { MdDescription, MdLabel, MdList, MdPeople, MdPerson, MdLocationCity, MdFolderOpen, MdPages, MdPageview, MdFirstPage, MdDashboard, MdReport, MdInsertChart } from "react-icons/md";
import * as IM from "react-icons/im";
import { FaChartBar, FaSignOutAlt, FaTableTennis, FaUserCircle } from "react-icons/fa";
 
import { ImOffice } from 'react-icons/im';

const Main_Links = [
  {
    icon:<MdDashboard size="1.5rem" />,
    label:"Dashboard",
    link:"/admin",
    id:1, 
  },
  {
    icon:<MdDescription size="1.5rem" />,
    label:"Projects",
    link:"/admin/project",
    id:2, 
  },
  {
    icon:<MdInsertChart size="1.5rem"/>,
    label:"Report - Last Date Expired",
    link:"/admin/project/lastdate-report",
    id:3,
  },
  {
    icon:<MdInsertChart size="1.5rem"/>,
    label:"Report - Required Amount",
    link:"/admin/project/lowbalance-report",
    id:3,
  },
  {
    icon:<MdInsertChart size="1.5rem"/>,
    label:"Report - Balance Amount ",
    link:"/admin/project/test-report",
    id:3,
  },
  {
    icon:<MdPeople  size="1.5rem" />,
    label:"Associates",
    link:"/admin/employee",
    id:4,
  },
  {
    icon:<MdPerson  size="1.5rem"/>,
    label:"User",
    link:"/admin/user",
    id:5,
  },
  {
    icon:<MdList  size="1.5rem"/>,
    label:"Task Nature",
    link:"/admin/task-nature",
    id:6,
  },
  {
    icon:<MdList  size="1.5rem"/>,
    label:"Work Entries",
    link:"/admin/work-entry",
    id:7,
  },
  {
    icon:<MdInsertChart size="1.5rem"/>,
    label:"Report - Projectwise Status",
    link:"/admin/work-entry/status-report",
    id:8,
  },
  {
    icon:<MdInsertChart size="1.5rem"/>,
    label:"Report - Associate",
    link:"/admin/work-entry/employee-report",
    id:9,
  },
  {
    icon:<MdList  size="1.5rem"/>,
    label:"Pages",
    link:"/admin/pages",
    id:10,
  },
  // {
  //   icon:<MdInsertChart  size="1.5rem"/>,
  //   label:"Amount Over",
  //   link:"/admin/amountover",
  //   id:11,
  // },
  // {
  //   icon:<MdInsertChart  size="1.5rem"/>,
  //   label:"Time Over",
  //   link:"/admin/timeover",
  //   id:12,
  // },
];

const Qb_Links = [

  {
    
    icon:<MdList size="1.5rem"/>,
    label:"Types",
    link:"/admin/question-bank/types",
    id:1,
  },

  {
    icon:<MdLocationCity size="1.5rem"/>,
    label:"States",
    link:"/admin/question-bank/states",
    id:2,
  },
  {
    icon:<MdLabel size="1.5rem"/>,
    label:"Tags",
    link:"/admin/question-bank/tags",
    id:3,
  },
  {
    icon:<MdFolderOpen size="1.5rem"/>,
    label:"Files",
    link:"/admin/question-bank/files",
    id:4,
  },
]

const Lv_Links = [
  {
    icon:<MdList size="1.5rem"/>,
    label:"Holiday List",
    link:"/admin/leave/holiday",
    id:1,
  },
  {
    icon:<MdList size="1.5rem"/>,
    label:"Requests",
    link:"/admin/leave/request",
    id:2,
  },
  {
    icon:<MdList size="1.5rem"/>,
    label:"Leave Accounts",
    link:"/admin/leave/account",
    id:3,
  }, 
  {
    icon:<MdList size="1.5rem"/>,
    label:"Bulk Action",
    link:"/admin/leave/bulk",
    id:4,
  },
]

export default function AdminLayout(props){
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const [reportDialogStatus, setReportDialogStatus] = useState(false);
  
  const [docHeight, setDocHeight] = useState(0);
  
  let [newsletter, setNewsletter] = useState(false);
  let [daily, setDaily] = useState(false);
  let [weekly, setWeekly] = useState(false);
  let [monthly, setMonthly] = useState(false);

  const onNewsletterChange = (checked) => {
    setNewsletter(checked);
    if (!checked) {
      setDaily(false);
      setWeekly(false);
      setMonthly(false);
    }
  };



  if(!state.UserState.isLoggedIn){
    return <Redirect  to={ { pathname:'/', state:{from:location.pathname} } } />;
     
  }
  if(state.UserState.type === "Employee"){
    return <Redirect to='/manager' />;
  }
  const Logout = () => {
    dispatch({
      type: "USER/LOGOUT",
      data: {
        token: null,
        user: null,
        type:null,
      }
    });
  };

  let hidedelete = <style>{`
  .link-delete{
    display:none;
  }
  
  `}
</style>;
  
    return <>
    
    <Helmet>
        <title>{props.title}</title>
        
        {state.UserState.user.user_id > 1 ? hidedelete : ''}
        
        <style media="print">{`
  .table-responsive{
    box-shadow:none !important;
    border:none;
  }
  body{
    background:white;
  }
  
  `}
</style>

    </Helmet>

    <B.Navbar variant="light" bg="primary" className="shadow text-white" expand="lg">
  <B.Container fluid>
    <B.Navbar.Brand href="/">
     <ImOffice style={{marginTop: -10}} /> Office Work
    </B.Navbar.Brand>
    <B.Navbar.Toggle aria-controls="basic-navbar-nav" />
    <B.Navbar.Collapse id="basic-navbar-nav">
      <B.Nav className="me-auto">
          

        
 
      </B.Nav>
    </B.Navbar.Collapse>

    <div className="float-right">


<div className="form-row">
  <div className="col">
  <FaUserCircle size="3rem" />
  </div>
  <div className="col-auto">

  Welcome <strong>{state.UserState.user.user_name}</strong><br />
  <a href="#" className="text-light" onClick={() => Logout()}><FaSignOutAlt /> Sign Out</a>
  </div>
</div>


  
</div>

  </B.Container>
</B.Navbar>
<div className="content-area">
  <div className="sidemenu d-print-none">  
    <div className="sidemenu-heading">Main</div>
    <ul>
        {Main_Links.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}        
    </ul>

    <div className="sidemenu-heading">Question Bank</div>
    <ul>
        {Qb_Links.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}        
    </ul>

    <div className="sidemenu-heading">Leave Management</div>
    <ul>
        {Lv_Links.map(r => {
          return <li  key={r.id} ><Link className={r.link == location.pathname ? 'active' : ''} to={r.link}>{r.icon} {r.label}</Link></li>
        })}         
    </ul>


    <div className="p-3 text-center">
    <button onClick={()=>setReportDialogStatus(!reportDialogStatus)} className="btn btn-primary">
      <FaChartBar /> Monthly Report</button>
    </div>
  </div>
  <div className="main">
      <header className="page-header clearfix">
          
          <h1 className="h4 mt-3" dangerouslySetInnerHTML={{ __html: props.title}}></h1>
              
      </header>
      <div style={{minHeight:1000}}>
      {props.children}     
      </div>
  </div>
</div>

<MonthReportDialog open={reportDialogStatus} onClose={()=>setReportDialogStatus(false)} />

    </>
}