import React, { useEffect, useState } from 'react';
import * as B from 'react-bootstrap';
import WorkEntry_List  from './WorkEntry_List';
import AdminLayout from '../../Layouts/AdminLayout';
import { Event_onAfterFileLinking, Event_onNew, Event_onNewFile } from './Events';
import WorkEntry_Dialog from './WorkEntry_Dialog';
import Files_Dialog from './Files_Dialog';
import Files_List from './Files_List';
import NormalDialog from '../../../Lib/NormalDialog';
import Importer from './Importer';
export default function Files(props){
    const [importerDialog, setImporterDialog] = useState({open:false,w_id:0});
    
     

    return <> 
        <Files_Dialog w_id={props.w_id} />
        
        <button onClick={()=>{
            window.dispatchEvent(new CustomEvent(Event_onNewFile,{}));            
        }} className="btn btn-lg mr-2 btn-primary mb-2">New</button>

        <button onClick={()=>{
            setImporterDialog({open:true,w_id:props.w_id})
        }} className="btn btn-lg btn-primary mb-2">Import</button>

        <Files_List w_id={props.w_id} />        
 
        <NormalDialog onClose={()=>{
            setImporterDialog({open:false,w_id:props.w_id});            
        }} open={importerDialog.open} title="File Browser for Import">
            <Importer w_id={importerDialog.w_id} />
        </NormalDialog>

        </>
}